import React from 'react';

import classnames from 'classnames';

import { Input, InputState, Props as InputProps } from '../Input/Input';
import { Typography } from '../Typography/Typography';
import styles from './InputField.module.css';

export { InputState } from '../Input/Input';

export type Props = InputProps & {
  className?: string;
  error?: React.ReactNode;
  errorClassName?: string;
  hint?: React.ReactNode;
  label?: React.ReactNode;
  labelClassName?: string;
  shrinkError?: boolean;
};
export const InputField: React.FC<Props> = React.memo(React.forwardRef((props, ref) => {
  const {
    className,
    labelClassName,
    errorClassName,
    label = null,
    error = null,
    shrinkError = true,
    state,
    hint,
    ...rest
  } = props;

  return (
    <div className={classnames(styles.inputField, className)}>
      { label && (
        <Typography component='label' className={classnames(styles.label, labelClassName)}>
          { label }
        </Typography>
      ) }
      <Input
        ref={ref}
        state={state}
        { ...rest }
      />
      {hint
      && <div className={styles.hint}>
        {hint}
      </div>
      }
      <Typography
        variant='body3'
        className={classnames(styles.error, {
          [styles.shown]: state === InputState.Error,
          [styles.shrinkError]: shrinkError,
        }, errorClassName)}
      >
        {/* span wrapper for correct working with browser translate */}
        {error && <span>{error}</span>}
      </Typography>
    </div>
  );
}));

InputField.displayName = 'InputField';
