import React from 'react';

import { GlobalMessages, HotelCheckoutMessages, PageEntity } from '@customTypes/Messages';
import { LayoutExtension } from '@customTypes/ResponsiveState';
import { HeaderFooterData } from '@customTypes/apiCompound';
import { BaseResponse, LanguageCodeMapped } from '@customTypes/common';

import { messageHooks } from '../../hooks/messageHooks/messageHooks';

type MessageValues = {
  changeId?: string | null;
  entity?: BaseResponse<PageEntity> | null;
  headerFooterData?: BaseResponse<HeaderFooterData> | null;
  language?: LanguageCodeMapped | null;
  layoutExtension?: LayoutExtension | null;
  uuid?: string | null;
};

export const prerenderedCallbacks = {
  useRefs() {
    const rendered = React.useRef(false);

    return {
      rendered,
    };
  },
  setMessageValues({
    layoutExtension,
    language,
    entity,
    headerFooterData,
    uuid,
    changeId,
  }: MessageValues) {
    const { rendered } = prerenderedCallbacks.useRefs();

    const setLayoutExtension = messageHooks.useNext(GlobalMessages.LayoutExtension);
    const setLanguage = messageHooks.useNext(GlobalMessages.Language);
    const setEntity = messageHooks.useNext(GlobalMessages.Entity);
    const setHeaderFooterData = messageHooks.useNext(GlobalMessages.HeaderFooterData);
    const setUuid = messageHooks.useNext(HotelCheckoutMessages.Uuid);
    const setChangeId = messageHooks.useNext(HotelCheckoutMessages.ChangeId);

    if (!rendered.current) {
      layoutExtension && setLayoutExtension(layoutExtension);
      language && setLanguage(language);
      setEntity(entity ?? null);
      headerFooterData && setHeaderFooterData(headerFooterData);
      uuid && setUuid(uuid);
      changeId && setChangeId(changeId);

      rendered.current = true;
    }
  },
};
