import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ErrorBoundary } from '@nxte-nl/error-boundary';

import { Modal } from '@components/Modal/Modal';
import { Typography } from '@components/Typography/Typography';
import { LayoutExtensionContainer }
  from '@containers/LayoutExtensionContainer/LayoutExtensionContainer';
import { Modal as ModalType } from '@customTypes/Modal';

import styles from './AccountTokenExpiredModal.module.css';

const messages = defineMessages({
  tokenExpiredError: {
    id: 'src.hooks.AccountTokenExpiredModal.tokenExpiredError',
    defaultMessage: 'Token has been expired' ,
  },
});

export type Props = {
  modalClose: () => void;
  modalId: ModalType | null;
};

export const AccountTokenExpiredModal = React.memo((props: Props) => {
  const {
    modalId,
    modalClose,
  } = props;

  return (
    <ErrorBoundary>
      <LayoutExtensionContainer>
        {({ layoutExtension }) => (
          <Modal
            modalClassName={styles.modal}
            isOpen={modalId === ModalType.AccountTokenExpiredModal}
            onClose={modalClose}
            layoutExtension={layoutExtension}
            mobileV2
          >
            <div className={styles.content}>
              <Typography variant='body1'>
                <FormattedMessage {...messages.tokenExpiredError} />
              </Typography>
            </div>
          </Modal>
        )}
      </LayoutExtensionContainer>
    </ErrorBoundary>
  );
});

AccountTokenExpiredModal.displayName = 'AccountTokenExpiredModal';
