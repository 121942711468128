import React from 'react';

import classnames from 'classnames';

import { Modal } from '@components/Modal/Modal';
import { LanguageFormContainerV2 }
  from '@containers/LanguageFormContainerV2/LanguageFormContainerV2';
import { LayoutExtensionContainer }
  from '@containers/LayoutExtensionContainer/LayoutExtensionContainer';

import styles from './LanguageSwitcherModalV2.module.css';

export type Props = {
  isOpen: boolean;
  modalNodeId?: string;
  onCloseLanguageModal: () => void;
  onCloseModals: () => void;
  withoutOverlay?: boolean;
};

export const LanguageSwitcherModalV2 = React.memo((props: Props) => {
  const { isOpen, onCloseLanguageModal, onCloseModals, withoutOverlay, modalNodeId } = props;

  return (
    <LayoutExtensionContainer>
      {({ layoutExtension, isMobile, isTablet }) => (
        <Modal
          nodeId={(isMobile || isTablet) ? modalNodeId : undefined}
          isOpen={isOpen}
          onClose={onCloseLanguageModal}
          modalClassName={classnames('mobileSidebar', styles.languageSwitcherModal)}
          overlayClassName={classnames({ [styles.withoutOverlay]: withoutOverlay })}
          headerClassName={styles.languageSwitcherModalHeader}
          closeButtonClassName={styles.languageSwitcherModalCloseButton}
          layoutExtension={layoutExtension}
        >
          <LanguageFormContainerV2 onClose={onCloseModals} />
        </Modal>
      )}
    </LayoutExtensionContainer>
  );
});

LanguageSwitcherModalV2.displayName = 'LanguageSwitcherModalV2';
