import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import classnames from 'classnames';

import { DropdownV2 } from '@components/DropdownV2/DropdownV2';
import { Link, LinkType } from '@components/Link/Link';
import { LocalizedLink } from '@components/LocalizedLink/LocalizedLink';
import { SectionExpandable } from '@components/SectionExpandable/SectionExpandable';
import { Typography } from '@components/Typography/Typography';
import { Account } from '@components/icons/Account/Account';
import { GlobalMessages } from '@customTypes/Messages';
import { Modal } from '@customTypes/Modal';
import { LayoutExtension } from '@customTypes/ResponsiveState';
import { domHooks } from '@hooks/domHooks/domHooks';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';

import styles from './AccountSwitcher.module.css';

const messages = defineMessages({
  accountSwitcherLabel: {
    id: 'src.components.AccountSwitcher.accountSwitcherLabel',
    defaultMessage: 'Inloggen',
  },
  accountSwitcherSidebarLabel: {
    id: 'src.components.AccountSwitcher.accountSwitcherSidebarLabel',
    defaultMessage: 'Account',
  },
  accountMenuMyAccount: {
    id: 'src.components.AccountSwitcher.accountMenuMyAccount',
    defaultMessage: 'Mijn account',
  },
  accountMenuMyData: {
    id: 'src.components.AccountSwitcher.accountMenuMyData',
    defaultMessage: 'Mijn gegevens',
  },
  accountMenuBookings: {
    id: 'src.components.AccountSwitcher.accountMenuBookings',
    defaultMessage: 'Boekingen',
  },
  accountMenuLogOut: {
    id: 'src.components.AccountSwitcher.accountMenuLogOut',
    defaultMessage: 'Uitloggen',
  },
  accountMenuGiftcards: {
    id: 'src.components.AccountSwitcher.accountMenuGiftcards',
    defaultMessage: 'Hotelgiftcards',
  },
  accountMenuOrders: {
    id: 'src.components.AccountSwitcher.accountMenuOrders',
    defaultMessage: 'Bestellingen',
  },
  accountMenuLogin: {
    id: 'src.components.AccountSwitcher.loginTitle',
    defaultMessage: 'Inloggen',
  },
});

export const hooks = {
  useAccountMenuMappingToProps(
    userName: string | null,
    loggedIn: boolean,
    layoutExtension: LayoutExtension | null,
    onLogOut: () => void,
    isActive: (url: string) => void,
    accountRelease1: boolean,
    accountRelease2: boolean,
    accountRelease3: boolean,
    accountRelease4: boolean,
    isSidebarMenu: boolean,
    openModal: () => void,
    withName: boolean,
  ) {
    const overviewRedirect = navigationHooks.useAccountOverviewRedirect();
    const personalDetailsRedirect = navigationHooks.useAccountPersonalDetailsRedirect();
    const bookingsRedirect = navigationHooks.useAccountBookingsRedirect();
    const giftcardsRedirect = navigationHooks.useAccountGiftcardsRedirect();
    const ordersRedirect = navigationHooks.useAccountOrdersRedirect();
    const { isDesktop } = domHooks.useLayout(layoutExtension);

    return React.useMemo(() => {
      const sidebarMenuTitle = loggedIn
        ? (
          <Typography
            variant='body4'
            className={styles.accountSwitcherSidebarLabel}
          >
            <FormattedMessage {...messages.accountSwitcherSidebarLabel} />
          </Typography>
        )
        : (
          <div
            className={styles.accountSwitcher}
            onClick={openModal}
          >
            <Account />
            {isDesktop
              ? (
                <Typography variant='body4' className={styles.accountSwitcherLabel}>
                  <FormattedMessage {...messages.accountSwitcherLabel} />
                </Typography>
              ) : null
            }
          </div>
        );

      const title = isSidebarMenu
        ? sidebarMenuTitle
        : (
          <div
            className={styles.accountSwitcher}
            onClick={loggedIn ? undefined : openModal}
          >
            <Account />
            {isDesktop && withName
              ? (
                <Typography variant='body4' className={styles.accountSwitcherLabel}>
                  {
                    userName?.length && loggedIn
                      ? userName
                      : <FormattedMessage {...messages.accountSwitcherLabel} />
                  }
                </Typography>
              ) : null}
          </div>
        );

      const menuItemsProps = [
        {
          message: messages.accountMenuMyAccount,
          url: overviewRedirect,
          isActive: isActive('/account/overview'),
          isHidden: !accountRelease1,
        },
        {
          message: messages.accountMenuGiftcards,
          url: giftcardsRedirect,
          isActive: isActive('/account/giftcards'),
          isHidden: !accountRelease3,
        },
        {
          message: messages.accountMenuOrders,
          url: ordersRedirect,
          isActive: isActive('/account/orders'),
          isHidden: !accountRelease4,
        },
        {
          message: messages.accountMenuBookings,
          url: bookingsRedirect,
          isActive: isActive('/account/bookings'),
          isHidden: !accountRelease2,
        },
        {
          message: messages.accountMenuMyData,
          url: personalDetailsRedirect,
          isActive: isActive('/account/personal-details'),
          isHidden: !accountRelease1,
        },
        {
          message: messages.accountMenuLogOut,
          action: onLogOut,
        },
      ];

      const items = loggedIn ? menuItemsProps.filter(item => !item.isHidden).map(({
        message, url, action, isActive,
      }) => (
        action
          ? <Link
            type={LinkType.Span}
            onClick={action}
            className={styles.link}
          >
            <FormattedMessage {...message} />
          </Link>
          : <LocalizedLink
            to={url || ''}
            className={classnames(
              { [styles.active]: isActive },
              styles.link,
            )}
          >
            <FormattedMessage {...message} />
          </LocalizedLink>
      )) : [];

      return { title, items };
    }, [
      isSidebarMenu,
      isActive,
      loggedIn,
      openModal,
      userName,
      overviewRedirect,
      accountRelease1,
      giftcardsRedirect,
      accountRelease3,
      ordersRedirect,
      accountRelease4,
      bookingsRedirect,
      accountRelease2,
      personalDetailsRedirect,
      onLogOut,
      withName,
      isDesktop,
    ]);
  },
  useLoginSidebarAction(
    hideMobileMenu: (() => void) | undefined,
    openModal: () => void,
  ) {
    return React.useCallback(() => {
      if (!hideMobileMenu) {
        return;
      }

      hideMobileMenu();
      openModal();
    }, [hideMobileMenu, openModal]);
  },
};

export type Props = {
  accountRelease1: boolean;
  accountRelease2: boolean;
  accountRelease3: boolean;
  accountRelease4: boolean;
  hideMobileMenu?: () => void;
  isSidebarMenu: boolean;
  layoutExtension: LayoutExtension | null;
  loggedIn: boolean;
  onLogOut: () => void;
  userName: string | null;
  withName?: boolean;
};

export const AccountSwitcher = React.memo((props: Props) => {
  const {
    layoutExtension,
    loggedIn,
    userName,
    onLogOut,
    accountRelease1,
    accountRelease2,
    accountRelease3,
    accountRelease4,
    isSidebarMenu,
    hideMobileMenu,
    withName = true,
  } = props;

  const isActive = navigationHooks.useActivePage();
  const openModal =
    messageHooks.useNextWithValue(GlobalMessages.ModalOpen, Modal.AccountInitialModal);

  const accountMenuProps = hooks.useAccountMenuMappingToProps(
    userName,
    loggedIn,
    layoutExtension,
    onLogOut,
    isActive,
    accountRelease1,
    accountRelease2,
    accountRelease3,
    accountRelease4,
    isSidebarMenu,
    openModal,
    withName,
  );

  const onLoginSidebarAction = hooks.useLoginSidebarAction(
    hideMobileMenu,
    openModal,
  );

  return (
    <>
      {
        isSidebarMenu
          ? loggedIn
            ? <SectionExpandable
              items={accountMenuProps.items}
              title={accountMenuProps.title}
            />
            : <div
              className={styles.accountNavWrapper}
              onClick={onLoginSidebarAction}
            >
              <Account className={styles.accountIcon} />
              <Typography variant='h4' className={styles.accountSwitcherSidebarLabel}>
                <FormattedMessage {...messages.accountSwitcherLabel} />
              </Typography>
            </div>
          : <div>
            <DropdownV2
              dropdownClass={styles.dropdown}
              subMenuClass={styles.subMenu}
              title={accountMenuProps.title}
              items={accountMenuProps.items}
              rtl
            />
          </div>
      }
    </>
  );
});

AccountSwitcher.displayName = 'AccountSwitcher';
