import { defineMessages } from 'react-intl';

export const redirects = defineMessages({
  giftCardRedirect: {
    id: 'src.routes.redirects.giftCardRedirect',
    defaultMessage: '/{language}/buy/',
  },
  hotelSearchRedirect: {
    id: 'src.routes.redirects.hotelSearchRedirect',
    defaultMessage: '/{language}/hotels/',
  },
  hotelGoodToKnowRedirect: {
    id: 'src.routes.redirects.hotelGoodToKnowRedirect',
    defaultMessage: '/{language}/booking/info/{uid}/',
  },
  hotelGoodToKnowStaticRedirect: {
    id: 'src.routes.redirects.hotelGoodToKnowStaticRedirect',
    defaultMessage: '/{language}/booking/info?uuid={uid}',
  },
  giftCardCheckoutRedirect: {
    id: 'src.routes.redirects.giftCardCheckoutRedirect',
    defaultMessage: '/{language}/buy/pay/',
  },
  balanceCheckerRedirect: {
    id: 'src.routes.redirects.balanceCheckerRedirect',
    defaultMessage: '/{language}/check-balance/',
  },
  contactRedirect: {
    id: 'src.routes.redirects.contactRedirect',
    defaultMessage: 'https://hotelgiftcard.zendesk.com/hc/en-gb/articles/8166504056989-PLEASE-CONTACT-US',
  },
  headerContactRedirect: {
    id: 'src.routes.redirects.headerContactRedirect',
    defaultMessage: 'https://hotelgiftcard.zendesk.com/hc/en-gb',
  },
  FAQRedirect: {
    id: 'src.routes.redirects.FAQRedirect',
    defaultMessage: 'https://hotelgiftcard.zendesk.com/hc/en-gb',
  },
  cookiesAndPrivacyRedirect: {
    id: 'src.routes.redirects.cookiesAndPrivacyRedirect',
    defaultMessage: '/{language}/information/cookies/',
  },
  termsAndConditionsRedirect: {
    id: 'src.routes.redirects.termsAndConditionsRedirect',
    defaultMessage: '/{language}/information/general-terms/',
  },
  supplierTermsRedirect: {
    id: 'src.routes.redirects.supplierTermsRedirect',
    defaultMessage: 'https://developer.expediapartnersolutions.com/terms/nl/',
  },
  impressumRedirect: {
    id: 'src.routes.redirects.impressumRedirect',
    defaultMessage: '/{language}/impressum/',
  },
  imprintRedirect: {
    id: 'src.routes.redirects.imprintRedirect',
    defaultMessage: '/{language}/imprint/',
  },
  businessRedirect: {
    id: 'src.routes.redirects.businessRedirect',
    defaultMessage: '/{language}/business/',
  },
  hotelCheckoutV2Route: {
    id: 'src.routes.redirects.hotelCheckoutV2Route',
    defaultMessage: '/{language}/booking/checkout/{uid}/',
  },
  hotelCheckoutV2StaticRedirect: {
    id: 'src.routes.redirects.hotelCheckoutV2StaticRedirect',
    defaultMessage: '/{language}/booking/checkout?uuid={uid}',
  },
  thankYouPageRedirect: {
    id: 'src.routes.redirect.thankYouPageRedirect',
    defaultMessage: '/{language}/booking/success/{uid}',
  },
  thankYouStaticPageRedirect: {
    id: 'src.routes.redirect.thankYouStaticPageRedirect',
    defaultMessage: '/{language}/booking/success?uuid={uid}',
  },
  bookingFailedPageRedirect: {
    id: 'src.routes.redirect.bookingFailedPageRedirect',
    defaultMessage: '/{language}/booking/failed/{uid}',
  },
  bookingFailedStaticPageRedirect: {
    id: 'src.routes.redirect.bookingFailedStaticPageRedirect',
    defaultMessage: '/{language}/booking/failed?uuid={uid}',
  },
  newsletterSubscriptionRedirect: {
    id: 'src.routes.redirect.newsletterSubscriptionRedirect',
    defaultMessage: '/{language}/newsletter-registration/',
  },
  accountOverviewRedirect: {
    id: 'src.routes.redirect.accountOverviewRedirect',
    defaultMessage: '/{language}/account/overview/',
  },
  accountPersonalDetailsRedirect: {
    id: 'src.routes.redirect.accountPersonalDetailsRedirect',
    defaultMessage: '/{language}/account/personal-details/',
  },
  accountBookingsRedirect: {
    id: 'src.routes.redirect.accountBookingsRedirect',
    defaultMessage: '/{language}/account/bookings/',
  },
  accountGiftcardsRedirect: {
    id: 'src.routes.redirect.accountGiftcardsRedirect',
    defaultMessage: '/{language}/account/giftcards/',
  },
  accountOrdersRedirect: {
    id: 'src.routes.redirect.accountOrdersRedirect',
    defaultMessage: '/{language}/account/orders/',
  },
});
