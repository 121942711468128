import React from 'react';

export type Props = {
  className?: string;
  height?: number;
  onClick?(): void;
  width?: number;
};
export const Globe = React.memo((props: Props) => {
  return (
    <svg
      className={props.className}
      width={props.width || 24}
      height={props.height || 24}
      viewBox='0 0 24 24'
      onClick={props.onClick}
    >
      <path d='M 12.068 0.07 C 8.886 0.07 5.8346 1.3342 3.5849 3.5849 C 1.3342 5.8345 0.07 8.886 0.07 12.068 C 0.07 15.25 1.3342 18.3013 3.5849 20.5511 C 5.8345 22.8018 8.886 24.066 12.068 24.066 C 15.25 24.066 18.3013 22.8018 20.5511 20.5511 C 22.8018 18.3015 24.066 15.25 24.066 12.068 C 24.066 8.886 22.8018 5.8346 20.5511 3.5849 C 18.3015 1.3342 15.25 0.07 12.068 0.07 Z M 20.3699 7.2686 H 16.9089 C 16.685 5.8637 16.2812 4.4926 15.7087 3.1901 C 17.6682 4.0011 19.3057 5.4352 20.3699 7.2686 Z M 21.6657 12.068 C 21.6645 12.8779 21.5577 13.6855 21.3485 14.4671 H 17.2151 C 17.2815 13.6752 17.3175 12.871 17.3175 12.068 C 17.3175 11.2638 17.2815 10.4608 17.2151 9.6689 H 21.3485 C 21.5577 10.4505 21.6645 11.2581 21.6657 12.068 Z M 12.068 21.6657 C 11.3718 21.6657 10.2683 19.9145 9.6689 16.8663 H 14.4683 C 13.8677 19.9145 12.7642 21.6657 12.068 21.6657 H 12.068 Z M 9.327 14.4671 C 9.2606 13.7169 9.219 12.9195 9.219 12.068 C 9.219 11.2165 9.2606 10.4179 9.327 9.6689 H 14.8102 C 14.8766 10.4191 14.9182 11.2165 14.9182 12.068 C 14.9182 12.9195 14.8766 13.7181 14.8102 14.4671 H 9.327 Z M 2.4704 12.068 C 2.4716 11.2581 2.5784 10.4505 2.7876 9.6689 H 6.921 C 6.8547 10.4608 6.8187 11.265 6.8187 12.068 C 6.8187 12.8722 6.8547 13.6752 6.921 14.4671 H 2.7876 C 2.5784 13.6855 2.4716 12.8779 2.4704 12.068 Z M 12.0682 2.4703 C 12.7644 2.4703 13.8679 4.2216 14.4673 7.2697 L 9.6691 7.2686 C 10.2685 4.2216 11.3719 2.4703 12.0682 2.4703 V 2.4703 Z M 8.4271 3.1901 C 7.8546 4.4926 7.4508 5.8637 7.227 7.2686 H 3.7659 C 4.8299 5.4352 6.4676 4.001 8.4271 3.1901 Z M 3.7659 16.8674 H 7.227 C 7.4508 18.2723 7.8546 19.6434 8.4271 20.9458 C 6.4677 20.1349 4.8301 18.7008 3.7659 16.8674 Z M 15.7097 20.947 L 15.7087 20.9458 C 16.2812 19.6434 16.685 18.2723 16.9089 16.8674 H 20.3699 C 19.3059 18.7008 17.6682 20.1351 15.7087 20.9458 L 15.7097 20.947 Z' />
    </svg>
  );
});

Globe.displayName = 'Globe';
