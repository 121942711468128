import React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, defineMessages } from 'react-intl';

import { Button, ButtonFit } from '@components/Button/Button';
import { InputField } from '@components/InputField/InputField';
import { Link, LinkType } from '@components/Link/Link';
import { Typography } from '@components/Typography/Typography';
import { RequestPasswordArgs }
  from '@containers/RequestPasswordFormContainer/RequestPasswordFormContainer';

import styles from './RequestPasswordForm.module.css';

const messages = defineMessages({
  description: {
    id: 'src.components.RequestPasswordForm.description',
    defaultMessage: 'Weet je je wachtwoord niet meer? Geen zorgen. Vul hieronder je e-mailadres in. Je ontvangt een mail waarmee je een nieuw wachtwoord kan aanmaken.',
  },
  emailLabel: {
    id: 'src.components.RequestPasswordForm.emailLabel',
    defaultMessage: 'E-mailadres',
  },
  buttonSubmit: {
    id: 'src.components.RequestPasswordForm.buttonSubmit',
    defaultMessage: 'Stuur e-mail',
  },
  backLink: {
    id: 'src.components.RequestPasswordForm.backLink',
    defaultMessage: 'Terug naar inloggen',
  },
});

export type RequestPasswordFormValues = {
  email: string;
};

export type Props = {
  backLinkTitle?: React.ReactNode;
  defaultEmailValue: string;
  loading: boolean;
  onBackButton: () => void;
  onSubmit: (data: RequestPasswordArgs) => void;
};

export const RequestPasswordForm = React.memo((props: Props) => {
  const {
    loading,
    onSubmit,
    defaultEmailValue,
    onBackButton,
    backLinkTitle,
  } = props;

  const methods = useForm<RequestPasswordFormValues>({
    mode: 'all',
    delayError: 1000,
    defaultValues: {
      email: defaultEmailValue,
    },
  });

  const {
    register,
    handleSubmit,
  } = methods;

  return (
    <>
      <Typography variant='body4' className={styles.description}>
        <FormattedMessage {...messages.description} />
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          {...register('email')}
          inputSize='large'
          label={<FormattedMessage {...messages.emailLabel} />}
          labelClassName={styles.label}
          readOnly={true}
        />

        <div className={styles.buttonGroup}>
          <Button
            type='submit'
            intent='primaryV2'
            size='medium'
            loading={loading}
            fit={ButtonFit.Fill}
          >
            <FormattedMessage {...messages.buttonSubmit} />
          </Button>
          <Link
            className={styles.backLink}
            type={LinkType.Span}
            color='blue'
            onClick={onBackButton}
          >
            {backLinkTitle || <FormattedMessage {...messages.backLink} />}
          </Link>
        </div>

      </form>
    </>
  );
});

RequestPasswordForm.displayName = 'RequestPasswordForm';
