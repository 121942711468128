import { CreditCardType } from '@customTypes/common';

export const ABSOLUTE_URL_REGEX = /^(tel:|mailto:|https?:).+/;
export const EMAIL_REGEX = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;

const mastercardPart1 = /^5[1-5][0-9]{14}$/;
const mastercardPart2 = /^2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14})$/;
const mastercardPart3 = /^2(7[0-1][0-9]{13}|720[0-9]{12})$/;
const mastercard = new RegExp(
  `${mastercardPart1.source}|${mastercardPart2.source}|${mastercardPart3.source}`,
);

const maestroPart1 = /^(5018|5020|5038|5612)\d+$/;
const maestroPart2 = /^(5893|6304|6759|6761)\d+$/;
const maestroPart3 = /^(6762|6763|0604|6390)\d+$/;

const maestro = new RegExp(`${maestroPart1.source}|${maestroPart2.source}|${maestroPart3.source}`);

export const cardValidator: Record<string, RegExp> = {
  [CreditCardType.ELECTRON]: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
  [CreditCardType.MAESTRO]: maestro,
  [CreditCardType.DANKORT]: /^(5019)\d+$/,
  [CreditCardType.INTERPAYMENT]: /^(636)\d+$/,
  [CreditCardType.UNIONPAY]: /^(62|88)\d+$/,
  [CreditCardType.VISA]: /^4[0-9]{12}(?:[0-9]{3})?$/,
  [CreditCardType.MASTERCARD]: mastercard,
  [CreditCardType.AMERICAN_EXPRESS]: /^3[47][0-9]{13}$/,
  [CreditCardType.DINERS]: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
  [CreditCardType.DISCOVER]: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
  [CreditCardType.JCB]: /^(?:2131|1800|35\d{3})\d{11}$/,
};
