import React from 'react';

export type Props = {
  className?: string;
  height?: number;
  onClick?(): void;
  width?: number;
};
export const Info = React.memo((props: Props) => {
  return (
    <svg
      width={props.width || 16}
      height={props.height || 16}
      className={props.className}
      onClick={props.onClick}
      viewBox='0 0 13 13'
    >
      <g id='icon/info' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='info' fill='#161616' fillRule='nonzero'>
          <path d='M6.5,0.0746515152 C2.95690909,0.0746515152 0.0746515152,2.95690909 0.0746515152,6.5 C0.0746515152,10.0430909 2.95690909,12.9253485 6.5,12.9253485 C10.0430909,12.9253485 12.9253485,10.0430909 12.9253485,6.5 C12.9253485,2.95690909 10.0430909,0.0746515152 6.5,0.0746515152 Z M6.5,11.1642424 C3.92777273,11.1642424 1.83575758,9.07222727 1.83575758,6.5 C1.83575758,3.92777273 3.92777273,1.83575758 6.5,1.83575758 C9.07222727,1.83575758 11.1642424,3.92777273 11.1642424,6.5 C11.1642424,9.07222727 9.07222727,11.1642424 6.5,11.1642424 Z' id='Shape'></path>
          <polygon id='Shape' points='5.61895455 6.08912121 7.38013939 6.08912121 7.38013939 9.55381818 5.61895455 9.55381818'></polygon>
          <polygon id='Shape' points='5.61895455 3.4469697 7.38090758 3.4469697 7.38090758 5.20892273 5.61895455 5.20892273'></polygon>
        </g>
      </g>
    </svg>
  );
});

Info.displayName = 'Info';
