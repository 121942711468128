import React from 'react';
import { useForm, UseFormSetError } from 'react-hook-form';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Button, ButtonFit } from '@components/Button/Button';
import { InputField } from '@components/InputField/InputField';
import { Link, LinkType } from '@components/Link/Link';
import { toaster } from '@components/Toaster/Toaster';
import { Typography } from '@components/Typography/Typography';
import { UserLoginArgs } from '@containers/LoginFormContainer/LoginFormContainer';
import { GlobalMessages } from '@customTypes/Messages';
import { BaseResponse, StatusResponsePresentational } from '@customTypes/common';
import { formHooks } from '@hooks/formHooks/formHooks';
import { messageHooks } from '@hooks/messageHooks/messageHooks';

import styles from './LoginForm.module.css';

const messages = defineMessages({
  description: {
    id: 'src.components.LoginForm.description',
    defaultMessage: 'Vul je e-mailadres in om in te loggen of een account aan te maken.',
  },
  email: {
    id: 'src.components.LoginForm.email',
    defaultMessage: 'E-mailadres',
  },
  password: {
    id: 'src.components.LoginForm.password',
    defaultMessage: 'Wachtwoord',
  },
  passwordPlaceholder: {
    id: 'src.components.LoginForm.passwordPlaceholder',
    defaultMessage: 'Vul je wachtwoord in',
  },
  requestPassword: {
    id: 'src.components.LoginForm.requestPassword',
    defaultMessage: 'Wachtwoord vergeten?',
  },
  buttonSubmit: {
    id: 'src.components.LoginForm.buttonSubmit',
    defaultMessage: 'Inloggen',
  },
  emptyError: {
    id: 'src.components.LoginForm.emptyError',
    defaultMessage: 'Required field',
  },
  passwordNotCorrect: {
    id: 'src.components.LoginForm.passwordNotCorrect',
    defaultMessage: 'Incorrect password',
  },
  backLink: {
    id: 'src.components.LoginForm.backLink',
    defaultMessage: 'Terug naar inloggen',
  },
  passwordEmailDoesNotMatch: {
    id: 'src.components.LoginForm.passwordEmailDoesNotMatch',
    defaultMessage: 'We do not know this combination of e-mail address and password.',
  },
  alreadyLoggedIn: {
    id: 'src.components.LoginForm.alreadyLoggedIn',
    defaultMessage: 'User already logged in.',
  },
  somethingWentWrong: {
    id: 'src.components.LoginForm.somethingWentWrong',
    defaultMessage: 'Something went wrong',
  },
});

export const validationSchema = yup.object().shape({
  email: yup.string().required('emptyError'),
  password: yup.string().required('emptyError'),
});

export type LoginFormValues = {
  email: string;
  password: string;
};

export type Props = {
  buttonLoading: boolean;
  defaultEmailValue: string;
  onBackButton: () => void;
  onFormSubmit: (
    variables: UserLoginArgs,
    onCompleteCb: (data: BaseResponse<StatusResponsePresentational>) => void
  ) => void;
  onRequestPasswordButton: () => void;
};

export const hooks = {
  useSetErrorCallback(
    setError: UseFormSetError<UserLoginArgs>,
    alreadyLoggedInError: string,
    somethingWentWrong: string,
    modalClose: () => void,
  ) {
    const layoutExtension = messageHooks.useSubjectState(GlobalMessages.LayoutExtension);

    return React.useCallback((data: BaseResponse<StatusResponsePresentational>) => {
      data?.data.errors_v1?.forEach(error => {
        switch (error.code) {
          case 1001:
            setError('password', { message: 'passwordEmailDoesNotMatch' });
            break;
          case 1030:
            toaster({
              layoutExtension,
              text: alreadyLoggedInError,
            }, { type: 'error', autoClose: 4000 });
            modalClose();
            break;
          default:
            toaster({
              layoutExtension,
              text: somethingWentWrong,
            }, { type: 'error', autoClose: 4000 });
            modalClose();
            break;
        }
      });
    }, [setError, layoutExtension, alreadyLoggedInError, somethingWentWrong, modalClose]);
  },
  useHandleSubmit(
    onFormSubmit: (
      formData: UserLoginArgs,
      onCompleteCb: (data: BaseResponse<StatusResponsePresentational>) => void,
    ) => void,
    onErrorSetting: (data: BaseResponse<StatusResponsePresentational>) => void,
  ) {
    return React.useCallback((data: UserLoginArgs) => {
      onFormSubmit({
        email: data.email,
        password: data.password,
      }, onErrorSetting);
    }, [onFormSubmit, onErrorSetting]);
  },
};

export const LoginForm = React.memo((props: Props) => {
  const {
    buttonLoading,
    onFormSubmit,
    onBackButton,
    onRequestPasswordButton,
    defaultEmailValue,
  } = props;

  const intl = useIntl();
  const alreadyLoggedInError = intl.formatMessage(messages.alreadyLoggedIn);
  const somethingWentWrong = intl.formatMessage(messages.somethingWentWrong);

  const methods = useForm<LoginFormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    delayError: 1000,
    defaultValues: {
      email: defaultEmailValue,
      password: '',
    },
  });

  const {
    register,
    handleSubmit,
    formState: { dirtyFields, errors, isValid },
    getValues,
    setError,
  } = methods;

  const modalClose = messageHooks.useNextWithValue(GlobalMessages.ModalOpen, null);
  const onErrorSetting = hooks.useSetErrorCallback(
    setError,
    alreadyLoggedInError,
    somethingWentWrong,
    modalClose,
  );

  const onSubmit = hooks.useHandleSubmit(
    onFormSubmit,
    onErrorSetting,
  );

  const values = getValues();
  const passwordState = formHooks.useCheckState(
    dirtyFields.password,
    values.password,
    errors.password,
  );

  return (
    <form onSubmit={handleSubmit((onSubmit))}>
      <div>
        <Typography variant='body4' className={styles.description}>
          <FormattedMessage {...messages.description} />
        </Typography>

        <InputField
          {...register('email')}
          labelClassName={styles.inputLabel}
          inputSize='large'
          label={<FormattedMessage {...messages.email} />}
          readOnly={true}
        />

        <InputField
          {...register('password')}
          withShowPassword={true}
          labelClassName={styles.inputLabel}
          inputSize='large'
          state={passwordState}
          label={<FormattedMessage {...messages.password} />}
          placeholder={intl.formatMessage(messages.passwordPlaceholder)}
          error={errors.password
              && <FormattedMessage
                {...messages[
                  errors.password?.message as
                  'emptyError' | 'passwordNotCorrect' | 'passwordEmailDoesNotMatch'
                ]}
              />
          }
        />

        <Link
          onClick={onRequestPasswordButton}
          className={styles.requestPassword}
          type={LinkType.Span}
          color='blue'
        >
          <FormattedMessage {...messages.requestPassword} />
        </Link>
      </div>

      <div className={styles.buttonGroup}>
        <Button
          type='submit'
          intent='primaryV2'
          size='medium'
          loading={buttonLoading}
          disabled={!isValid}
          fit={ButtonFit.Fill}
        >
          <FormattedMessage {...messages.buttonSubmit} />
        </Button>
        <Link
          className={styles.backLink}
          type={LinkType.Span}
          color='blue'
          onClick={onBackButton}
        >
          <FormattedMessage {...messages.backLink} />
        </Link>
      </div>
    </form>
  );
});

LoginForm.displayName = 'LoginForm';
