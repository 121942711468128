import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ErrorBoundary } from '@nxte-nl/error-boundary';

import { Modal } from '@components/Modal/Modal';
import { Typography } from '@components/Typography/Typography';
import { StepName } from '@containers/AccountModalStepperContainer/AccountModalStepperContainer';
import { LayoutExtensionContainer }
  from '@containers/LayoutExtensionContainer/LayoutExtensionContainer';
import { Modal as ModalType } from '@customTypes/Modal';

import styles from './AccountModalStepper.module.css';

const messages = defineMessages({
  identifyTitle: {
    id: 'src.components.AccountModalStepper.identifyTitle',
    defaultMessage: 'Inloggen of registreren',
  },
  loginTitle: {
    id: 'src.components.AccountModalStepper.loginTitle',
    defaultMessage: 'Inloggen of registreren',
  },
  registrationTitle: {
    id: 'src.components.AccountModalStepper.registrationTitle',
    defaultMessage: 'Registreren',
  },
  requestPasswordTitle: {
    id: 'src.components.AccountModalStepper.requestPasswordTitle',
    defaultMessage: 'Wachtwoord vergeten',
  },
});

export const hooks = {
  useModalStepperHeightControl(
    activeStep: StepName,
    modalWrapperRef: React.MutableRefObject<HTMLDivElement | null>,
    modalContentRef: React.MutableRefObject<HTMLDivElement | null>,
  ) {
    React.useEffect(() => {
      if (modalWrapperRef.current && modalContentRef.current?.parentElement) {
        const transitionProperties =
          new Set(modalWrapperRef.current.style.transitionProperty.replace(' ', '').split(','));
        transitionProperties.add('height');
        modalWrapperRef.current.style.transitionProperty =
          Array.from(transitionProperties).join(', ');
        modalWrapperRef.current.style.height =
          `${modalContentRef.current.parentElement.scrollHeight}px`;
      }
    }, [activeStep, modalWrapperRef, modalContentRef]);
  },
  useModalWrapperRefCallback(modalWrapperRef: React.MutableRefObject<HTMLDivElement | null>) {
    return React.useCallback((node: HTMLDivElement | null) => {
      if (node) {
        modalWrapperRef.current = node;
        modalWrapperRef.current.style.height = `${modalWrapperRef.current.scrollHeight}px`;
      }
    }, [modalWrapperRef]);
  },
};

export type Props = {
    activeStep: StepName;
    children: JSX.Element | string | null;
    modalClose: () => void;
    modalId: ModalType | null;
};

export const AccountModalStepper = React.memo((props: Props) => {
  const {
    activeStep,
    children,
    modalId,
    modalClose,
  } = props;
  const modalWrapperRef = React.useRef<HTMLDivElement | null>(null);
  const modalContentRef = React.useRef<HTMLDivElement | null>(null);

  const modalWrapperRefCallback = hooks.useModalWrapperRefCallback(modalWrapperRef);

  hooks.useModalStepperHeightControl(activeStep, modalWrapperRef, modalContentRef);

  const titles = React.useMemo(() => ({
    [StepName.IdentifyStep]: (
      <Typography variant='h2' className={styles.title}>
        <FormattedMessage {...messages.identifyTitle} />
      </Typography>
    ),
    [StepName.LoginStep]: (
      <Typography variant='h2' className={styles.title}>
        <FormattedMessage {...messages.loginTitle} />
      </Typography>
    ),
    [StepName.RegisterStep]: (
      <Typography variant='h2' className={styles.title}>
        <FormattedMessage {...messages.registrationTitle} />
      </Typography>
    ),
    [StepName.RequestPasswordStep]: (
      <Typography variant='h2' className={styles.title}>
        <FormattedMessage {...messages.requestPasswordTitle} />
      </Typography>
    ),
  }), []);

  return (
    <>
      <ErrorBoundary>
        <LayoutExtensionContainer>
          {({ layoutExtension, isDesktop }) => {
            return <Modal
              ref={modalWrapperRefCallback}
              title={!isDesktop && titles[activeStep]}
              modalClassName={styles.modal}
              isOpen={modalId === ModalType.AccountInitialModal}
              onClose={modalClose}
              layoutExtension={layoutExtension}
              mobileV2
            >
              <div ref={modalContentRef} className={styles.content}>
                {isDesktop && <div className={styles.header}>
                  {titles[activeStep]}
                </div>}
                {children}
              </div>
            </Modal>;
          }}

        </LayoutExtensionContainer>
      </ErrorBoundary>
    </>
  );
});

AccountModalStepper.displayName = 'AccountModalStepper';
