import React from 'react';

import classnames from 'classnames';

import { Typography } from '../Typography/Typography';
import { CheckMark as CheckMarkIcon } from '../icons/CheckMark/CheckMark';
import styles from './Checkbox.module.css';

export type Props = JSX.IntrinsicElements['input'] & {
  className?: string;
  label?: JSX.Element | string;
  labelClassName?: string;
  ref?: React.Ref<HTMLInputElement>;
  variant?: 'body4';
};

export const Checkbox = React.memo(React.forwardRef((
  props: Props,
  ref: React.ForwardedRef<HTMLInputElement>,
) => {
  const { label, variant = 'body2', className, labelClassName, ...rest } = props;

  return (
    <label className={classnames(
      styles.checkboxContainer,
      className,
      {
        [styles.disabled]: rest.disabled,
        [styles.labelless]: !label,
      },
    )}>
      <input
        ref={ref}
        type='checkbox'
        className={styles.checkbox}
        { ...rest }
      />

      <span className={styles.customCheckbox}>
        <CheckMarkIcon
          width={8}
          height={6}
          color='#fff'
        />
      </span>
      <Typography
        className={classnames(styles.label, labelClassName)}
        variant={variant}
        component='span'
      >
        {label}
      </Typography>
    </label>
  );
}));

Checkbox.displayName = 'Checkbox';
