import React from 'react';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { stateHooks } from '@nxte-nl/state-hooks';

import styles from './SectionExpandable.module.css';

export const hooks = {
  useScrollPanelHeight(callback: (value: number) => void) {
    return React.useCallback((node: HTMLDivElement | null) => {
      if (node) {
        callback(node.scrollHeight);
      }
    }, [callback]);
  },
  useTitleClickHandler(
    toggle: () => void,
    items: JSX.Element[],
    redirect?: string,
  ) {
    const timerRef = React.useRef<NodeJS.Timeout | null>(null);
    return React.useCallback((event: React.MouseEvent) => {
      timerRef.current && clearTimeout(timerRef.current);
      timerRef.current = setTimeout(() => {
        switch (event.detail) {
          case 1:
            items.length === 0 && redirect
              ? window.location.href = redirect
              : toggle();
            break;
          case 2:
            if (redirect) window.location.href = redirect;
            break;
        }
      }, 200);
    }, [items.length, redirect, toggle]);
  },
};

export type Props = {
  items: JSX.Element[];
  title: JSX.Element;
  titleRedirect?: string;
};

export const SectionExpandable = React.memo((props: Props) => {
  const {
    title,
    titleRedirect,
    items,
  } = props;
  const [height, setHeight] = React.useState(0);
  const {
    value: expanded,
    toggle: handlePanelToggle,
  } = stateHooks.useBooleanState();
  const accordionPanelInnerRefCallback = hooks.useScrollPanelHeight(setHeight);
  const handleTitleClick = hooks.useTitleClickHandler(handlePanelToggle, items, titleRedirect);

  return (
    <div
      className={styles.accordion}
      aria-expanded={expanded}
    >
      <button
        className={styles.accordionTitle}
        onClick={handleTitleClick}
      >
        {title}
        {items.length
          ? <FontAwesomeIcon className={styles.chevronIcon} icon={faChevronDown} />
          : null
        }
      </button>
      <div
        className={styles.accordionPanelInner}
        ref={accordionPanelInnerRefCallback}
        style={{ height: `${expanded ? height : 0}px` }}
      >
        <div className={styles.accordionPanelBody}>
          <ol className={styles.accordionBody}>
            {items.map((item, idx) => (
              <li key={`SectionExpandable_${title}_${idx}`} className={styles.accordionItem}>
                { item }
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
});

SectionExpandable.displayName = 'SectionExpandable';
