import React from 'react';
import {
  FieldError,
  FieldNamesMarkedBoolean,
  FieldValues,
  Path, PathValue,
  UseFormSetValue,
} from 'react-hook-form';

import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';
const phoneUtil = PhoneNumberUtil.getInstance();

import { phoneCodesList } from '@components/CountrySelect/CountrySelect';
import { InputState } from '@components/Input/Input';
import { CountryCode } from '@customTypes/apiEnums';

export const formHooks = {
  useCheckState(
    dirtyField: boolean | undefined,
    field: string | number,
    fieldError: FieldError | undefined,
  ) {
    return !dirtyField
      ? field
        ? InputState.Success
        : InputState.Default
      : fieldError
        ? InputState.Error
        : InputState.Success;
  },
  useHandleCountryChange(defaultCountryCode: CountryCode | null = null) {
    const defaultPhoneCode = defaultCountryCode ? phoneCodesList[defaultCountryCode] : '';
    const [phoneCode, setPhoneCode] = React.useState<string>(defaultPhoneCode);
    const [countryCode, setCountryCode] = React.useState<CountryCode | null>(defaultCountryCode);

    const handleCountryChange = React.useCallback((countryCode: CountryCode, phoneCode: string) => {
      setPhoneCode(phoneCode);
      setCountryCode(countryCode);
    }, [setPhoneCode, setCountryCode]);

    return {
      phoneCode,
      countryCode,
      handleCountryChange,
      defaultPhoneCode,
    };
  },
  usePrefillDefaultPhone<T extends Record<'phone', string>,>(
    setValue: UseFormSetValue<T>,
    defaultValuesPhone: string | undefined,
    defaultPhoneCode: string,
  ) {
    const defaultPhone = React.useMemo(() => (
      (defaultValuesPhone || defaultValuesPhone === '')
      && defaultValuesPhone.indexOf(defaultPhoneCode) !== -1
        ? defaultValuesPhone.replace(defaultPhoneCode, '') : ''
    ), [defaultPhoneCode, defaultValuesPhone]);

    React.useEffect(() => {
      if (defaultPhone)
        setValue('phone' as Path<T>, defaultPhone as PathValue<T, Path<T>>, { shouldDirty: true });
    }, [defaultPhone, setValue]);

    return { defaultPhone };
  },
  usePhoneChanged<T extends FieldValues,>(
    defaultPhone: string,
    currentPhone: string,
    defaultCountryCode: string | null | undefined,
    currentCountryCode: string | null | undefined,
    dirtyFields: FieldNamesMarkedBoolean<T>,
  ) {
    const dirtyKeys = Object.keys(dirtyFields);

    const isPhoneNotChangedAndFormNotDirty = defaultPhone === currentPhone
      && dirtyKeys.length === 1
      && dirtyKeys[0] === 'phone'
      && defaultCountryCode === currentCountryCode;

    return { isPhoneNotChangedAndFormNotDirty };
  },
  useGetCountryCode(phoneNumber: string) {
    if (!phoneNumber.length) return null;
    let number: PhoneNumber;

    try {
      number = phoneUtil.parse(phoneNumber);
    } catch (_) {
      return null;
    }
    return phoneUtil.getRegionCodeForNumber(number) as CountryCode;
  },
};
