import React from 'react';

import { LocalizedLink } from '@components/LocalizedLink/LocalizedLink';

type WrapTextOptions = {
  className?: string;
  component?: React.ElementType;
};

type WrapLinkTextOptions = {
  className?: string;
  targetBlank?: boolean;
};

export const textCallbacks = {
  wrapTextHandler(options: WrapTextOptions = {}) {
    const {
      component: Component = 'strong',
      className,
    } = options;

    return (...text: string[]): JSX.Element => {
      return <Component className={className}>{text}</Component>;
    };
  },
  wrapLinkTextHandler(
    url: string,
    options: WrapLinkTextOptions = {},
  ) {
    return (...text: string[]): JSX.Element => {
      const {
        targetBlank,
        className,
      } = options;

      return (
        <LocalizedLink
          to={url}
          className={className}
          externalTargetBlank={targetBlank ? true : undefined}
        >
          {text}
        </LocalizedLink>
      );
    };
  },
};
