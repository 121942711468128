import { Id, toast, ToastOptions } from 'react-toastify';

import classnames from 'classnames';

import { LayoutExtension } from '@customTypes/ResponsiveState';

import styles from './Toaster.module.css';

export type Props = {
  layoutExtension: LayoutExtension | null;
  text: string;
};

export const toaster =
  ({ layoutExtension, ...props }: Props, toastProps?: ToastOptions): Id =>
    toast(props.text, {
      autoClose: false,
      position: layoutExtension === LayoutExtension.Mobile ? 'bottom-center' : 'top-right',
      theme: 'colored',
      className: classnames(
        styles.content, styles.increaseSpecificity,
        {
          [styles.success]: toastProps?.type === 'success',
          [styles.error]: toastProps?.type === 'error',
        },
      ),
      ...toastProps,
    });

