import React from 'react';

export type Props = {
  className?: string;
  color?: string;
  height?: number;
  onClick?(): void;
  width?: number;
};
export const CheckMark = React.memo((props: Props) => {
  return (
    <svg
      width={props.width || 16}
      height={props.height || 16}
      className={props.className}
      onClick={props.onClick}
      viewBox='0 0 8 6'
    >
      <g id='icon/checkmark' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path d='M7.14923652,0.000355559316 C6.93444171,0.00620725748 6.73094754,0.09504358 6.5807593,0.247183647 C5.27207653,1.5430277 4.0775302,2.81337867 2.8210922,4.08149627 L1.34387833,2.91118387 C1.17268745,2.77553533 0.954130354,2.71276814 0.736096949,2.73670876 C0.517539855,2.76064666 0.318359222,2.86916658 0.181084034,3.03886719 C0.0443476937,3.20803125 -0.0191715381,3.42400072 0.00505592703,3.6394527 C0.0292806359,3.85542217 0.139100786,4.05224459 0.3102834,4.1878945 L2.37747326,5.82206666 C2.70800314,6.08165491 3.18282287,6.05452765 3.47997452,5.75823181 C4.96683528,4.28584269 6.29920805,2.83932796 7.75216689,1.40043937 C7.99495145,1.16477813 8.06709638,0.806240753 7.93412094,0.497177943 C7.80061493,0.187584026 7.48945462,-0.0092520112 7.14923652,0.000335132164 L7.14923652,0.000355559316 Z' id='Fill-1' fill={props.color || '#000'}></path>
      </g>
    </svg>
  );
});

CheckMark.displayName = 'CheckMark';
