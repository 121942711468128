import React from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';

import classnames from 'classnames';

import { ExpediaLogo } from '../icons/ExpediaLogo/ExpediaLogo';
import styles from './ExpediaLogoPlaceholder.module.css';

export const logoSizes = {
  large: {
    width: 87,
    height: 20,
  },
  medium: {
    width: 75,
    height: 16,
  },
  extraMedium: {
    width: 73,
    height: 22,
  },
  small: {
    width: 61,
    height: 21,
  },
  extraSmall: {
    width: 51,
    height: 11,
  },
};

export type Props = {
  className?: string;
  logoClassName?: string;
  message: MessageDescriptor;
  size?: 'large' | 'medium' | 'extraMedium' | 'small' | 'extraSmall';
};
export const ExpediaLogoPlaceholder = React.memo((props: Props) => {
  const { message, className, logoClassName, size = 'medium' } = props;
  const { width, height } = logoSizes[size];
  return (
    <span className={classnames(styles.expediaLogoPlaceholder, className, {
      [styles.fontLarge]: size === 'large',
    })}>
      <FormattedMessage
        {...message}
        values={{
          expediaLogo: (
            <ExpediaLogo
              width={width}
              height={height}
              className={classnames(styles.expediaLogo, logoClassName)}
            />
          ),
        }}
      />
    </span>
  );
});

ExpediaLogoPlaceholder.displayName = 'ExpediaLogoPlaceholder';
