import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { LanguageSwitcherModalV2 }
  from '@components/LanguageSwitcherModalV2/LanguageSwitcherModalV2';
import { CountryCode } from '@customTypes/apiEnums';

import { Button, ButtonFit } from '../Button/Button';
import { Typography } from '../Typography/Typography';
import { Close } from '../icons/Close/Close';
import { Globe } from '../icons/Globe/Globe';
import styles from './CountrySelectionPanel.module.css';

export const messages = defineMessages({
  countrySelectMessage: {
    id: 'src.components.CountrySelectionPanel.countrySelectMessage',
    defaultMessage: 'You are visiting a website that does not deliver to the country where you are now. Want to exchange to your local store?',
  },
  netherlands: {
    id: 'src.components.CountrySelectionPanel.netherlands',
    defaultMessage: '{languageCode}, Netherlands',
  },
  belgium: {
    id: 'src.components.CountrySelectionPanel.belgium',
    defaultMessage: '{languageCode}, Belgium',
  },
  germany: {
    id: 'src.components.CountrySelectionPanel.germany',
    defaultMessage: '{languageCode}, Germany',
  },
  spain: {
    id: 'src.components.CountrySelectionPanel.spain',
    defaultMessage: '{languageCode}, Spain',
  },
  france: {
    id: 'src.components.CountrySelectionPanel.france',
    defaultMessage: '{languageCode}, France',
  },
  italy: {
    id: 'src.components.CountrySelectionPanel.italy',
    defaultMessage: '{languageCode}, Italy',
  },
  britain: {
    id: 'src.components.CountrySelectionPanel.britain',
    defaultMessage: '{languageCode}, Great Britain',
  },
  openModalButton: {
    id: 'src.components.CountrySelectionPanel.openModalButton',
    defaultMessage: 'change',
  },
  continueButton: {
    id: 'src.components.CountrySelectionPanel.continueButton',
    defaultMessage: 'Continue',
  },
});

export const hooks = {
  useMappedLabelToCountry(countryCode: CountryCode, regionCode: string) {
    const intl = useIntl();
    let belgiumLanguage;
    if (countryCode === 'BE') {
      if (regionCode === 'VLG') {
        belgiumLanguage = 'NL';
      }
      if (regionCode === 'WAL'
        || regionCode === 'BRU') {
        belgiumLanguage = 'FR';
      }
    }
    switch (countryCode) {
      case CountryCode.NL:
        return intl.formatMessage(messages.netherlands, {
          languageCode: CountryCode.NL,
        });
      case CountryCode.BE:
        return intl.formatMessage(messages.belgium, {
          languageCode: belgiumLanguage,
        });
      case CountryCode.DE:
        return intl.formatMessage(messages.germany, {
          languageCode: CountryCode.DE,
        });
      case CountryCode.ES:
        return intl.formatMessage(messages.spain, {
          languageCode: CountryCode.ES,
        });
      case CountryCode.FR:
        return intl.formatMessage(messages.france, {
          languageCode: CountryCode.FR,
        });
      case CountryCode.IT:
        return intl.formatMessage(messages.italy, {
          languageCode: CountryCode.IT,
        });
      default:
        return intl.formatMessage(messages.britain, {
          languageCode: CountryCode.GB,
        });
    }
  },
  useLanguageSwitcherModal(setLanguageSwitcherModalOpen: (isOpen: boolean) => void) {
    const handleModalOpen = () => {
      setLanguageSwitcherModalOpen(true);
    };

    const handleModalClose = () => {
      setLanguageSwitcherModalOpen(false);
    };

    return {
      handleModalClose,
      handleModalOpen,
    };
  },
};

export type Props = {
  countryCode: CountryCode;
  onClose: () => void;
  onLanguageSelected: () => void;
  regionCode: string;
};

export const CountrySelectionPanel = React.memo((props: Props) => {
  const { countryCode, regionCode, onClose, onLanguageSelected } = props;

  const [isLanguageSwitcherModalOpen, setLanguageSwitcherModalOpen] = React.useState(false);

  const { handleModalClose, handleModalOpen } =
    hooks.useLanguageSwitcherModal(setLanguageSwitcherModalOpen);
  const label = hooks.useMappedLabelToCountry(countryCode, regionCode);

  return (
    <div className={styles.countrySelectionPanel}>
      <div className={styles.topRow}>
        <Typography variant='body4' className={styles.message}>
          <FormattedMessage {...messages.countrySelectMessage} />
        </Typography>
        <Close
          className={styles.countryPanelCloseButton}
          width={14}
          height={14}
          color='#fff'
          onClick={onClose}
        />
      </div>
      <div className={styles.bottomRow}>
        <div className={styles.selectedLanguage}>
          <div className={styles.languageAndCountryBlock}>
            <Globe
              width={20}
              height={20}
            />
            <Typography variant='body4' className={styles.languageAndCountry}>
              {label}
            </Typography>
          </div>
          <Button
            intent='tertiary'
            size='small'
            color='black'
            fit={ButtonFit.Inline}
            onClick={handleModalOpen}
            className={styles.changeLanguageButton}
          >
            <FormattedMessage {...messages.openModalButton} />
          </Button>
        </div>
        <Button
          intent='secondaryV2'
          size='small'
          fit={ButtonFit.Inline}
          onClick={onLanguageSelected}
          className={styles.continueButton}
        >
          <FormattedMessage {...messages.continueButton} />
        </Button>
      </div>
      <LanguageSwitcherModalV2
        isOpen={isLanguageSwitcherModalOpen}
        onCloseLanguageModal={handleModalClose}
        onCloseModals={handleModalClose}
      />
    </div>
  );
});

CountrySelectionPanel.displayName = 'CountrySelectionPanel';
