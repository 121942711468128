import React from 'react';

import { GiftCardBasketButton } from '@components/GiftCardBasketButton/GiftCardBasketButton';
import { GiftCardCheckoutMessages, GlobalMessages } from '@customTypes/Messages';
import { Modal } from '@customTypes/Modal';
import { Shipment } from '@customTypes/apiTypes';
import { messageHooks } from '@hooks/messageHooks/messageHooks';

export const hooks = {
  useGiftCardBasketProductsQuantity(shipments?: Shipment[] | null) {
    return React.useMemo(() => (shipments ?? [])?.reduce((acc, shipment) => {
      return shipment.packages ? shipment.packages.length + acc : acc;
    }, 0), [shipments]);
  },
};

export const GiftCardBasketButtonContainer = React.memo(() => {
  const giftcardBasket = messageHooks.useSubjectState(GiftCardCheckoutMessages.GiftcardBasket);
  const quantity = hooks.useGiftCardBasketProductsQuantity(giftcardBasket?.data.order?.shipments);
  const openModal = messageHooks.useNextWithValue(GlobalMessages.ModalOpen, Modal.GiftCardBasket);

  return <GiftCardBasketButton quantity={quantity} onClick={openModal} />;
});

GiftCardBasketButtonContainer.displayName = 'GiftCardBasketButtonContainer';
