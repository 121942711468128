import React from 'react';

import classNames from 'classnames';

import { SelectItem } from '../BasicSelect/BasicSelect';
import styles from './BasicSelectItem.module.css';

export const hooks = {
  useSelectValue(item: SelectItem, handleSelectValue: (item: SelectItem) => void) {
    return React.useCallback(() => {
      handleSelectValue(item);
    }, [handleSelectValue, item]);
  },
};

export type Props = {
  classes?: {
    basicSelectItem?: string;
  };
  currentSelectedId: string;
  handleSelectValue: (item: SelectItem) => void;
  highlighted: boolean;
  item: SelectItem;
};

export const BasicSelectItem = React.forwardRef(
  (props: Props, ref: React.ForwardedRef<HTMLDivElement> | null) => {
    const { currentSelectedId, item, highlighted, handleSelectValue, classes } = props;

    const handleClick = hooks.useSelectValue(item, handleSelectValue);

    return (
      <div
        className={classNames(styles.basicSelectItem, classes?.basicSelectItem, {
          [styles.selectedItem]: item.id === currentSelectedId,
          [styles.basicSelectItemWithIcon]: item.icon,
          [styles.highlighted]: highlighted,
        })}
        onClick={handleClick}
        ref={ref}
      >
        {item.radioButton && item.radioButton}
        {item.icon && item.icon}
        {item.label}
      </div>
    );
  },
);

BasicSelectItem.displayName = 'BasicSelectItem';
