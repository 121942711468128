import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { apolloHooks } from '@nxte-nl/apollo-hooks';

import { AccountRegistrationForm, AccountRegistrationFormValues, RegistrationDefaultValues }
  from '@components/AccountRegistrationForm/AccountRegistrationForm';
import { toaster } from '@components/Toaster/Toaster';
import { GlobalMessages } from '@customTypes/Messages';
import { Modal } from '@customTypes/Modal';
import { LayoutExtension } from '@customTypes/ResponsiveState';
import { BaseVariables } from '@customTypes/apiCompound';
import {
  Error,
  CustomerType,
  MutationuserRegisterArgs,
  StatusResponse,
} from '@customTypes/apiTypes';
import { BaseResponseV2 } from '@customTypes/common';
import { googleRecaptchaHooks } from '@hooks/googleRecaptchaHooks/googleRecaptchaHooks';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import { userRegister } from '@mutations/userRegister.gql';

const messages = defineMessages({
  inputError: {
    id: 'src.containers.AccountRegistrationFormContainer.inputError',
    defaultMessage: 'Invalid (or empty) input',
  },
  captchaError: {
    id: 'src.containers.AccountRegistrationFormContainer.captchaError',
    defaultMessage: 'Captcha verification failed',
  },
  loggedInError: {
    id: 'src.containers.AccountRegistrationFormContainer.loggedInError',
    defaultMessage: 'User already logged',
  },
  existsUserError: {
    id: 'src.containers.AccountRegistrationFormContainer.existsUserError',
    defaultMessage: 'Exists user with email {email}',
  },
});

export const hooks = {
  useErrorHandling: (
    layoutExtension: LayoutExtension | null,
    errors: Error[],
    email: string,
  ) => {
    const intl = useIntl();
    let text = '';

    errors.forEach(error => {
      switch (error.code) {
        case 1001:
          text = intl.formatMessage(messages.inputError);
          break;
        case 1005:
          text = intl.formatMessage(messages.captchaError);
          break;
        case 1030:
          text = intl.formatMessage(messages.loggedInError);
          break;
        case 1031:
          text = intl.formatMessage(messages.existsUserError, {
            email: email,
          });
          break;
        default:
          text = error.message;
          break;
      }
    });

    toaster({
      layoutExtension,
      text: text,
    }, { type: 'error', autoClose: 4000 });
  },
  useUserRegister: (
    confirmationModalOpen: () => void,
    setDefaultStep?: () => void,
  ) => {
    const { executeRecaptcha } = googleRecaptchaHooks.useExecuteRecaptcha();
    const layoutExtension = messageHooks.useSubjectState(GlobalMessages.LayoutExtension);

    const [mutate, { loading }] = apolloHooks
      .useMutation<BaseResponseV2<StatusResponse>,
      BaseVariables<MutationuserRegisterArgs>>(
        userRegister, {
          refetchQueries: ['getUserInfo'],
        },
      );

    const handleSubmit = React.useCallback(async (
      variables: AccountRegistrationFormValues,
      onCompleteCb?: (data: BaseResponseV2<StatusResponse>) => void,
    ) => {
      const captchaToken = await executeRecaptcha();
      if (!captchaToken) return;
      return mutate({
        variables: {
          ...variables,
          phoneNumber: variables.phone,
          firstName: variables.fullName.firstName,
          lastName: variables.fullName.lastName,
          customerType: CustomerType.PRIVATE,
          companyName: '',
          captchaToken: captchaToken,
        },
        onCompleted: (data) => {
          onCompleteCb?.(data);

          if (data.data.errors_v1?.length) {
            hooks.useErrorHandling(layoutExtension, data.data.errors_v1, variables.email);
          }
          if (!data || data.data.errors?.length) return;
          confirmationModalOpen();
          setDefaultStep && setDefaultStep();
        },
        context: { ignoreError: true },
      });
    }, [confirmationModalOpen, executeRecaptcha, layoutExtension, mutate, setDefaultStep]);

    return {
      handleSubmit,
      loading,
    };
  },
};

export type Props = {
  handleBackButton?: () => void;
  prefillData: RegistrationDefaultValues | null;
  setDefaultStep?: () => void;
};

export const AccountRegistrationFormContainer = React.memo((props: Props) => {
  const {
    handleBackButton,
    setDefaultStep,
    prefillData,
  } = props;

  const confirmationModalOpen =
  messageHooks.useNextWithValue(GlobalMessages.ModalOpen, Modal.AccountRegistrationConfirmModal);
  const {
    handleSubmit,
    loading,
  } = hooks.useUserRegister(confirmationModalOpen, setDefaultStep);

  return (
    <AccountRegistrationForm
      loading={loading}
      onSubmit={handleSubmit}
      prefillData={prefillData}
      handleBackButton={handleBackButton}
    />
  );
});

AccountRegistrationFormContainer.displayName = 'AccountRegistrationFormContainer';
