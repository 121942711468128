import React from 'react';

import { AccountModalStepper } from '@components/AccountModalStepper/AccountModalStepper';
import { ANIMATION_DURATION } from '@components/Modal/Modal';
import { AccountIdentifyFormContainer }
  from '@containers/AccountIdentifyFormContainer/AccountIdentifyFormContainer';
import { AccountRegistrationFormContainer }
  from '@containers/AccountRegistrationFormContainer/AccountRegistrationFormContainer';
import { LoginFormContainer } from '@containers/LoginFormContainer/LoginFormContainer';
import { RequestPasswordFormContainer }
  from '@containers/RequestPasswordFormContainer/RequestPasswordFormContainer';
import { GlobalMessages } from '@customTypes/Messages';
import { messageHooks } from '@hooks/messageHooks/messageHooks';

export enum StepName {
  IdentifyStep = 'identifyStep',
  LoginStep = 'loginStep',
  RegisterStep = 'registerStep',
  RequestPasswordStep = 'RequestPasswordStep',
}

export const hooks = {
  useActiveContent(
    setActive: (active: StepName) => void,
    setEmail: (value: string | null) => void,
  ) {
    const setIdentifyStep = () => {
      setActive(StepName.IdentifyStep);
      setEmail(null);
    };

    const setRegisterStep = () => {
      setActive(StepName.RegisterStep);
    };

    const setLoginStep = () => {
      setActive(StepName.LoginStep);
    };

    const setRequestPasswordStep = () => {
      setActive(StepName.RequestPasswordStep);
    };

    return {
      setIdentifyStep,
      setRegisterStep,
      setLoginStep,
      setRequestPasswordStep,
    };
  },
  useStateEmail() {
    return React.useState<string | null>(null);
  },
  useStateActiveStep(defaultValue: StepName) {
    return React.useState<StepName>(defaultValue);
  },
  useResetFormsToDefaultOnClose(
    setActive: (active: StepName) => void,
    setEmail: (value: string | null) => void,
    modalClose: () => void,
  ) {
    return React.useCallback(() => {
      modalClose();

      setTimeout(() => {
        setActive(StepName.IdentifyStep);
        setEmail(null);
      }, ANIMATION_DURATION);
    }, [modalClose, setActive, setEmail]);
  },
};

export const AccountModalStepperContainer = () => {
  const modalId = messageHooks.useSubjectState(GlobalMessages.ModalOpen);
  const modalClose = messageHooks.useNextWithValue(GlobalMessages.ModalOpen, null);
  const [active, setActive] = hooks.useStateActiveStep(StepName.IdentifyStep);
  const [emailValue, setEmail] = hooks.useStateEmail();
  const handleModalClose =
    hooks.useResetFormsToDefaultOnClose(setActive, setEmail, modalClose);

  const {
    setIdentifyStep,
    setRegisterStep,
    setLoginStep,
    setRequestPasswordStep,
  } = hooks.useActiveContent(setActive, setEmail);

  const steps = {
    [StepName.IdentifyStep]: () => (
      <AccountIdentifyFormContainer
        setLoginStep={setLoginStep}
        setRegisterStep={setRegisterStep}
        setEmail={setEmail}
      />
    ),
    [StepName.RegisterStep]: () => (
      emailValue && <AccountRegistrationFormContainer
        handleBackButton={setIdentifyStep}
        prefillData={{
          email: emailValue,
          fullName: {
            firstName: '',
            lastName: '',
          },
          phone: '',
        }}
        setDefaultStep={setIdentifyStep}
      />
    ),
    [StepName.LoginStep]: () => (
      emailValue && <LoginFormContainer
        onBackButton={setIdentifyStep}
        onRequestPasswordButton={setRequestPasswordStep}
        defaultEmailValue={emailValue}
      />
    ),
    [StepName.RequestPasswordStep]: () => (
      emailValue && <RequestPasswordFormContainer
        onBackButton={setLoginStep}
        defaultEmailValue={emailValue}
        setDefaultStep={setIdentifyStep}
      />
    ),
  };

  return (
    <AccountModalStepper
      activeStep={active}
      modalId={modalId}
      modalClose={handleModalClose}
    >
      { steps[active]() }
    </AccountModalStepper>
  );
};

AccountModalStepperContainer.displayName = 'AccountModalStepperContainer';
