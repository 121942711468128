import React from 'react';
import { IntlProvider } from 'react-intl';

import { MaintenancePage } from '@components/MaintenancePage/MaintenancePage';
import { ApiErrorContainer } from '@containers/ApiErrorContainer/ApiErrorContainer';
import { ApolloServerContainer } from '@containers/ApolloContainer/ApolloServerContainer';
import {
  IsomorphicRoutingContainer,
} from '@containers/IsomorphicRoutingContainer/IsomorphicRoutingContainer';
import { ResponsiveStateContainer } from
  '@containers/ResponsiveStateContainer/ResponsiveStateContainer';
import { GlobalMessages } from '@customTypes/Messages';
import { LanguageCodeMapped } from '@customTypes/common';
import { domHooks } from '@hooks/domHooks/domHooks';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import { serverUtils } from '@utils/serverUtils/serverUtils';

export type Props = {
  children: JSX.Element;
  language: LanguageCodeMapped;
  messages: {[key: string]: string };
};

export const NextPageWrapper = React.memo((props: Props) => {
  const { children, language, messages } = props;

  const isServerSide = serverUtils.isServerSide();
  const { maintenanceMode, trustBadge } =
    messageHooks.useSubjectState(GlobalMessages.FeatureToggles);
  domHooks.useTrustBadgeScripts(language, trustBadge);

  return (
    <>
      {
        (!isServerSide && maintenanceMode)
          ? <IntlProvider
            defaultLocale={language}
            locale={language}
            messages={messages}
          >
            <MaintenancePage />
          </IntlProvider>
          : <ApolloServerContainer language={language}>
            <IntlProvider
              defaultLocale={language}
              locale={language}
              messages={messages}
            >
              <ApiErrorContainer />
              <IsomorphicRoutingContainer>
                <>
                  {!serverUtils.isServerSide() && <ResponsiveStateContainer />}
                  {children}
                </>
              </IsomorphicRoutingContainer>
            </IntlProvider>
          </ApolloServerContainer>
      }
    </>
  );
});

NextPageWrapper.displayName = 'NextPageWrapper';
