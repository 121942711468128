import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { serverUtils } from '@utils/serverUtils/serverUtils';

export type Props = {
  children: JSX.Element;
};

export const IsomorphicRoutingContainer = ({ children }: Props) => {
  return (
    serverUtils.isServerSide() ? children
      : <Router>
        { children }
      </Router>
  );
};

IsomorphicRoutingContainer.displayName = 'IsomorphicRoutingContainer';
