import React from 'react';

import { ApolloMiddleware } from '@nxte-nl/apollo-hooks';

import { GlobalMessages } from '@customTypes/Messages';
import { Modal } from '@customTypes/Modal';
import { ErrorV1 } from '@customTypes/common';

import { messageHooks } from '../messageHooks/messageHooks';

export const callbacks = {
  logErrorsToConsole(error: unknown) {
    if (typeof error === 'string') {
      console.error(error); // eslint-disable-line no-console
      return;
    }

    if (Array.isArray(error)) {
      error.map(err => callbacks.logErrorsToConsole(err));
      return;
    }

    if (error && typeof error === 'object') {
      Object.values(error).map(err => callbacks.logErrorsToConsole(err));
      return;
    }

    console.error(error); // eslint-disable-line no-console
  },
};

export const apolloMiddlewareHooks = {
  useDefaultErrorMiddleware(): ApolloMiddleware {
    const openModal = messageHooks.useNext(GlobalMessages.ModalOpen);

    return React.useCallback((data) => {
      const contextErrors = data.errors;
      const responseData = data.data?.data;
      const dataErrors = responseData && 'errors' in responseData && responseData.errors;

      if ((contextErrors && contextErrors.length) || (dataErrors && dataErrors.length)) {
        callbacks.logErrorsToConsole(contextErrors?.length && contextErrors || dataErrors);
        openModal(Modal.ApiError);
      }

      return data;
    },[openModal]);
  },
  useErrorCodeMiddleware() {
    const [errors, setErrors] = React.useState<ErrorV1[]>();

    const handleErrorCodeMiddleware: ApolloMiddleware =
      React.useCallback((data) => {
        const errorsData = data.data?.data;
        const errors = errorsData && 'errors_v1' in errorsData && errorsData.errors_v1;
        if (errors && errors.length) setErrors(errors);
        return data;
      }, [setErrors]);

    return { errors, setErrors, handleErrorCodeMiddleware };
  },
};
