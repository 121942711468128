import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import getSymbolFromCurrency from 'currency-symbol-map';

import { ThrobberWrapper } from '@components/ThrobberWrapper/ThrobberWrapper';

import { BasicSelect, SelectItem } from '../BasicSelect/BasicSelect';
import { Button, ButtonFit } from '../Button/Button';
import { Input } from '../Input/Input';
import { Typography } from '../Typography/Typography';
import styles from './LanguageFormV2.module.css';

const messages = defineMessages({
  title: {
    id: 'src.components.LanguageFormV2.title',
    defaultMessage: 'Selecteer jouw regio en taal',
  },
  selectCountry: {
    id: 'src.components.LanguageFormV2.selectCountry',
    defaultMessage: 'Regio',
  },
  selectLanguage: {
    id: 'src.components.LanguageFormV2.selectLanguage',
    defaultMessage: 'Taal',
  },
  currency: {
    id: 'src.components.LanguageFormV2.currency',
    defaultMessage: 'Valuta',
  },
  buttonApply: {
    id: 'src.components.LanguageFormV2.buttonApply',
    defaultMessage: 'Opslaan en sluiten',
  },
});

export type Props = {
  countryData: SelectItem[] | null;
  currency: string;
  defaultCountryId: string;
  defaultLangId: string;
  handleCountrySelectChange: (id: string) => void;
  handleLanguageSelectChange: (id: string) => void;
  languageData: SelectItem[] | null;
  onLanguageSelected?: () => void;
};

export const LanguageFormV2 = React.memo((props: Props) => {
  const {
    countryData,
    languageData,
    currency,
    handleCountrySelectChange,
    handleLanguageSelectChange,
    onLanguageSelected,
    defaultCountryId,
    defaultLangId,
  } = props;

  const currencySymbol = React.useMemo(() => getSymbolFromCurrency(currency), [currency]);

  const currencyFieldValue = currencySymbol ? `${currencySymbol} (${currency})` : currency;

  return !countryData || !languageData
    ? <ThrobberWrapper
      throbberWrapperClassName={styles.languageFormSpinner}
    />
    : (
      <form className={styles.languageForm}>
        <div className={styles.languageFormContent}>
          <Typography variant='h2' className={styles.languageFormTitle}>
            <FormattedMessage {...messages.title} />
          </Typography>

          <div>
            <div className={styles.inputWrapper}>
              <Typography component='label' variant='body4' className={styles.inputLabel}>
                <FormattedMessage {...messages.selectCountry} />
              </Typography>
              <BasicSelect
                onChange={handleCountrySelectChange}
                items={countryData}
                defaultSelectedItemId={defaultCountryId}
                search={true}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Typography component='label' variant='body4' className={styles.inputLabel}>
                <FormattedMessage {...messages.selectLanguage} />
              </Typography>
              <BasicSelect
                items={languageData}
                onChange={handleLanguageSelectChange}
                disabled={languageData.length === 1}
                defaultSelectedItemId={defaultLangId}
                search={true}
              />
            </div>
            <div className={styles.inputWrapper}>
              <Typography component='label' variant='body4' className={styles.inputLabel}>
                <FormattedMessage {...messages.currency} />
              </Typography>
              <Input
                inputSize='medium'
                inputClassName={styles.inputField}
                readOnly={true}
                value={currencyFieldValue}
              />
            </div>
          </div>
        </div>

        <div className={styles.languageFormFooter}>
          <Button
            type='button'
            intent='primary'
            size='medium'
            fit={ButtonFit.Inline}
            onClick={onLanguageSelected}
            className={styles.languageFormApplyButton}
          >
            <FormattedMessage {...messages.buttonApply} />
          </Button>
        </div>
      </form>
    );
});

LanguageFormV2.displayName = 'LanguageFormV2';
