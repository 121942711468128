import React from 'react';

import {
  BaseProps,
  Link as LinkComponent,
  LinkType,
} from '@components/Link/Link';
import { ABSOLUTE_URL_REGEX } from '@constants/regularExpressions';

export type Props = BaseProps & {
  externalTargetBlank?: boolean;
  to: string | (() => string);
};

export const LocalizedLink = React.memo((props: Props) => {
  const {
    children,
    to,
    externalTargetBlank,
    ...rest
  } = props;

  const toString = typeof to === 'function' ? to() : to;

  return (
    <LinkComponent
      to={toString}
      type={toString.match(ABSOLUTE_URL_REGEX) ? LinkType.ExternalHref : LinkType.Href}
      {...rest}
      target={
        toString.match(ABSOLUTE_URL_REGEX) || externalTargetBlank
          ? '_blank'
          : '_self'
      }
    >
      {children}
    </LinkComponent>
  );
});

LocalizedLink.displayName = 'LocalizedLink';
