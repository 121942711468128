import React from 'react';

import { Typography } from '../Typography/Typography';
import { Basket } from '../icons/Basket/Basket';
import styles from './GiftCardBasketButton.module.css';

export type Props = {
  onClick?: () => void;
  quantity?: number;
};
export const GiftCardBasketButton = React.memo(( props: Props) => {
  const { quantity = 0, onClick } = props;

  return (
    <button className={styles.giftCardBasketButton} onClick={onClick}>
      <Basket/>
      {
        quantity > 0 && (
          <div className={styles.badge}>
            <Typography className={styles.badgeText} variant='body4'>
              {quantity}
            </Typography>
          </div>
        )
      }
    </button>
  );
});

GiftCardBasketButton.displayName = 'GiftCardBasketButton';
