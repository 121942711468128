import React from 'react';

export type Props = {
  boldIcon?: boolean;
  className?: string;
  color?: string;
  height?: number;
  onClick?(): void;
  width?: number;
};
export const Close = React.memo((props: Props) => {
  return (
    <svg
      viewBox='0 0 16 16'
      width={props.width || 16}
      height={props.height || 16}
      className={props.className}
      onClick={props.onClick}
    >
      <g id='icon/close' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
        <path d='M2.31827762,2 L14,14 M13.6817224,2 L2,14' id='Combined-Shape' stroke={props.color || '#161616'} strokeWidth={props.boldIcon ? '3.5' : '2.16'}></path>
      </g>
    </svg>
  );
});
