import { apolloHooks } from '@nxte-nl/apollo-hooks';

import { AccountMessages } from '@customTypes/Messages';
import { UserInfoData } from '@customTypes/apiCompound';
import { accountHooks } from '@hooks/accountHooks/accountHooks';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';
import { getUserInfo } from '@queries/getUserInfo.gql';

export const hooks = {
  useGetApiData(
    onCompleted: (data: UserInfoData) => void,
    setLoading: (value: boolean) => void,
    logOut: () => void,
  ) {
    const pathname = navigationHooks.usePathname();
    const homePageRedirect = navigationHooks.useHomePageRedirect();

    const { loading } = apolloHooks.useQuery<UserInfoData>(getUserInfo, {
      onCompleted: (data) => {
        if (data?.data.errors_v1?.length && !data.data.errors_v1.some(e => e.code === 1034))
          return logOut();
        if (pathname.includes('/account/') && !data?.data.user)
          return window.location.href = homePageRedirect();
        onCompleted(data);
      },
    });

    // timeOut is needed to clear console warnings
    setTimeout(() => {
      setLoading(loading);
    }, 0);
  },
};

export const UserInfoContainer = () => {
  const setUserInfo = messageHooks.useNext(AccountMessages.UserInfo);
  const setUserInfoLoading = messageHooks.useNext(AccountMessages.UserInfoLoading);
  const { onLogOut } = accountHooks.useLogOut();
  hooks.useGetApiData(setUserInfo, setUserInfoLoading, onLogOut);

  return null;
};

UserInfoContainer.displayName = 'UserInfoContainer';
