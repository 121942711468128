import React from 'react';

import { OrderModalItem }
  from '@containers/AccountOrderDetailsModalContainer/AccountOrderDetailsModalContainer';
import {
  DataLayerEcommerceGiftCardProductV3,
  DataLayerGiftCardProductCategory,
  DataLayerGiftCardProductV2,
  DataLayerGiftCardProductV3,
} from '@customTypes/DataLayer';
import { ApiGiftCardOrderResponse } from '@customTypes/apiCompound';
import { GiftCardType } from '@customTypes/apiEnums';
import {
  ExtraProducts,
  GiftCardOrderItem,
  GiftCardProductVariation,
  GiftPackingOrderItem,
  GiftPackingProductVariation,
  Package,
  PersonalMessageOrderItem,
  PersonalMessageProductVariation,
  WineOrderItem,
  WrapperOrderItem,
  WrapperProduct,
} from '@customTypes/apiTypes';

export const dataMappingHooks = {
  useAddToCartTrackMapping(
    quantity: number,
    variation: GiftCardProductVariation | null,
    wrapper: WrapperProduct | null,
    packing: GiftPackingProductVariation | null,
    personalNote: PersonalMessageProductVariation | null,
    extras: ExtraProducts[],
  ) {
    return React.useMemo(() => {
      if (!variation) {
        return { products: null, currencyCode: null };
      }

      const products = [{
        id: variation.product.id,
        name: variation.product.title,
        price: variation.price.value.toString(),
        quantity,
        category: variation.giftCardType === GiftCardType.DIGITAL
          ? DataLayerGiftCardProductCategory.DIGITAL
          : DataLayerGiftCardProductCategory.PHYSICAL,
      }];

      if (variation.giftCardType === GiftCardType.PHYSICAL && wrapper) {
        products.push({
          id: wrapper.variations[0].product.id,
          name: wrapper.variations[0].product.title,
          price: wrapper.variations[0].price.value.toString(),
          quantity,
          category: DataLayerGiftCardProductCategory.ADDITIONAL,
        });
      }
      if (variation.giftCardType === GiftCardType.PHYSICAL && packing) {
        products.push({
          id: packing.product.id,
          name: packing.product.title,
          price: packing.price.value.toString(),
          quantity,
          category: DataLayerGiftCardProductCategory.ADDITIONAL,
        });
      }
      if (variation.giftCardType === GiftCardType.PHYSICAL && personalNote) {
        products.push({
          id: personalNote.product.id,
          name: personalNote.product.title,
          price: personalNote.price.value.toString(),
          quantity,
          category: DataLayerGiftCardProductCategory.ADDITIONAL,
        });
      }
      if (variation.giftCardType === GiftCardType.PHYSICAL && extras.length) {
        const extrasObject = extras.reduce((acc, extra) => {
          return acc[extra.id] ? {
            ...acc,
            [extra.id]: {
              ...acc[extra.id],
              quantity: acc[extra.id].quantity + 1,
            },
          } : { ...acc, [extra.id]: { ...extra, quantity: 1 } };
        }, {} as {[key: string]: ExtraProducts & { quantity: number } });
        Object.keys(extrasObject).forEach((key) => products.push({
          id: extrasObject[key].id,
          name: extrasObject[key].title,
          price: extrasObject[key].variations[0].price.value.toString(),
          quantity: extrasObject[key].quantity,
          category: DataLayerGiftCardProductCategory.ADDITIONAL,
        }));
      }

      return {
        products,
        currencyCode: variation.price.currency,
      };
    }, [quantity, variation, wrapper, packing, personalNote, extras]);
  },
  useMapDataLayerItems(
    quantity: number,
    variation: GiftCardProductVariation | null,
    wrapper: WrapperProduct | null,
  ) {
    return React.useMemo(() => {
      if (!variation) {
        return { items: null };
      }

      const items = [{
        item_id: variation?.product?.id,
        item_name: variation?.product?.title,
        price: variation.price.value,
        currency: variation.price.currency,
        quantity,
        item_category: variation.giftCardType === GiftCardType.DIGITAL
          ? DataLayerGiftCardProductCategory.DIGITAL
          : DataLayerGiftCardProductCategory.PHYSICAL,
        item_category2: 'giftcard',
      }];

      if (variation.giftCardType === GiftCardType.PHYSICAL && wrapper) {
        items.push({
          quantity,
          item_id: wrapper.variations[0].product.id,
          item_name: wrapper.variations[0].product.title,
          price: wrapper.variations[0].price.value,
          currency: wrapper.variations[0].price.currency,
          item_category: DataLayerGiftCardProductCategory.ADDITIONAL,
          item_category2: 'giftcard',
        });
      }

      return { items };
    }, [quantity, variation, wrapper]);
  },
  useMapAddToCartItems(
    quantity: number,
    variation: GiftCardProductVariation | null,
    wrapper: WrapperProduct | null,
    packing: GiftPackingProductVariation | null,
    personalNote: PersonalMessageProductVariation | null,
    extras: ExtraProducts[],
  ): DataLayerEcommerceGiftCardProductV3 | null {
    return React.useMemo(() => {
      if (!variation) {
        return null;
      }

      let index = 0;
      let value = 0;
      const items = [{
        item_id: variation.product.id,
        item_name: variation.product.title,
        price: variation.price.value,
        index,
        quantity,
        item_category: variation.giftCardType === GiftCardType.DIGITAL
          ? DataLayerGiftCardProductCategory.DIGITAL
          : DataLayerGiftCardProductCategory.PHYSICAL,
        item_category2: 'giftcard',
      }];
      index++;
      value = value + variation.price.value;

      if (variation.giftCardType === GiftCardType.PHYSICAL && wrapper) {
        items.push({
          item_id: wrapper.variations[0].product.id,
          item_name: wrapper.variations[0].product.title,
          price: wrapper.variations[0].price.value,
          index,
          quantity,
          item_category: DataLayerGiftCardProductCategory.ADDITIONAL,
          item_category2: 'giftcard',
        });
        index++;
        value = value + wrapper.variations[0].price.value;
      }
      if (variation.giftCardType === GiftCardType.PHYSICAL && packing) {
        items.push({
          item_id: packing.product.id,
          item_name: packing.product.title,
          price: packing.price.value,
          index,
          quantity,
          item_category: DataLayerGiftCardProductCategory.ADDITIONAL,
          item_category2: 'giftcard',
        });
        index++;
        value = value + packing.price.value;
      }
      if (variation.giftCardType === GiftCardType.PHYSICAL && personalNote) {
        items.push({
          item_id: personalNote.product.id,
          item_name: personalNote.product.title,
          price: personalNote.price.value,
          index,
          quantity,
          item_category: DataLayerGiftCardProductCategory.ADDITIONAL,
          item_category2: 'giftcard',
        });
        index++;
        value = value + personalNote.price.value;
      }
      if (variation.giftCardType === GiftCardType.PHYSICAL && extras.length) {
        const extrasObject = extras.reduce((acc, extra) => {
          value = value + extra.variations[0].price.value;
          return acc[extra.id] ? {
            ...acc,
            [extra.id]: {
              ...acc[extra.id],
              quantity: acc[extra.id].quantity + 1,
            },
          } : { ...acc, [extra.id]: { ...extra, quantity: 1 } };
        }, {} as {[key: string]: ExtraProducts & { quantity: number } });
        Object.keys(extrasObject).forEach((key, idx) => items.push({
          item_id: extrasObject[key].variations[0].product.id,
          item_name: extrasObject[key].variations[0].product.title,
          price: extrasObject[key].variations[0].price.value,
          quantity: extrasObject[key].quantity,
          item_category: DataLayerGiftCardProductCategory.ADDITIONAL,
          item_category2: 'giftcard',
          index: index + idx,
        }));
      }

      return {
        currency: variation.price.currency,
        value: value * quantity,
        items,
      };
    }, [quantity, variation, wrapper, packing, personalNote, extras]);
  },
  useMapAllShipments(
    allPackages: Package[],
  ) {
    return React.useMemo(() => {
      if (!allPackages.length) {
        return { mappedItems: null };
      }

      const mappedItems: DataLayerGiftCardProductV2[] = allPackages.map(
        (packageItem) => packageItem.items?.filter(
          item => item.__typename === 'GiftCardOrderItem',
        ),
      )
        .reduce((acc, el) => acc?.concat(el || []), [])
        ?.map((giftcard: GiftCardOrderItem) => {
          return {
            item_id: giftcard?.productVariation?.product?.id,
            item_name: giftcard?.productVariation?.product?.title,
            price: giftcard?.totalPrice?.value.toString(),
            currency: giftcard?.totalPrice?.currency,
            quantity: giftcard?.qty,
            item_category: giftcard?.productVariation.giftCardType === GiftCardType.DIGITAL
              ? DataLayerGiftCardProductCategory.DIGITAL
              : DataLayerGiftCardProductCategory.PHYSICAL,
            item_category2: 'giftcard' };
        }) || [];

      const filteredGiftcardsAdditions = allPackages.map(
        (packageItem) => packageItem.items?.filter(item => item.__typename !== 'GiftCardOrderItem'),
      )
        .reduce((acc, el) => acc?.concat(el || []), []);

      const filteredExtras = allPackages.map((packageItem) => packageItem.extraItems)
        .reduce((acc, el) => acc?.concat(el || []), []) || [];

      const mergedAdditions = filteredGiftcardsAdditions?.concat(filteredExtras);

      if (mergedAdditions?.length) {
        mergedAdditions.forEach((addition: PersonalMessageOrderItem |
                WineOrderItem |
                WrapperOrderItem |
                GiftCardOrderItem |
                GiftPackingOrderItem) => {
          mappedItems.push({
            item_id: addition.productVariation?.product?.id,
            item_name: addition.productVariation?.product?.title,
            price: addition.productVariation.price?.value.toString(),
            currency: addition.productVariation.price?.currency,
            quantity: addition.qty,
            item_category: DataLayerGiftCardProductCategory.ADDITIONAL,
            item_category2: 'giftcard',
          });
        });
      }

      return { mappedItems };
    }, [allPackages]);
  },
  useFlatPackagesFromOrder(basket: ApiGiftCardOrderResponse | null) {
    return basket?.data.order?.shipments?.map(shipment =>
      shipment.packages?.map(packageItem => packageItem))
      .reduce((acc, el) => acc?.concat(el || []), []) || [];
  },
  useFindGiftCardOrderItemById(itemId: string, packages: Package[]) {
    const packageItem = packages.find(packageItem => itemId === packageItem.id);
    const giftCardOrderItem =
      packageItem?.items?.find(item => item.__typename === 'GiftCardOrderItem');
    return { packageItem, giftCardOrderItem };
  },
  useMapAllShipmentsV2(
    allPackages: Package[],
    isQuantityChange = false,
  ) {
    if (!allPackages.length) {
      return { mappedItemsV2: null };
    }

    const mappedItems: DataLayerGiftCardProductV3[] = allPackages.map(
      (packageItem) => packageItem.items?.filter(
        item => item.__typename === 'GiftCardOrderItem',
      ),
    )
      .reduce((acc, el) => acc?.concat(el || []), [])
      ?.map((giftcard: GiftCardOrderItem, i) => {
        return {
          item_id: giftcard?.productVariation?.product?.id,
          item_name: giftcard?.productVariation?.product?.title,
          price: giftcard?.unitPrice?.value,
          quantity: isQuantityChange ? 1 : giftcard?.qty,
          index: i,
          item_category: giftcard?.productVariation.giftCardType === GiftCardType.DIGITAL
            ? DataLayerGiftCardProductCategory.DIGITAL
            : DataLayerGiftCardProductCategory.PHYSICAL,
          item_category2: 'giftcard',
        };
      }) || [];

    const filteredGiftcardsAdditions = allPackages.map(
      (packageItem) => packageItem.items?.filter(item => item.__typename !== 'GiftCardOrderItem'),
    )
      .reduce((acc, el) => acc?.concat(el || []), []);

    const filteredExtras = allPackages.map((packageItem) => packageItem.extraItems)
      .reduce((acc, el) => acc?.concat(el || []), []) || [];

    const mergedAdditions = filteredGiftcardsAdditions?.concat(filteredExtras);

    if (mergedAdditions?.length) {
      mergedAdditions.forEach((addition: PersonalMessageOrderItem |
              WineOrderItem |
              WrapperOrderItem |
              GiftCardOrderItem |
              GiftPackingOrderItem) => {
        mappedItems.push({
          item_id: addition.productVariation?.product?.id,
          item_name: addition.productVariation?.product?.title,
          price: addition.productVariation.price?.value,
          quantity: isQuantityChange ? 1 : addition.qty,
          item_category: DataLayerGiftCardProductCategory.ADDITIONAL,
          item_category2: 'giftcard',
          index: mappedItems.length,
        });
      });
    }

    return { mappedItemsV2: mappedItems };
  },
  useMapCartPackageById(
    itemId: string,
    basket: ApiGiftCardOrderResponse | null,
  ) {
    const allPackages: Package[] = React.useMemo(() => (
      basket?.data.order?.shipments?.map(shipment => (
        shipment.packages?.map(packageItem => packageItem)
      )).reduce((acc, el) => acc?.concat(el || []), [])) || [],
    [basket?.data.order?.shipments]);

    const filteredPackageArr = allPackages.filter(packageItem => itemId === packageItem.id);

    const { mappedItems } = dataMappingHooks.useMapAllShipments(filteredPackageArr);

    return {
      allPackages,
      filteredPackageArr,
      mappedItems,
    };
  },
  useMapAccountOrderModalItems(
    allPackages: Package[],
  ) {
    if (!allPackages.length) {
      return null;
    }

    const orderItems: OrderModalItem[] = allPackages.map(
      (packageItem) => packageItem.items?.filter(
        item => item.__typename === 'GiftCardOrderItem',
      ),
    )
      .reduce((acc, el) => acc?.concat(el || []), [])
      ?.map((giftcard: GiftCardOrderItem) => {
        return {
          id: giftcard?.id,
          name: giftcard?.productVariation?.title,
          price: giftcard?.totalPrice?.value,
          quantity: giftcard?.qty,
          currency: giftcard?.totalPrice?.currency,
          unitPrice: giftcard?.unitPrice,
        };
      }) || [];

    const filteredGiftcardsAdditions = allPackages.map(
      (packageItem) => packageItem.items?.filter(item => item.__typename !== 'GiftCardOrderItem'),
    )
      .reduce((acc, el) => acc?.concat(el || []), []);

    const filteredExtras = allPackages.map((packageItem) => packageItem.extraItems)
      .reduce((acc, el) => acc?.concat(el || []), []) || [];

    const mergedAdditions = filteredGiftcardsAdditions?.concat(filteredExtras);

    if (mergedAdditions?.length) {
      mergedAdditions.forEach((addition: PersonalMessageOrderItem |
              WineOrderItem |
              WrapperOrderItem |
              GiftCardOrderItem |
              GiftPackingOrderItem) => {
        orderItems.push({
          id: addition.id,
          name: addition.productVariation?.title,
          price: addition.productVariation.price?.value * addition.qty,
          quantity: addition.qty,
          currency: addition.productVariation.price?.currency,
          unitPrice: addition.unitPrice,
        });
      });
    }

    return orderItems;
  },
};
