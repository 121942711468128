import React from 'react';

import { ApolloProvider } from '@apollo/client';

import { ErrorBoundary } from '@nxte-nl/error-boundary';

import { GlobalMessages } from '@customTypes/Messages';
import { Modal } from '@customTypes/Modal';
import { LanguageCodeMapped } from '@customTypes/common';
import { apolloMiddlewareHooks } from '@hooks/apolloMiddlewareHooks/apolloMiddlewareHooks';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import { serverUtils } from '@utils/serverUtils/serverUtils';

import { clientFactory } from '../../apolloClient/apolloClient';

export type Props = {
  children: JSX.Element;
  language: LanguageCodeMapped;
};

export const ApolloServerContainer = (props: Props) => {
  const { children, language } = props;
  const openModal = messageHooks.useNextWithValue(GlobalMessages.ModalOpen, Modal.ApiError);
  const defaultErrorMiddleware = apolloMiddlewareHooks.useDefaultErrorMiddleware();

  const path = serverUtils.isServerSide() ? null : window?.location.pathname;
  const client = React.useMemo(() => (clientFactory.createClient(
    language, path || '', defaultErrorMiddleware, openModal,
  )), [language, path, defaultErrorMiddleware, openModal]);
  return (
    <ApolloProvider client={client}>
      <ErrorBoundary>
        {children}
      </ErrorBoundary>
    </ApolloProvider>
  );
};

ApolloServerContainer.displayName = 'ApolloServerContainer';
