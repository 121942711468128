import React from 'react';

import classNames from 'classnames';

import { domHooks } from '@hooks/domHooks/domHooks';

import { BasicSelectItem } from '../BasicSelectItem/BasicSelectItem';
import { ChevronDown } from '../icons/ChevronDown/ChevronDown';
import styles from './BasicSelect.module.css';

const VERTICAL_PADDING_VALUE = 8;

export interface IKeydownEvent {
  key: string;
  preventDefault: () => void;
}

export const hooks = {
  useClickOutsideHandler(
    setOpenState: (value: React.SetStateAction<boolean>) => void,
    setSearchValue: (value: React.SetStateAction<string>) => void,
  ) {
    return () => {
      setOpenState(false);
      setSearchValue('');
    };
  },
  useToogleDropdown(
    openState: boolean,
    setOpenState: (value: React.SetStateAction<boolean>) => void,
    setHighlightedItem: React.Dispatch<React.SetStateAction<SelectItem | null>>,
    currentSelectedItem: SelectItem | null,
  ) {
    return () => {
      setOpenState(!openState);

      if (openState === false) {
        setHighlightedItem(currentSelectedItem);
      }
    };
  },
  useSearchInputChange(setSearchValue: (value: React.SetStateAction<string>) => void) {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.currentTarget.value);
    };
  },
  useSelectValue(
    setOpenState: (value: React.SetStateAction<boolean>) => void,
    setCurrentSelectedId: React.Dispatch<React.SetStateAction<string | null>>,
    setSearchValue: (value: React.SetStateAction<string>) => void,
    onChange: (id: string) => void,
  ) {
    return React.useCallback(
      (item: SelectItem) => {
        setOpenState(false);
        setCurrentSelectedId(item.id);
        setSearchValue('');
        onChange(item.id);
      },
      [onChange, setCurrentSelectedId, setOpenState, setSearchValue],
    );
  },
  useKeyboardNavigation(
    items: SelectItem[],
    setOpenState: (value: React.SetStateAction<boolean>) => void,
    setCurrentSelectedId: React.Dispatch<React.SetStateAction<string | null>>,
    setSearchValue: (value: React.SetStateAction<string>) => void,
    onChange: (id: string) => void,
    highlightedItem: SelectItem | null,
    setHighlightedItem: React.Dispatch<React.SetStateAction<SelectItem | null>>,
    refHighlightedItem: React.RefObject<HTMLDivElement>,
    refSelectList: React.RefObject<HTMLDivElement>,
  ) {
    const onKeyDown = React.useCallback(
      (e: IKeydownEvent) => {
        const isDownKey = e.key === 'ArrowDown';
        const isUpKey = e.key === 'ArrowUp';
        const isEnter = e.key === 'Enter';

        if (!items.length || !highlightedItem) {
          return;
        }

        if (isEnter) {
          setOpenState(false);
          setCurrentSelectedId(highlightedItem.id);
          setSearchValue('');
          onChange(highlightedItem.id);
          return;
        }

        let currentIndex = items.indexOf(highlightedItem);

        if (
          refSelectList
          && refHighlightedItem
          && refSelectList.current
          && refHighlightedItem.current
        ) {
          const highlightedItemOffsetTop = refHighlightedItem.current.offsetTop;
          const highlightedItemHeight = refHighlightedItem.current.clientHeight;
          const highlightedItemOffsetHeight = refHighlightedItem.current.offsetHeight;
          const selectListHeight = refSelectList.current.clientHeight;
          const selectListScrollHeight = refSelectList.current.scrollHeight;

          if (isDownKey && currentIndex === items.length - 1) {
            currentIndex = 0;

            refSelectList.current.scrollTop = 0;
          } else if (isDownKey) {
            currentIndex++;

            if (highlightedItemOffsetTop + highlightedItemHeight * 2 > selectListHeight) {
              refSelectList.current.scrollTop =
                highlightedItemOffsetTop + highlightedItemHeight - VERTICAL_PADDING_VALUE;
            }
          } else if (isUpKey && currentIndex <= 0) {
            currentIndex = items.length - 1;

            refSelectList.current.scrollTop = selectListScrollHeight;
          } else if (isUpKey) {
            currentIndex--;

            if (highlightedItemOffsetHeight <= highlightedItemHeight) {
              refSelectList.current.scrollTop =
                highlightedItemOffsetTop - highlightedItemHeight - VERTICAL_PADDING_VALUE;
            }
          } else {
            return;
          }
        }

        setHighlightedItem(items[currentIndex]);
        e.preventDefault();
      },
      [
        items,
        highlightedItem,
        setHighlightedItem,
        setOpenState,
        setCurrentSelectedId,
        setSearchValue,
        onChange,
        refSelectList,
        refHighlightedItem,
      ],
    );

    return { onKeyDown, refSelectList };
  },
  useFilteredItems(items: SelectItem[], searchValue: string) {
    const filteredItems = items.filter((item: SelectItem) => {
      if (!searchValue || item.label.toLowerCase().includes(searchValue.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    }, []);

    return { filteredItems };
  },
  useCurrentSelectEffect(
    defaultSelectedItemId: string,
    setCurrentSelectedId: (val: string) => void,
  ) {
    React.useEffect(() => {
      setCurrentSelectedId(defaultSelectedItemId);
    }, [defaultSelectedItemId, setCurrentSelectedId]);

    return { defaultSelectedItemId, setCurrentSelectedId };
  },

  useSelectLayoutEffect(
    search: boolean,
    openState: boolean,
    refSearchInput: React.RefObject<HTMLInputElement>,
    highlightedItem: SelectItem | null,
    filteredItems: SelectItem[],
    refSelectList: React.RefObject<HTMLDivElement>,
    refHighlightedItem: React.RefObject<HTMLDivElement>,
    setHighlightedItem: (item: SelectItem | null) => void,
  ) {
    React.useLayoutEffect(() => {
      if (!search && openState) {
        refSearchInput.current?.focus();
      }

      if (openState) {
        if (highlightedItem && filteredItems.length) {
          const existHighlatedItemInList = filteredItems.some(
            (item) => item.id === highlightedItem.id,
          );

          if (!existHighlatedItemInList) {
            setHighlightedItem(filteredItems[0]);
          }
        }

        if (refSelectList.current && refHighlightedItem.current) {
          refHighlightedItem.current.scrollIntoView({ block: 'nearest' });
        }
      }
    }, [
      filteredItems,
      highlightedItem,
      openState,
      refHighlightedItem,
      refSearchInput,
      refSelectList,
      search,
      setHighlightedItem,
    ]);

    return {
      selectListScrollTop: refSelectList?.current?.scrollTop,
    };
  },
  useStates(
    defaultSelectedItemId: string,
  ) {
    const [searchValue, setSearchValue] = React.useState<string>('');
    const [openState, setOpenState] = React.useState<boolean>(false);
    const [currentSelectedId, setCurrentSelectedId] = React.useState<string>(defaultSelectedItemId);
    const [highlightedItem, setHighlightedItem] = React.useState<SelectItem | null>(null);

    return {
      searchValue,
      setSearchValue,
      openState,
      setOpenState,
      currentSelectedId,
      setCurrentSelectedId,
      highlightedItem,
      setHighlightedItem,
    };
  },
  useRefs() {
    const refSearchInput = React.useRef<HTMLInputElement>(null);
    const refHighlightedItem = React.useRef<HTMLDivElement>(null);
    const refSelectList = React.useRef<HTMLDivElement>(null);

    return {
      refSearchInput,
      refHighlightedItem,
      refSelectList,
    };
  },
};

export type SelectItem = {
  icon?: JSX.Element;
  id: string;
  label: string;
  radioButton?: JSX.Element;
};

type ExtendedProps = {
  defaultSelectedItemId?: string;
  disabled?: boolean;
  itemClasses?: {
    basicSelectItem?: string;
  };
  prefix?: React.ReactNode;
  search?: boolean;
  selectClasses?: {
    basicSelect?: string;
    basicSelectHeader?: string;
    basicSelectHeaderValue?: string;
    basicSelectHeaderValueWithIcon?: string;
    basicSelectInput?: string;
    basicSelectList?: string;
    chevron?: string;
  };
};

type BaseProps = {
  items: SelectItem[];
  onChange: (id: string) => void;
  placeholder?: string;
};

export type Props = BaseProps & ExtendedProps;

export const BasicSelect = React.memo((props: Props) => {
  const {
    items,
    defaultSelectedItemId = items[0].id,
    disabled = false,
    search = false,
    onChange,
    selectClasses,
    itemClasses,
    placeholder = '',
    prefix = null,
  } = props;

  const {
    searchValue,
    setSearchValue,
    openState,
    setOpenState,
    currentSelectedId,
    setCurrentSelectedId,
    highlightedItem,
    setHighlightedItem,
  } = hooks.useStates(defaultSelectedItemId);

  const {
    refSearchInput,
    refHighlightedItem,
    refSelectList,
  } = hooks.useRefs();

  hooks.useCurrentSelectEffect(defaultSelectedItemId, setCurrentSelectedId);

  const selectedItem = items.find((item: SelectItem) => {
    return item.id === currentSelectedId;
  });

  let headerValue = null;
  if (selectedItem) {
    if (selectedItem.icon) {
      headerValue = (
        <div className={classNames(
          styles.basicSelectHeaderValueWithIcon, selectClasses?.basicSelectHeaderValueWithIcon,
        )}>
          {selectedItem.icon}
          {selectedItem.label}
        </div>
      );
    } else if (prefix) {
      headerValue = null;
    } else {
      headerValue = selectedItem.label;
    }
  } else {
    headerValue = placeholder;
  }

  const searchInputValue = selectedItem ? selectedItem.label : placeholder;
  const { filteredItems } = hooks.useFilteredItems(items, searchValue);
  const refSelect = React.useRef<HTMLDivElement>(null);
  domHooks.useClickOutside(refSelect, hooks.useClickOutsideHandler(setOpenState, setSearchValue));
  const currentSelectedItem =
    items.find((item: SelectItem) => item.id === currentSelectedId) ?? null;

  const handleToogleDropdown = hooks.useToogleDropdown(
    openState,
    setOpenState,
    setHighlightedItem,
    currentSelectedItem,
  );
  const handleSearchInputChange = hooks.useSearchInputChange(setSearchValue);
  const handleSelectValue = hooks.useSelectValue(
    setOpenState,
    setCurrentSelectedId,
    setSearchValue,
    onChange,
  );

  const { onKeyDown } = hooks.useKeyboardNavigation(
    filteredItems,
    setOpenState,
    setCurrentSelectedId,
    setSearchValue,
    onChange,
    highlightedItem,
    setHighlightedItem,
    refHighlightedItem,
    refSelectList,
  );

  hooks.useSelectLayoutEffect(
    search,
    openState,
    refSearchInput,
    highlightedItem,
    filteredItems,
    refSelectList,
    refHighlightedItem,
    setHighlightedItem,
  );

  return (
    <div
      className={classNames(styles.basicSelect, selectClasses?.basicSelect, {
        [styles.disabled]: disabled,
      })}
      ref={refSelect}
    >
      <div className={classNames(styles.basicSelectHeader, selectClasses?.basicSelectHeader)}>
        {search ? (
          !openState ? (
            <div
              className={classNames(
                styles.basicSelectHeaderValue,
                selectClasses?.basicSelectHeaderValue,
              )}
              onClick={handleToogleDropdown}
            >
              {headerValue}
            </div>
          ) : (
            <input
              type='text'
              className={classNames(styles.basicSelectInput, selectClasses?.basicSelectInput)}
              defaultValue={searchValue}
              placeholder={searchInputValue}
              autoFocus={true}
              onChange={handleSearchInputChange}
              onKeyDown={onKeyDown}
            />
          )
        ) : (
          <>
            <div
              className={classNames(
                styles.basicSelectHeaderValue,
                selectClasses?.basicSelectHeaderValue,
              )}
              onClick={handleToogleDropdown}
            >
              {prefix}
              {headerValue}
            </div>
            <input
              type='text'
              className={classNames(
                styles.basicSelectInput,
                styles.noSearchableInputDefaultState,
                selectClasses?.basicSelectInput,
                {
                  [styles.noSearchableInputOpenedListState]: openState,
                },
              )}
              defaultValue={searchValue}
              placeholder={searchInputValue}
              onChange={handleSearchInputChange}
              onKeyDown={onKeyDown}
              readOnly={!search}
              ref={refSearchInput}
            />
          </>
        )}
        <div className={styles.toggleButton} onClick={handleToogleDropdown}>
          <ChevronDown
            className={classNames(styles.chevron, selectClasses?.chevron, {
              [styles.chevronUp]: openState,
            })}
            width={10}
            height={6}
          />
        </div>
      </div>

      {filteredItems.length ? (
        <div
          className={classNames(styles.basicSelectList, selectClasses?.basicSelectList, {
            [styles.basicSelectListOpened]: openState,
          })}
          ref={refSelectList}
        >
          {filteredItems.map((item: SelectItem) => (
            <BasicSelectItem
              key={item.id}
              item={item}
              currentSelectedId={currentSelectedId}
              highlighted={item === highlightedItem}
              handleSelectValue={handleSelectValue}
              ref={item === highlightedItem ? refHighlightedItem : null}
              classes={itemClasses}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
});

BasicSelect.displayName = 'BasicSelect';
