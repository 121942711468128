import React from 'react';

import classNames from 'classnames';

import { Loader } from '../Loader/Loader';
import styles from './Button.module.css';

export enum ButtonFit {
  Default = 'default',
  Fill = 'fill',
  Inline = 'inline',
}

export type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  color?: 'white' | 'blue' | 'black' | 'red';
  disabled?: boolean;
  fit?: ButtonFit;
  intent: 'primary' | 'secondary' | 'tertiary' | 'primaryV2' | 'secondaryV2' | 'tertiaryV2' ;
  loaderColor?: string;
  loading?: boolean;
  onClick?: React.MouseEventHandler;
  size: 'large' | 'medium' | 'small';
  type?: 'submit' | 'reset' | 'button';
};

export const Button: React.FC<Props> = React.memo((props) => {
  const {
    loading = false,
    intent,
    size,
    color,
    fit,
    children,
    disabled,
    className,
    loaderColor,
    ...buttonProps
  } = props;

  return (
    <button
      className={classNames(
        styles.button,
        styles[intent],
        styles[size],
        color ? styles[color] : null,
        {
          [styles.inline]: fit === ButtonFit.Inline,
          [styles.fill]: fit === ButtonFit.Fill,
          [styles.disabled]: disabled,
          [styles.loading]: loading,
        },
        className,
      )}
      disabled={!!disabled || loading}
      {...buttonProps}
    >
      <span>
        {loading
          && <Loader
            loaderColor={loaderColor || 'rgb(195, 200, 233)'}
            smallSize={true}
          />
        }
      </span>
      {children}
    </button>
  );
});
