import React from 'react';

import { apolloHooks } from '@nxte-nl/apollo-hooks';

import { RequestPasswordForm } from '@components/RequestPasswordForm/RequestPasswordForm';
import { GlobalMessages } from '@customTypes/Messages';
import { Modal } from '@customTypes/Modal';
import { BaseVariables } from '@customTypes/apiCompound';
import { MutationuserForgotPasswordArgs, StatusResponse } from '@customTypes/apiTypes';
import { BaseResponse } from '@customTypes/common';
import { googleRecaptchaHooks } from '@hooks/googleRecaptchaHooks/googleRecaptchaHooks';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import { userForgotPassword } from '@mutations/userForgotPassword.gql';

export type RequestPasswordVariables = BaseVariables<MutationuserForgotPasswordArgs>;
export type RequestPasswordArgs = Omit<RequestPasswordVariables, 'captchaToken'>;

export const hooks = {
  useRequestPassword(
    openConfirmationModal: () => void,
    setDefaultStep?: () => void,
  ) {
    const {
      executeRecaptcha,
      isRecaptchaLoading,
    } = googleRecaptchaHooks.useExecuteRecaptcha();

    const [mutate, { data, loading: requestPasswordLoading }] = apolloHooks
      .useMutation<BaseResponse<StatusResponse>, RequestPasswordVariables>(
        userForgotPassword, {
          onCompleted: (data) => {
            data.data.success && openConfirmationModal();
            setDefaultStep && setDefaultStep();
          },
        },
      );

    const handleRequestPassword = React.useCallback(async (
      variables: RequestPasswordArgs,
    ) => {
      const captchaToken = await executeRecaptcha();
      if (!captchaToken) return;

      return mutate({
        variables: { ...variables,
          captchaToken,
        },
      });
    }, [executeRecaptcha, mutate]);

    return {
      requestPasswordSuccess: !!data?.data.success,
      requestPasswordLoading: requestPasswordLoading || isRecaptchaLoading,
      onRequestPassword: handleRequestPassword,
    };
  },

};

export type Props = {
  backLinkTitle?: React.ReactNode;
  defaultEmailValue: string;
  onBackButton: () => void;
  setDefaultStep?: () => void;
};

export const RequestPasswordFormContainer = (props: Props) => {
  const {
    setDefaultStep,
    ...rest
  } = props;

  const openConfirmationModal = messageHooks.useNextWithValue(
    GlobalMessages.ModalOpen,
    Modal.AccountRequestPasswordConfirmModal,
  );

  const {
    requestPasswordLoading,
    onRequestPassword,
  } = hooks.useRequestPassword(openConfirmationModal, setDefaultStep);

  return (
    <RequestPasswordForm
      loading={requestPasswordLoading}
      onSubmit={onRequestPassword}
      {...rest}
    />
  );
};

RequestPasswordFormContainer.displayName = 'RequestPasswordFormContainer';
