import { PaymentGatewayTypeId } from '@customTypes/common';

import { Currency, GiftCardType } from './apiEnums';

export enum DataLayerGiftCardProductCategory {
  ADDITIONAL = 'bijproduct',
  DIGITAL = 'digitaal',
  PHYSICAL = 'fysiek',
}

export enum DataLayerPaymentMethods {
  CARD = 'credit_card',
  INVOICE = 'invoice',
}

export type DataLayerProduct = {
  id: string;
  name: string;
};
export type DataLayerGiftCardProduct = DataLayerProduct & {
  category: DataLayerGiftCardProductCategory | null;
  price: string | null;
  quantity: number;
};

export type DataLayerEcommerceGiftCardProductV3 = {
  currency: Currency;
  items: DataLayerGiftCardProductV3[];
  value: number;
};

export type DataLayerGiftCardProductV2 = {
  currency?: Currency;
  index?: number;
  item_category: DataLayerGiftCardProductCategory;
  item_category2?: string;
  item_id: string;
  item_name: string;
  price: string | number | null;
  quantity: number;
};

export type DataLayerGiftCardProductV3 = {
  index: number;
  item_category: DataLayerGiftCardProductCategory;
  item_category2: string;
  item_id: string;
  item_name: string;
  price: number | string;
  quantity: number;
};

export type BillingfInfo = {
  email: string;
  phoneNumber: string;
};

export type PaymentInfo = {
  currency?: Currency;
  items: DataLayerGiftCardProductV2[];
  payment_type: DataLayerPaymentMethods | PaymentGatewayTypeId;
  shipping: number;
  transaction_id: string;
  value?: number;
};

export type PurchaseInfo = {
  currency?: Currency;
  items: DataLayerGiftCardProductV2[];
  payment_type: string[] | DataLayerPaymentMethods;
  shipping: number;
  tax: number | null;
  transaction_id: string;
  value: number;
};

export type QuotationUserInfo = {
  user_email: string;
  user_phone_number: string | null;
};

export type HashedUserInfo = {
  user_hashed_email: string | null;
  user_hashed_phone_number: string | null;
};

export type DataLayerProductDetailsEvent = {
  ecommerce: {
    detail: {
      products: DataLayerProduct[];
    };
  };
  event: 'productDetail';
};

export type DataLayerAddToCartEvent = {
  ecommerce: {
    add: {
      products: DataLayerGiftCardProduct[];
    };
    currencyCode: Currency;
  };
  event: 'addToCart';
};

export type DataLayerOrderEvent = {
  ecommerce: {
    purchase: {
      actionField: {
        affiliation: string;
        billingEmail?: string;
        id: string;
        revenue?: string;
        shipping?: string;
        tax?: string;
      };
      products: DataLayerGiftCardProduct[];
    };
  };
  event: 'onlineOrder';
};

export type DataLayerTrackViewItemEvent = {
  ecommerce: {
      currency: Currency;
      items: DataLayerGiftCardProductV2[];
  };
  event: 'view_item';
};

export type DataLayerTrackAddToCartEvent = {
  ecommerce: {
      items: DataLayerGiftCardProductV2[];
  };
  event: 'add_to_cart';
};

export type DataLayerTrackIncreaseQuantityEvent = {
  ecommerce: DataLayerEcommerceGiftCardProductV3 | null;
  event: 'add_to_cart';
};

export type DataLayerViewCartEvent = {
  ecommerce: {
      currency: Currency;
      items: DataLayerGiftCardProductV2[];
      value: number;
  };
  event: 'view_cart';
};

export type DataLayerTrackBeginCheckout = {
  ecommerce: {
    items: DataLayerGiftCardProductV2[];
  };
  event: 'begin_checkout';
};

export type DataLayerTrackBeginCheckoutV2 = {
  ecommerce: {
    currency: Currency;
    items: DataLayerGiftCardProductV3[];
    value: number;
  };
  event: 'begin_checkout';
};

export type DataLayerTrackSelectItemEvent = {
  cta_button_id: string;
  cta_button_text: string;
  event: 'select_item';
};

export type DataLayerTrackAddShipping = {
  ecommerce: {
    items: DataLayerGiftCardProductV2[];
  };
  event: 'add_shipping_info';
};

export type DataLayerAddPaymentInfo = {
  ecommerce: PaymentInfo;
  event: 'add_payment_info';
};

export type DataLayerTrackPurchase = {
  ecommerce: PurchaseInfo;
  event: 'purchase';
  user_data: {
    sha256_email_address: string | null;
    sha256_phone_number: string | null;
  };
};

export type DataLayerTrackRemoveFromCart = {
  ecommerce: {
    items: DataLayerGiftCardProductV2[];
  };
  event: 'remove_from_cart';
};

export type DataLayerTrackDecreaseQuantityEvent = {
  ecommerce: DataLayerEcommerceGiftCardProductV3;
  event: 'remove_from_cart';
};

export type DataLayerTrackFormSubmitQuotation = {
  event: 'form_submit_quotation';
  user: HashedUserInfo;
};

export type DataLayerTrackSelectVariantShippingEvent = {
  event: 'select_item_variant_shipping';
  selected_item_variant: GiftCardType;
};

export type DataLayerTrackSelectVariantPackageEvent = {
  event: 'select_item_variant_package';
  selected_item_variant: string;
};

export type DataLayerTrackSelectVariantAmountEvent = {
  event: 'select_item_variant_amount';
  selected_item_variant: number;
};

export type DataLayerClearEcommerce = {
  ecommerce: null;
};

export type DataLayerEvent =
  | DataLayerProductDetailsEvent
  | DataLayerAddToCartEvent
  | DataLayerOrderEvent
  | DataLayerTrackViewItemEvent
  | DataLayerTrackAddToCartEvent
  | DataLayerTrackIncreaseQuantityEvent
  | DataLayerTrackSelectItemEvent
  | DataLayerViewCartEvent
  | DataLayerTrackBeginCheckout
  | DataLayerTrackAddShipping
  | DataLayerAddPaymentInfo
  | DataLayerTrackPurchase
  | DataLayerTrackRemoveFromCart
  | DataLayerTrackDecreaseQuantityEvent
  | DataLayerTrackFormSubmitQuotation
  | DataLayerTrackBeginCheckoutV2
  | DataLayerTrackSelectVariantShippingEvent
  | DataLayerTrackSelectVariantPackageEvent
  | DataLayerTrackSelectVariantAmountEvent
  | DataLayerClearEcommerce;
