import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { AccountConfirmationModal }
  from '@components/AccountConfirmationModal/AccountConfirmationModal';
import styles from '@components/AccountConfirmationModal/AccountConfirmationModal.module.css';
import { Button, ButtonFit } from '@components/Button/Button';
import { Link, LinkType } from '@components/Link/Link';
import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const messages = defineMessages({
  title: {
    id: 'src.components.RegistrationConfirmationModal.title',
    defaultMessage: 'Registreren gelukt!',
  },
  content: {
    id: 'src.components.RegistrationConfirmationModal.content',
    defaultMessage: 'Je account is aangemaakt. Bestel vanaf nu Hotel Giftcards als cadeau, of wissel je eigen giftcard in voor een verblijf in je favoriete hotel.',
  },
  hotelsSearchButtonTitle: {
    id: 'src.components.RegistrationConfirmationModal.hotelsSearchButtonTitle',
    defaultMessage: 'Boek een hotel',
  },
  accountRedirectButtonTitle: {
    id: 'src.components.RegistrationConfirmationModal.accountRedirectButtonTitle',
    defaultMessage: 'Naar je account',
  },
});

export const RegistrationConfirmationModal = React.memo((props: Props) => {
  const {
    isOpen,
    onClose,
  } = props;

  const overviewRedirect = navigationHooks.useAccountOverviewRedirect();
  const hotelSearchRedirect = navigationHooks.useHotelSearchRedirect();

  return (
    <AccountConfirmationModal
      isOpen={isOpen}
      onClose={onClose}
      title={<FormattedMessage {...messages.title} />}
      description={<FormattedMessage {...messages.content} />}
      buttonPrimary={
        <Link
          className={styles.link}
          to={overviewRedirect}
          type={LinkType.Href}
        >
          <Button
            intent='primaryV2'
            size='medium'
            fit={ButtonFit.Fill}
            onClick={onClose}
          >
            <FormattedMessage {...messages.accountRedirectButtonTitle} />
          </Button>
        </Link>
      }
      buttonSecondary={
        <Link
          className={styles.link}
          to={hotelSearchRedirect}
          type={LinkType.Href}
        >
          <Button
            intent='secondaryV2'
            size='medium'
            fit={ButtonFit.Fill}
            onClick={onClose}
          >
            <FormattedMessage {...messages.hotelsSearchButtonTitle} />
          </Button>
        </Link>
      }
    />
  );
});

RegistrationConfirmationModal.displayName = 'RegistrationConfirmationModal';
