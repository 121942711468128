import React from 'react';

import { CountrySelectionPanel }
  from '@components/CountrySelectionPanel/CountrySelectionPanel';
import { GlobalMessages } from '@customTypes/Messages';
import { LanguageSpecificUrlType } from '@customTypes/apiCompoundLanguageSpecificUrl';
import { CountryCode, LangCode } from '@customTypes/apiEnums';
import { LanguageCodeMapped } from '@customTypes/common';
import { countrySelectPanelHooks }
  from '@hooks/countrySelectPanelHooks/countrySelectPanelHooks';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import {
  languageMappingInverse,
  navigationHooks,
} from '@hooks/navigationHooks/navigationHooks';
import { queryHooks } from '@hooks/queryHooks/queryHooks';

export const hooks = {
  useShowCountryPanel(
    isUrlCorrespondsLocation: boolean | undefined,
    countryCode: CountryCode,
    supportedCountries: CountryCode[],
  ) {
    const isCountrySupported = supportedCountries.includes(countryCode);

    const showingPanelCondition = !isUrlCorrespondsLocation && isCountrySupported;

    const showCountrySelectPanel = messageHooks.useNext(GlobalMessages.CountrySelectPanelShown);

    React.useEffect(() => {
      showCountrySelectPanel(showingPanelCondition);
    }, [showingPanelCondition, showCountrySelectPanel]);
  },

  useMappedLocationWithRegion(countryCode: CountryCode, regionCode: string) {
    const language = navigationHooks.useLanguage();

    const handleBelgiumLanguageIdentifier = () => {
      switch (regionCode) {
        case 'VLG':
          return language === LanguageCodeMapped.NlBe || language === LanguageCodeMapped.EnBe;
        case 'WAL':
          return language === LanguageCodeMapped.FrBe || language === LanguageCodeMapped.EnBe;
        case 'BRU':
          return language === LanguageCodeMapped.FrBe || language === LanguageCodeMapped.EnBe;
        default:
          return false;
      }
    };

    switch (language) {
      case LanguageCodeMapped.NlNl:
      case LanguageCodeMapped.EnNl:
        return countryCode === 'NL';
      case LanguageCodeMapped.NlBe:
      case LanguageCodeMapped.FrBe:
      case LanguageCodeMapped.EnBe:
        return handleBelgiumLanguageIdentifier();
      case LanguageCodeMapped.DeDe:
      case LanguageCodeMapped.EnDe:
        return countryCode === 'DE';
      case LanguageCodeMapped.EsEs:
      case LanguageCodeMapped.EnEs:
        return countryCode === 'ES';
      case LanguageCodeMapped.FrFr:
      case LanguageCodeMapped.EnFr:
        return countryCode === 'FR';
      case LanguageCodeMapped.ItIt:
      case LanguageCodeMapped.EnIt:
        return countryCode === 'IT';
      case LanguageCodeMapped.EnGb:
        return countryCode === 'GB';
      default:
        return true;
    }
  },

  useContinueWithDetectedLanguage(countryCode: CountryCode, regionCode: string) {
    const { url } = queryHooks.useQueryParams();
    const urls = messageHooks.useSubjectState(GlobalMessages.Urls);
    const entityUrl = navigationHooks.useEntityUrl();

    const handleBelgiumLanguage = React.useCallback(() => {
      if (regionCode === 'VLG') {
        return LangCode.nl_be;
      } else if (regionCode === 'WAL'
        || regionCode === 'BRU') {
        return LangCode.fr_be;
      } else {
        return LangCode.nl_be;
      }
    }, [regionCode]);

    const transformCountryCodeToLanguage = React.useCallback(() => {
      switch (countryCode) {
        case CountryCode.NL:
          return LangCode.nl_nl;
        case CountryCode.DE:
          return LangCode.de_de;
        case CountryCode.ES:
          return LangCode.es_es;
        case CountryCode.FR:
          return LangCode.fr_fr;
        case CountryCode.IT:
          return LangCode.it_it;
        case CountryCode.BE:
          return handleBelgiumLanguage();
        default:
          return LangCode.en_gb;
      }
    }, [handleBelgiumLanguage, countryCode]);

    const onLanguageSelected = React.useCallback(() => {
      const languageCode = transformCountryCodeToLanguage();
      const languageToUrl = languageMappingInverse[languageCode];

      const specificUrl = urls && urls[LanguageSpecificUrlType.PageEntity].find((url) =>
        url.langCode === languageCode);

      window.location.href = `/${languageToUrl}${specificUrl?.url || entityUrl}/${url.search}`;
    }, [transformCountryCodeToLanguage, urls, entityUrl, url.search]);

    return { onLanguageSelected };
  },
};

export type Props = {
  countryCode: CountryCode;
  regionCode: string;
  supportedCountries: CountryCode[];
};

export const CountrySelectionPanelContainer = React.memo(({
  countryCode,
  regionCode,
  supportedCountries,
}: Props) => {
  const isCountrySelectShown = messageHooks.useSubjectState(GlobalMessages.CountrySelectPanelShown);

  const locationPanelWasClosed = localStorage.getItem('panelWasClosedFlag');

  const isUrlCorrespondsLocation = hooks.useMappedLocationWithRegion(countryCode, regionCode);

  const { handlePanelClose } = countrySelectPanelHooks.useClosePanel();

  hooks.useShowCountryPanel(
    isUrlCorrespondsLocation,
    countryCode,
    supportedCountries,
  );

  const { onLanguageSelected } = hooks.useContinueWithDetectedLanguage(countryCode, regionCode);

  return (
    <>
      {isCountrySelectShown && !locationPanelWasClosed && (
        <CountrySelectionPanel
          countryCode={countryCode}
          regionCode={regionCode}
          onClose={handlePanelClose}
          onLanguageSelected={onLanguageSelected}
        />
      )}
    </>
  );
});

CountrySelectionPanelContainer.displayName = 'CountrySelectionPanelContainer';
