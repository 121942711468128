import React from 'react';
import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router-dom';

import { format } from 'date-fns/format';
import isNil from 'lodash.isnil';

import { Mode } from '@containers/HotelListContainer/HotelListContainer';
import { GlobalMessages, HotelCheckoutMessages } from '@customTypes/Messages';
import { LanguageSpecificUrlType } from '@customTypes/apiCompoundLanguageSpecificUrl';
import { LangCode, LangDependentUrl }
  from '@customTypes/apiTypes';
import {
  langToDateFnsLocale,
  LanguageCodeMapped,
  OccupancyPresentational,
  RangeSelectedEvent,
} from '@customTypes/common';
import { queryHooks } from '@hooks/queryHooks/queryHooks';
import { serverUtils } from '@utils/serverUtils/serverUtils';

import { redirects } from '../../routes/redirects';
import { defaultUrls, messageHooks } from '../messageHooks/messageHooks';

export type RouteParams = {
  changeId?: string;
  language?: LanguageCodeMapped;
  uuid?: string;
};

export const urlDateFormat = 'YYYY-MM-DD';
export const urlDateFormatV2 = 'yyyy-MM-dd';
export const pathWithLanguageRegex = /\/.+?(\/.*[^/])/;
export const pathWithLanguageNextRegex = /^\/[^/]+(\/.*[^/])/;
export const defaultLanguage = LanguageCodeMapped.NlNl;

export type DateRangeParams = {
  from: Date | null | undefined;
  to: Date | null | undefined;
};

export type OccupancyParams = {
  adults: number;
  children: number[];
};

export type HotelListQuery = {
  mode?: Mode;
  occupancy: OccupancyPresentational;
  range: RangeSelectedEvent | null;
};

export const languageMapping: {
  [code in LanguageCodeMapped]: LangCode;
} = {
  [LanguageCodeMapped.NlNl]: LangCode.nl_nl,
  [LanguageCodeMapped.EnGb]: LangCode.en_gb,
  [LanguageCodeMapped.DeDe]: LangCode.de_de,
  [LanguageCodeMapped.EsEs]: LangCode.es_es,
  [LanguageCodeMapped.FrFr]: LangCode.fr_fr,
  [LanguageCodeMapped.FrBe]: LangCode.fr_be,
  [LanguageCodeMapped.ItIt]: LangCode.it_it,
  [LanguageCodeMapped.NlBe]: LangCode.nl_be,
  [LanguageCodeMapped.EnNl]: LangCode.en_nl,
  [LanguageCodeMapped.EnBe]: LangCode.en_be,
  [LanguageCodeMapped.EnFr]: LangCode.en_fr,
  [LanguageCodeMapped.EnIt]: LangCode.en_it,
  [LanguageCodeMapped.EnDe]: LangCode.en_de,
  [LanguageCodeMapped.EnEs]: LangCode.en_es,
};

export const languageMappingInverse = Object.entries(languageMapping).reduce(
  (acc, [key, val]) => ({ ...acc, [val]: key }), {} as {[code in LangCode]: LanguageCodeMapped },
);

export const buildQuery = (params: Record<string, string | number[] | string[] | null>) =>
  Object.entries(params).reduce(
    (prev: string, [key, value]: [string, string]) => {
      const encodedValue = Array.isArray(value)
        ? value.map(encodeURIComponent).join(',') : encodeURIComponent(value);
      const prevPart = prev ? `${prev}&` : '?';
      return `${prevPart}${key}=${encodedValue}`;
    }, '',
  );

export const navigationHooks = {
  useUuid() {
    if (serverUtils.isNextBuild()) {
      return navigationHooks.useNextUuid();
    } else {
      return navigationHooks.useReactUuid();
    }
  },
  useReactUuid() {
    const { params: { uuid } } = useRouteMatch<RouteParams>();

    const { url } = queryHooks.useQueryParams();
    const uuidQueryParam = url.searchParams.get('uuid');

    return uuid || uuidQueryParam || '';
  },
  useNextUuid() {
    const uuid = messageHooks.useSubjectState(HotelCheckoutMessages.Uuid);
    const { url } = queryHooks.useQueryParams();
    const uuidQueryParam = url.searchParams.get('uuid');

    return uuid || uuidQueryParam || '';
  },
  useChangeId() {
    if (serverUtils.isNextBuild()) {
      return navigationHooks.useNextChangeId();
    } else {
      return navigationHooks.useReactChangeId();
    }
  },
  useReactChangeId() {
    const { params: { changeId } } = useRouteMatch<RouteParams>();
    return changeId || '';
  },
  useNextChangeId() {
    const changeId = messageHooks.useSubjectState(HotelCheckoutMessages.ChangeId);
    return changeId || '';
  },
  useLanguage() {
    if (serverUtils.isNextBuild()) {
      return navigationHooks.useNextLanguage();
    } else {
      return navigationHooks.useReactLanguage();
    }
  },
  useHomePageRedirect() {
    const language = navigationHooks.useLanguage();
    return React.useCallback(() => `/${language}/`, [language]);
  },
  useLanguageOrDefault(language: LanguageCodeMapped | undefined) {
    if (language) return language;
    return defaultLanguage;
  },
  useReactLanguage() {
    const { params: { language } } = useRouteMatch<RouteParams>();
    return navigationHooks.useLanguageOrDefault(language);
  },
  useNextLanguage() {
    const language = messageHooks.useSubjectState(GlobalMessages.Language);
    return navigationHooks.useLanguageOrDefault(language as LanguageCodeMapped);
  },
  useLangCode() {
    const language = navigationHooks.useLanguage();
    return languageMapping[language];
  },
  useRedirectWithLanguage(redirect: typeof redirects[keyof typeof redirects], withUid?: boolean) {
    const intl = useIntl();
    const language = navigationHooks.useLanguage();
    return React.useCallback((uid?: string) => {
      return intl.formatMessage(redirect, { language, ...(withUid ? { uid } : {}) });
    }, [intl, language, redirect, withUid]);
  },
  useHotelSearchRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.hotelSearchRedirect);
  },
  useHotelGoodToKnowRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.hotelGoodToKnowRedirect, true);
  },
  useHotelGoodToKnowStaticRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.hotelGoodToKnowStaticRedirect, true);
  },
  useHotelCheckoutV2Redirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.hotelCheckoutV2Route, true);
  },
  useHotelCheckoutV2StaticRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.hotelCheckoutV2StaticRedirect, true);
  },
  useGiftCardRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.giftCardRedirect);
  },
  useGiftcardCheckoutRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.giftCardCheckoutRedirect);
  },
  useCheckBalanceRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.balanceCheckerRedirect);
  },
  useContactRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.contactRedirect);
  },
  useHeaderContactRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.headerContactRedirect);
  },
  useFAQRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.FAQRedirect);
  },
  useCookiesAndPrivacyRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.cookiesAndPrivacyRedirect);
  },
  useBusinessRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.businessRedirect);
  },
  useNotFoundPageRedirect() {
    return React.useCallback(() => '/404/', []);
  },
  useTermsAndConditionsRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.termsAndConditionsRedirect);
  },
  useImpressumRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.impressumRedirect);
  },
  useImprintRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.imprintRedirect);
  },
  useNewsletterSubscriptionRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.newsletterSubscriptionRedirect);
  },
  useSupplierTermsRedirect() {
    const intl = useIntl();
    return React.useCallback(() => {
      return intl.formatMessage(redirects.supplierTermsRedirect);
    }, [intl]);
  },
  useThankYouPageRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.thankYouPageRedirect, true);
  },
  useThankYouStaticPageRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.thankYouStaticPageRedirect, true);
  },
  useBookingFailedPageRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.bookingFailedPageRedirect, true);
  },
  useBookingFailedStaticPageRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.bookingFailedStaticPageRedirect, true);
  },
  useAutocompleteRedirect(
    url: string,
    query: HotelListQuery,
  ) {
    const { range, occupancy, mode } = query;
    const params: Record<string, string | number[] | null> = {
      adults: occupancy.adults.toString(),
      children: occupancy.children,
    };
    const language = navigationHooks.useLanguage();
    const locale = langToDateFnsLocale[language];
    if (range) {
      params.from = format(range.startDate, urlDateFormatV2, { locale });
      params.to = format(range.endDate, urlDateFormatV2, { locale });
    }
    if (mode) params.mode = mode;

    const queryParams = buildQuery(params);
    return React.useCallback(() => {
      return `/${language}${url}/${queryParams}`;
    }, [language, url, queryParams]);
  },
  usePathname() {
    if (serverUtils.isServerSide()) {
      return navigationHooks.useNextPathname();
    } else {
      return window.location.pathname;
    }
  },
  useNextPathname() {
    const asPath = require('next/router').useRouter();
    const pathname = asPath.asPath;

    return pathname;
  },
  useEntityUrl() {
    const pathname = navigationHooks.usePathname();
    const pathMatch = pathname?.match(
      serverUtils.isServerSide() ? pathWithLanguageNextRegex : pathWithLanguageRegex,
    );
    const url = pathMatch ? pathMatch[1] : '';
    return url;
  },
  useHotelDetailsRedirect(pathname: string) {
    const language = navigationHooks.useLanguage();
    const url = queryHooks.useEssentialQueryParamsUrl();
    return React.useCallback(
      () => `/${language}${pathname}/${url.search}`, [language, pathname, url],
    );
  },
  useSetLanguageSpecificPageUrls(
    type: LanguageSpecificUrlType,
    urls: LangDependentUrl[] | null | undefined,
  ) {
    const existingUrls = messageHooks.useSubjectState(GlobalMessages.Urls);
    const setUrls = messageHooks.useNext(GlobalMessages.Urls);
    React.useEffect(() => {
      if (urls && existingUrls) setUrls({ ...existingUrls, [type]: urls });
      return () => { setUrls(defaultUrls); };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urls, type, setUrls]);
  },
  useParams(queryParams: URLSearchParams, keys: string[]) {
    return React.useMemo(() => keys.map(key => queryParams.get(key)), [queryParams, keys]);
  },
  useConvertedParams(params: (string | null | undefined)[]) {
    return React.useMemo(() => params.map(param => isNil(param) ? '' : param), [params]);
  },
  useEssentialQueryParamsUrl() {
    const { url } = queryHooks.useQueryParams();
    return React.useMemo(() => {
      const essentialQueryParams = ['adults', 'children', 'from', 'to'];
      Array.from(url.searchParams.keys()).forEach(key => {
        if (!essentialQueryParams.includes(key)) url.searchParams.delete(key);
      });
      return url;
    }, [url]);
  },
  useAccountOverviewRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.accountOverviewRedirect);
  },
  useAccountPersonalDetailsRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.accountPersonalDetailsRedirect);
  },
  useAccountBookingsRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.accountBookingsRedirect);
  },
  useAccountGiftcardsRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.accountGiftcardsRedirect);
  },
  useAccountOrdersRedirect() {
    return navigationHooks.useRedirectWithLanguage(redirects.accountOrdersRedirect);
  },
  useActivePage() {
    const pathname = navigationHooks.usePathname();

    return React.useCallback(
      (url: string) => {
        const pathnameWithSlash = pathname.endsWith('/') ? pathname : `${pathname}/`;
        const urlWithSlash = url.endsWith('/') ? url : `${url}/`;

        return pathnameWithSlash.endsWith(urlWithSlash);
      }, [pathname],
    );
  },
};
