import React from 'react';

export type Props = {
  className?: string;
  color?: string;
  height?: number;
  onClick?(): void;
  width?: number;
};

export const EyeOpen = React.memo((props: Props) => {
  const {
    width = 24,
    height = 24,
    className,
    onClick,
  } = props;

  const style = {
    minWidth: width,
    minHeight: height,
  };

  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      style={style}
      viewBox={`0 0 ${width} ${height}`}
    >
      <path d='m3.09,11.84c-.08.12.69.72,1.34,1.45,1.17,1.3,2.9,2.57,2.9,2.57,1.68,1.09,3.27,1.44,4.66,1.42,1.4.03,2.98-.33,4.66-1.42,0,0,1.74-1.27,2.9-2.57.66-.73,1.27-1.33,1.34-1.45-.08-.12-.69-.72-1.34-1.45-1.17-1.3-2.9-2.57-2.9-2.57-1.68-1.09-3.27-1.44-4.66-1.42-1.4-.03-2.98.33-4.66,1.42,0,0-1.74,1.27-2.9,2.57-.66.73-1.27,1.33-1.34,1.45Z' style={{ 'fill': 'none', 'stroke': '#161616', 'strokeLinecap': 'round', 'strokeLinejoin': 'round', 'strokeWidth': '1.62px' }}/>
      <circle
        cx='12' cy='11.84' r='2.59'
        style={{
          'fill': 'none',
          'stroke': '#161616',
          'strokeLinecap': 'round',
          'strokeLinejoin': 'round',
          'strokeWidth': '1.62px',
        }}
      />
    </svg>
  );
});

EyeOpen.displayName = 'EyeOpen';
