import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Modal } from '@components/Modal/Modal';
import { LayoutExtension } from '@customTypes/ResponsiveState';

import styles from './ApiErrorModal.module.css';

const messages = defineMessages({
  apiError: {
    id: 'src.components.ApiErrorModal.apiError',
    defaultMessage: 'Oh oh, something went wrong. We are already working on a fix for this issue. Please try again at a later moment. Our apologies for the inconvenience.',
  },
});

export type Props = {
  expanded: boolean;
  layoutExtension: LayoutExtension | null;
  onClose: () => void;
};

export const ApiErrorModal: React.FC<Props> = React.memo(({
  layoutExtension,
  expanded,
  onClose,
}) => {
  return (
    <Modal
      isOpen={expanded}
      onClose={onClose}
      layoutExtension={layoutExtension}
      modalClassName={styles.apiErrorModal}
      mobileV2
    >
      <div className={styles.apiErrorModalContent}>
        <FormattedMessage {...messages.apiError} />
      </div>
    </Modal>
  );
});

ApiErrorModal.displayName = 'ApiErrorModal';
