import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { apolloHooks } from '@nxte-nl/apollo-hooks';

import {
  AccountResetPasswordForm,
  ResetPasswordFormValues,
} from '@components/AccountResetPasswordForm/AccountResetPasswordForm';
import { toaster } from '@components/Toaster/Toaster';
import { GlobalMessages } from '@customTypes/Messages';
import { Modal } from '@customTypes/Modal';
import { LayoutExtension } from '@customTypes/ResponsiveState';
import { BaseVariables } from '@customTypes/apiCompound';
import { MutationuserResetPasswordArgs, StatusResponse, Error } from '@customTypes/apiTypes';
import { BaseResponse } from '@customTypes/common';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import { userResetPassword } from '@mutations/userResetPassword.gql';

const messages = defineMessages({
  somethingWentWrong: {
    id: 'src.containers.AccountResetPasswordFormContainer.somethingWentWrong',
    defaultMessage: 'Something went wrong',
  },
  inputError: {
    id: 'src.containers.AccountResetPasswordFormContainer.inputError',
    defaultMessage: 'Invalid (or empty) input',
  },
  tokenWasExpired: {
    id: 'src.containers.AccountResetPasswordFormContainer.tokenWasExpired',
    defaultMessage: 'Token was expired',
  },
});

export const hooks = {
  useErrorHandling: (
    layoutExtension: LayoutExtension | null,
    errors: Error[],
  ) => {
    const intl = useIntl();
    let text = '';

    errors.forEach(error => {
      switch (error.code) {
        case 0:
          text = intl.formatMessage(messages.somethingWentWrong);
          break;
        case 1001:
          text = intl.formatMessage(messages.inputError);
          break;
        case 1033:
          text = intl.formatMessage(messages.tokenWasExpired);
          break;
        default:
          text = error.message;
          break;
      }
    });
    toaster({
      layoutExtension,
      text: text,
    }, { type: 'error', autoClose: 4000 });
  },
  useResetPassword(
    token: string,
    layoutExtension: LayoutExtension | null,
    openConfirmtionModal: () => void,
  ) {
    const [executeRequest, { loading }] = apolloHooks.useMutation<
        BaseResponse<StatusResponse>,
        BaseVariables<MutationuserResetPasswordArgs>
    >(userResetPassword, {
      onCompleted: (data) => {
        if (data.data.success) {
          openConfirmtionModal();
        } else if (data.data.errors_v1?.length) {
          hooks.useErrorHandling(layoutExtension, data.data.errors_v1);
        }
      },
    });

    const handleSubmit = React.useCallback(async (data: ResetPasswordFormValues) => {
      return executeRequest({
        variables: {
          password: data.password,
          repeatPassword: data.repeatPassword,
          token: encodeURIComponent(token),
        },
      });
    }, [token, executeRequest]);

    return {
      isChangePasswordLoading: loading,
      onChangePasswordSubmit: handleSubmit,
    };
  },
};

export type Props = {
  layoutExtension: LayoutExtension | null;
  token?: string;
};
export const AccountResetPasswordFormContainer = (props: Props) => {
  const { layoutExtension, token = '' } = props;

  const openConfirmtionModal =
    messageHooks.useNextWithValue(GlobalMessages.ModalOpen, Modal.AccountResetPasswordConfirmModal);
  const { isChangePasswordLoading, onChangePasswordSubmit } = hooks.useResetPassword(
    token,
    layoutExtension,
    openConfirmtionModal,
  );

  return (
    <AccountResetPasswordForm
      loading={isChangePasswordLoading}
      onSubmit={onChangePasswordSubmit}
    />
  );
};

AccountResetPasswordFormContainer.displayName = 'AccountResetPasswordFormContainer';
