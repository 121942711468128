import React from 'react';

import { useRouter } from 'next/router';

import { isBefore, isValid } from 'date-fns';

import { HotelConfigurableFilterGroupInput, StarsRatingFilterInput } from '@customTypes/apiTypes';
import { DateRangeParams, OccupancyParams } from '@hooks/navigationHooks/navigationHooks';
import { serverUtils } from '@utils/serverUtils/serverUtils';

export const urlDateFormat = 'YYYY-MM-DD';
const STARS_MAPPER: {[key: string]: keyof StarsRatingFilterInput } = {
  '1': 'One', '2': 'Two', '3': 'Three', '4': 'Four', '5': 'Five',
};

type QueryParams = { params: Record<string, string>; pushParams: (url: URL) => void; url: URL };

const localHooks = {
  useServerQueryParams() : QueryParams {
    return { params: {}, pushParams: () => null, url: new URL('https://url') };
  },
  useClientQueryParams(
    url: string,
    setUrl: (url: string) => void,
    getUrl: () => string,
  ): QueryParams {
    const [_, forceUpdateIfUrlChanged] = React.useState(window.location.href);
    const popStateEvent = React.useMemo(() => new CustomEvent('popstate'), []);

    React.useEffect(() => {
      const handler = () => {
        const newUrl = getUrl();
        if (url === newUrl) return;

        setUrl(newUrl);
        forceUpdateIfUrlChanged(newUrl);
      };
      handler();

      window.addEventListener('popstate', handler);
      return () => window.removeEventListener('popstate', handler);
    }, [getUrl, setUrl, url]);

    const pushParams = React.useCallback((url: URL) => {
      window.history.pushState({}, '', String(url));
      window.dispatchEvent(popStateEvent);
    }, [popStateEvent]);

    const [_params, _setParams] = React.useState<Record<string, string>>({});
    const [_url, _setUrl] = React.useState<URL>(new URL(url));

    return {
      get params() {
        const params = Object.fromEntries(new URL(url).searchParams);
        if (JSON.stringify(params) === JSON.stringify(_params)) return _params;

        _setParams(params);
        return _params;
      },
      pushParams,
      get url() {
        const u = String(_url);
        if (u === url) return _url;

        const newUrl = new URL(url);
        _setUrl(newUrl);
        return _url;
      },
    };
  },
};

export const queryHooks = {
  useQueryParams: (function (): () => QueryParams {
    const isServerSide = serverUtils.isServerSide();
    let url = isServerSide ? '' : window.location.href;
    const setUrl = (u: string) => { url = u; };
    const getUrl = () => window.location.href;

    return () => {
      if (serverUtils.isServerSide()) return localHooks.useServerQueryParams();
      return localHooks.useClientQueryParams(url, setUrl, getUrl);
    };
  })(),
  useFromTo(): DateRangeParams {
    const { params } = queryHooks.useQueryParams();
    const fromParam = 'from' in params ? params.from : null;
    const toParam = 'to' in params ? params.to : null;

    return React.useMemo(() => {
      const hasBothFromTo = fromParam && toParam;
      return {
        from: hasBothFromTo
          ? (isValid(new Date(fromParam)) ? new Date(fromParam) : undefined)
          : null,
        to: hasBothFromTo ? (isValid(new Date(toParam)) ? new Date(toParam) : undefined) : null,
      };
    }, [fromParam, toParam]);
  },
  useOccupancy(): OccupancyParams | null {
    const { params } = queryHooks.useQueryParams();
    const adultsParam = 'adults' in params ? params.adults : null;
    const childrenParam = 'children' in params ? params.children : null;

    return React.useMemo(() => {
      return adultsParam ? {
        adults: parseInt(adultsParam),
        children: childrenParam
          ? decodeURIComponent(childrenParam).split(',').map(x => parseInt(x)) : [],
      } : null;
    }, [adultsParam, childrenParam]);
  },
  usePrice() {
    const { params } = queryHooks.useQueryParams();
    const currency = 'currency' in params ? params.currency : null;
    const min = 'min' in params ? params.min : null;
    const max = 'max' in params ? params.max : null;

    return React.useMemo(() => {
      return min && max && currency ? {
        currency,
        min_price: parseInt(min),
        max_price: parseInt(max),
      } : null;
    }, [currency, max, min]);
  },
  useDeletePrice() {
    const { url, pushParams } = queryHooks.useQueryParams();

    return React.useCallback(() => {
      url.searchParams.delete('min');
      url.searchParams.delete('max');
      url.searchParams.delete('currency');
      url.searchParams.delete('page');
      pushParams(url);
    }, [url, pushParams]);
  },
  useRating() {
    const { params } = queryHooks.useQueryParams();
    const rawStarsValue = 'stars' in params ? params.stars : null;

    return React.useMemo(() => {
      const stars = rawStarsValue ? decodeURIComponent(rawStarsValue)
        .split(',')
        .reduce<StarsRatingFilterInput>((prev, curr) => {
          return { ...prev, [STARS_MAPPER[curr]]: true };
        }, {} as StarsRatingFilterInput) : null;

      return stars ?? null;
    }, [rawStarsValue]);
  },
  useDeleteRating() {
    const { url, pushParams } = queryHooks.useQueryParams();

    return React.useCallback(() => {
      url.searchParams.delete('stars');
      url.searchParams.delete('page');
      pushParams(url);
    }, [url, pushParams]);
  },
  useConfigurableFilters(): HotelConfigurableFilterGroupInput[] | null {
    const { params, url } = queryHooks.useQueryParams();
    const serializedConfigurableFilters = 'filters' in params ? params.filters : null;

    return React.useMemo(() => {
      try {
        return serializedConfigurableFilters
          ? JSON.parse(serializedConfigurableFilters)
          : null;
      } catch (_) {
        window.location.replace(url.origin + url.pathname);
        return null;
      }
    }, [serializedConfigurableFilters, url]);
  },
  useDeleteConfigurableFilter() {
    const { url, pushParams } = queryHooks.useQueryParams();
    const appliedConfigurableFilters = queryHooks.useConfigurableFilters();

    return React.useCallback((id: string) => {
      if (!appliedConfigurableFilters) return;

      const updatedConfigurableFilters = appliedConfigurableFilters
        .reduce((acc, filterGroup) => {
          if (filterGroup.configurableFilters) {
            const filteredConfigurableFilters =
              filterGroup.configurableFilters.filter((filterId) => filterId !== id);
            return filteredConfigurableFilters.length
              ? [...acc, { ...filterGroup, configurableFilters: filteredConfigurableFilters }]
              : acc;
          }
          return acc;
        }, []);

      if (updatedConfigurableFilters.length) {
        url.searchParams.set('filters', JSON.stringify(updatedConfigurableFilters));
      } else {
        url.searchParams.delete('filters');
      }
      url.searchParams.delete('page');
      pushParams(url);
    }, [appliedConfigurableFilters, url, pushParams]);
  },
  useDeleteAllFilters() {
    const { pushParams } = queryHooks.useQueryParams();
    const url = queryHooks.useEssentialQueryParamsUrl();

    return React.useCallback(() => pushParams(url), [pushParams, url]);
  },
  usePageNumber() {
    const { params } = queryHooks.useQueryParams();
    const pageParam = 'page' in params ? params.page : null;
    return React.useMemo(() => pageParam ? parseInt(pageParam, 10) - 1 : 0, [pageParam]);
  },
  useSetPageNumber() {
    const { url, pushParams } = queryHooks.useQueryParams();
    return React.useCallback((page: number) => {
      url.searchParams.set('page', (page + 1).toString());
      pushParams(url);
    }, [url, pushParams]);
  },
  useCoordinateRectangle() {
    const { params } = queryHooks.useQueryParams();
    const latmax = 'latmax' in params ? params.latmax : null;
    const latmin = 'latmin' in params ? params.latmin : null;
    const lonmax = 'lonmax' in params ? params.lonmax : null;
    const lonmin = 'lonmin' in params ? params.lonmin : null;
    return React.useMemo(() => latmax && latmin && lonmax && lonmin ? {
      latmax: parseFloat(latmax),
      latmin: parseFloat(latmin),
      lonmax: parseFloat(lonmax),
      lonmin: parseFloat(lonmin),
    } : null, [latmax, latmin, lonmax, lonmin]);
  },
  useSetCoordinateRectangle() {
    const { url, pushParams } = queryHooks.useQueryParams();
    const setCoordinateRectangle = React.useCallback((
      latmax: number | null,
      latmin: number | null,
      lonmax: number | null,
      lonmin: number | null,
    ) => {
      if (url.searchParams.has('page')) url.searchParams.set('page', '1');
      if (latmax && latmin && lonmax && lonmin) {
        url.searchParams.set('latmax', latmax.toString());
        url.searchParams.set('latmin', latmin.toString());
        url.searchParams.set('lonmax', lonmax.toString());
        url.searchParams.set('lonmin', lonmin.toString());
      }
      pushParams(url);
    }, [url, pushParams]);
    return { setCoordinateRectangle };
  },
  useFilteredParams(keys: string[]) {
    const { url } = queryHooks.useQueryParams();
    return React.useMemo(() => {
      keys.forEach(key => url.searchParams.delete(key));
      return url;
    }, [url, keys]);
  },
  useEssentialQueryParamsUrl() {
    const { url } = queryHooks.useQueryParams();
    return React.useMemo(() => {
      const essentialQueryParams = ['adults', 'children', 'from', 'to'];
      Array.from(url.searchParams.keys()).forEach(key => {
        if (!essentialQueryParams.includes(key)) url.searchParams.delete(key);
      });
      return url;
    }, [url]);
  },
  useDateParamsValidation() {
    const { from, to } = queryHooks.useFromTo();

    return React.useMemo(() => {
      let isDateRangeValid = true,
        isNotBeforeToday = true;

      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (from === undefined || to === undefined) {
        return false;
      }

      if (from && to) {
        isDateRangeValid = isBefore(from, to) && isValid(from) && isValid(to);
        isNotBeforeToday = !(isBefore(from, today) || isBefore(to, today));
      }
      return isDateRangeValid && isNotBeforeToday;
    }, [from, to]);
  },
  useFiterParamsIfDateInvalid() {
    const { pushParams } = queryHooks.useQueryParams();
    const keys = React.useMemo(() => [
      'from', 'to', 'adults', 'children', 'max', 'min', 'currency',
    ], []);
    const urlWithFilteredParams = queryHooks.useFilteredParams(keys);
    const isDateParamsCorrect = queryHooks.useDateParamsValidation();
    const router = useRouter();

    React.useEffect(() => {
      if (!isDateParamsCorrect) {
        if (serverUtils.isNextBuild()) {
          if (router.isReady) pushParams(urlWithFilteredParams);
        } else {
          setTimeout(() => pushParams(urlWithFilteredParams), 0);
        }
      }
    }, [isDateParamsCorrect, urlWithFilteredParams, pushParams, router]);
  },
  useRemoveQueryParams(names: Array<string>) {
    const { url, pushParams } = queryHooks.useQueryParams();
    const params = url.searchParams;

    return React.useCallback(() => {
      names.forEach(name => {
        if (params.has(name)) {
          params.delete(name);
        }
      });

      pushParams(url);
    }, [names, pushParams, url, params]);
  },
};
