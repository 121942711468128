import React from 'react';

import { apolloHooks } from '@nxte-nl/apollo-hooks';

import { LoginForm } from '@components/LoginForm/LoginForm';
import { GlobalMessages } from '@customTypes/Messages';
import { BaseVariables } from '@customTypes/apiCompound';
import { MutationuserLoginArgs, StatusResponse } from '@customTypes/apiTypes';
import { BaseResponse, StatusResponsePresentational } from '@customTypes/common';
import { googleRecaptchaHooks } from '@hooks/googleRecaptchaHooks/googleRecaptchaHooks';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';
import { userLogin } from '@mutations/userLogin.gql';

export type UserLoginVariables = BaseVariables<MutationuserLoginArgs>;
export type UserLoginArgs = Omit<UserLoginVariables, 'captchaToken'>;

export const hooks = {
  useUserLogin() {
    const { executeRecaptcha, isRecaptchaLoading } = googleRecaptchaHooks.useExecuteRecaptcha();
    const loginModalClose = messageHooks.useNextWithValue(GlobalMessages.ModalOpen, null);
    const overviewRedirect = navigationHooks.useAccountOverviewRedirect();

    const [mutate, { data, loading: logInLoading }] = apolloHooks
      .useMutation<BaseResponse<StatusResponse>, UserLoginVariables>(
        userLogin, {
          refetchQueries: ['getUserInfo'],
        },
      );

    const handleLogIn = React.useCallback(async (
      variables: UserLoginArgs,
      onCompleteCb: (data: BaseResponse<StatusResponsePresentational>) => void,
    ) => {
      const captchaToken = await executeRecaptcha();
      if (!captchaToken) return;

      return mutate({
        variables: { ...variables, captchaToken },
        onCompleted: (data) => {
          if (data.data.success) {
            loginModalClose();
            window.location.href = overviewRedirect();
          }
          if (data.data.errors_v1?.length !== 0) onCompleteCb?.(data);
        },
      });
    }, [executeRecaptcha, loginModalClose, mutate, overviewRedirect]);

    return {
      logInSuccess: !!data?.data.success,
      logInLoading: logInLoading || isRecaptchaLoading,
      onLogIn: handleLogIn,
    };
  },
};

export type Props = {
  defaultEmailValue: string;
  onBackButton: () => void;
  onRequestPasswordButton: () => void;
};

export const LoginFormContainer = (props: Props) => {
  const {
    ...rest
  } = props;

  const {
    logInLoading,
    onLogIn,
  } = hooks.useUserLogin();

  return (
    <LoginForm
      buttonLoading={logInLoading}
      onFormSubmit={onLogIn}
      {...rest}
    />
  );
};

LoginFormContainer.displayName = 'LoginFormContainer';
