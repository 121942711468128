import React from 'react';

import { stateHooks } from '@nxte-nl/state-hooks';

import { GlobalMessages } from '@customTypes/Messages';
import { LayoutExtension } from '@customTypes/ResponsiveState';
import { ComponentIds, LanguageCodeMapped } from '@customTypes/common';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import { serverUtils } from '@utils/serverUtils/serverUtils';

export type Observer = {
  disconnect: () => void;
  observe: (element: HTMLElement) => void;
};

/* istanbul ignore next */
export const callbacks = {
  loadTrustBadgeScript() {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.onload = resolve;
      script.src = '//widgets.trustedshops.com/js/X0BEEF3E3F5EC540140A7CEBBDB2C3D02.js';
      script.async = true;
      script.dataset.desktopYOffset = '0';
      script.dataset.mobileYOffset = '0';
      script.dataset.desktopDisableReviews = 'false';
      script.dataset.desktopEnableCustom = 'false';
      script.dataset.desktopPosition = 'left';
      script.dataset.desktopCustomWidth = '156';
      script.dataset.desktopEnableFadeout = 'false';
      script.dataset.disableMobile = 'false';
      script.dataset.disableTrustbadge = 'false';
      script.dataset.mobileCustomWidth = '156';
      script.dataset.mobileDisableReviews = 'false';
      script.dataset.mobileEnableCustom = 'false';
      script.dataset.mobilePosition = 'left';
      script.dataset.mobileEnableTopbar = 'false';
      script.dataset.mobileEnableFadeout = 'true';
      script.dataset.colorScheme = 'light';
      document.getElementsByTagName('body')[0].appendChild(script);
    });
  },
};

export const domHooks = {
  useStickyStateDetection(
    intersectionRatioEdge: number,
    options: IntersectionObserverInit,
    setSticky: (val: boolean) => void,
  ) {
    const observer = React.useMemo(() => {
      if (serverUtils.isServerSide()) return null;

      return new IntersectionObserver(
        ([e]) => setSticky(e.intersectionRatio < intersectionRatioEdge),
        options,
      );
    }, [intersectionRatioEdge, options, setSticky]);

    const refCallback = React.useCallback((element: HTMLElement | null) =>
      element && observer?.observe(element), [observer]);

    React.useEffect(() => () => observer?.disconnect(), [observer]);

    return { refCallback };
  },
  useElementScrollHeight(callback: (value: number) => void) {
    return React.useCallback((node: HTMLDivElement) =>
      node && callback(node.scrollHeight), [callback]);
  },
  useLayout(layoutExtension: LayoutExtension | null) {
    return React.useMemo(() => {
      return {
        isMobile: layoutExtension === LayoutExtension.Mobile,
        isTablet: layoutExtension === LayoutExtension.Tablet,
        isDesktop: layoutExtension
          ? [LayoutExtension.DesktopSmall, LayoutExtension.DesktopLarge]
            .includes(layoutExtension)
          : false,
      };
    }, [layoutExtension]);
  },
  useScrollToElement(id: string) {
    return React.useCallback(() => {
      const element = document.getElementById(id);
      element?.scrollIntoView({ behavior: 'smooth' });
    }, [id]);
  },
  useStatesStickyButton() {
    const [showButton, setShowButton] = React.useState<boolean>(false);
    const [isButtonUnmounting, setButtonUnmounting] = React.useState<boolean>(false);

    return {
      showButton,
      setShowButton,
      isButtonUnmounting,
      setButtonUnmounting,
    };
  },
  useStickyButton(
    arrayOfLayoutsForAction: LayoutExtension[],
    scrollOffsetValue: number,
    defaultState = false,
  ) {
    const {
      showButton, setShowButton,
      isButtonUnmounting, setButtonUnmounting,
    } = domHooks.useStatesStickyButton();
    const layoutExtension = messageHooks.useSubjectState(GlobalMessages.LayoutExtension);
    const layoutActionCondition =
      layoutExtension ? arrayOfLayoutsForAction.includes(layoutExtension) : false;
    const {
      value: shown,
      setFalse: hide,
      setTrue: show,
    } = stateHooks.useBooleanState({ initialState: !layoutActionCondition && defaultState });

    const handleWindowScroll = React.useCallback(() => {
      const docClientHeight = document.body.clientHeight;
      const footer = document.getElementById(ComponentIds.FooterV3);
      const windowInnerHeight = window.innerHeight;
      const maxAvailableScroll = docClientHeight - windowInnerHeight - (footer?.clientHeight || 0);
      let condition = window.scrollY < maxAvailableScroll;
      if (layoutActionCondition) {
        condition = condition && window.scrollY > scrollOffsetValue;
      }
      condition
        ? !shown && show()
        : shown && hide();
    }, [layoutActionCondition, shown, show, hide, scrollOffsetValue]);

    React.useEffect(() => {
      let timer: number | undefined;

      if (!shown) {
        setButtonUnmounting(true);
        timer = window.setTimeout(() => {
          setShowButton(false);
          setButtonUnmounting(false);
        }, 500);
      } else {
        setShowButton(true);
        setButtonUnmounting(false);
      }

      window.addEventListener('scroll', handleWindowScroll);

      return () => {
        if (timer) window.clearTimeout(timer);
        window.removeEventListener('scroll', handleWindowScroll);
      };
    }, [
      handleWindowScroll,
      layoutActionCondition,
      scrollOffsetValue,
      setButtonUnmounting,
      setShowButton,
      shown,
    ]);

    return {
      showButton,
      isButtonUnmounting,
    };
  },
  useRefreshPage() {
    return () => window.location.reload();
  },
  useScrollPresent(
    isMobileTablet: boolean,
  ) {
    const [isScrollPresent, setIsScrollPresent] = React.useState(false);

    const refCallback = (node: HTMLDivElement) => {
      if (isMobileTablet) return;

      const isCurrentScrollPresent = node?.scrollHeight && node?.clientHeight
        ? node.scrollHeight > node.clientHeight : false;

      if (isCurrentScrollPresent !== isScrollPresent) setIsScrollPresent(isCurrentScrollPresent);
    };

    return { isScrollPresent, refCallback };
  },
  useTrustBadgeScripts(language: LanguageCodeMapped, trustBadge: boolean) {
    React.useEffect(() => {
      const prepareScriptLoad = async () => {
        if (trustBadge && language === LanguageCodeMapped.DeDe) {
          await callbacks.loadTrustBadgeScript();
        }
      };
      prepareScriptLoad();
    }, [language, trustBadge]);
  },
  useClickOutside(ref: React.RefObject<HTMLDivElement>, callback: (event: Event) => void) {
    const clickListener = React.useCallback((event: Event) => {
      if (ref.current && !ref.current.contains(event.target as HTMLDivElement)) {
        callback(event);
      }
    }, [ref, callback]);

    React.useEffect(() => {
      const events = ['mousedown', 'touchstart'];
      events.forEach(e => {
        window.addEventListener(e, clickListener);
      });
      return () => {
        events.forEach(e => {
          window.removeEventListener(e, clickListener);
        });
      };
    }, [clickListener]);

    return clickListener;
  },
};
