import React from 'react';

import { AccountResetPasswordModal }
  from '@components/AccountResetPasswordModal/AccountResetPasswordModal';
import { GlobalMessages } from '@customTypes/Messages';
import { Modal } from '@customTypes/Modal';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import { queryHooks } from '@hooks/queryHooks/queryHooks';

export const hooks = {
  useTokenDetection(modalOpen: () => void, token?: string) {
    React.useEffect(() => {
      token && modalOpen();
    }, [token, modalOpen]);
  },
};

export const AccountResetPasswordModalContainer = () => {
  const modalId = messageHooks.useSubjectState(GlobalMessages.ModalOpen);
  const modalOpen =
    messageHooks.useNextWithValue(GlobalMessages.ModalOpen, Modal.AccountResetPasswordModal);
  const modalClose = messageHooks.useNextWithValue(GlobalMessages.ModalOpen, null);

  const { params: { token } } = queryHooks.useQueryParams();

  hooks.useTokenDetection(modalOpen, token);

  return (
    <AccountResetPasswordModal
      token={token}
      modalId={modalId}
      modalClose={modalClose}
    />
  );
};

AccountResetPasswordModalContainer.displayName = 'AccountResetPasswordModalContainer';
