import React from 'react';

import { GlobalMessages } from '@customTypes/Messages';

import { messageHooks } from '../messageHooks/messageHooks';

export const countrySelectPanelHooks = {
  useClosePanel(
  ) {
    const showCountrySelectPanel =
    messageHooks.useNext(GlobalMessages.CountrySelectPanelShown);

    const handlePanelClose = React.useCallback(() => {
      const localStorageItemStr = localStorage.getItem('panelWasClosedFlag');

      if (localStorageItemStr) {
        return;
      }

      showCountrySelectPanel(false);
      const convertedHours = 48 * 60 * 60 * 1000;
      const localStorageItem = {
        value: 'true',
        expiry: new Date().getTime() + convertedHours,
      };
      localStorage.setItem('panelWasClosedFlag', JSON.stringify(localStorageItem));
    }, [showCountrySelectPanel]);

    return { handlePanelClose };
  },
};
