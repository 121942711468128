import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import styles from './MaintenancePage.module.css';

const messages = defineMessages({
  siteUnderTheMaintenance: {
    id: 'src.components.MaintenancePage.siteUnderTheMaintenance',
    defaultMessage: 'The site is under maintenance',
  },
});

export const MaintenancePage = () => {
  return (
    <div className={styles.maintenancePage}>
      <FormattedMessage {...messages.siteUnderTheMaintenance} />
    </div>
  );
};

MaintenancePage.displayName = 'MaintenancePage';
