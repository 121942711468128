import React from 'react';
import { FieldError, useFormContext } from 'react-hook-form';
import { defineMessages, FormattedMessage } from 'react-intl';

import classnames from 'classnames';
import * as yup from 'yup';

import { formHooks } from '@hooks/formHooks/formHooks';

import { InputField } from '../InputField/InputField';
import styles from './FullNameSubForm.module.css';

export const validationSchema = yup.object().shape({
  firstName:
    yup.string().matches(/^([^0-9]*)$/, 'numbersNotAllowedError')
      .matches(/^([^!@#$%&*?<>^~{}]*)$/, 'specialSymbolsNotAllowed').trim().required('emptyError'),
  lastName:
    yup.string().matches(/^([^0-9]*)$/, 'numbersNotAllowedError')
      .matches(/^([^!@#$%&*?<>^~{}]*)$/, 'specialSymbolsNotAllowed').trim().required('emptyError'),
});

const messages = defineMessages({
  emptyError: {
    id: 'src.components.FullNameSubForm.emptyError',
    defaultMessage: 'Required field',
  },
  numbersNotAllowedError: {
    id: 'src.components.FullNameSubForm.numbersNotAllowedError',
    defaultMessage: 'Numbers are not allowed',
  },
  specialSymbolsNotAllowed: {
    id: 'src.components.FullNameSubForm.specialSymbolsNotAllowed',
    defaultMessage: 'Symbols are not allowed',
  },
});

export type FullNameFormValues = {
  fullName: {
    firstName: string;
    lastName: string;
  };
};

export type Props = {
  firstNameLabel: JSX.Element | string;
  firstNamePlaceholder?: string;
  fullWidthVertical?: boolean;
  lastNameLabel: JSX.Element | string;
  lastNamePlaceholder?: string;
  name: string;
};

export const FullNameSubForm: React.FC<Props> = React.memo((props) => {
  const {
    name,
    firstNameLabel,
    lastNameLabel,
    firstNamePlaceholder,
    lastNamePlaceholder,
    fullWidthVertical } = props;
  const { register, formState, getValues, getFieldState } = useFormContext();

  const values = getValues(name);

  const firstNameName = React.useMemo(() => `${name}.firstName`, [name]);
  const lastNameName = React.useMemo(() => `${name}.lastName`, [name]);

  const { isDirty: isFirstNameDirty, error: firstNameError } =
    getFieldState(firstNameName, formState);

  const firstNameState = formHooks.useCheckState(
    isFirstNameDirty,
    values?.firstName,
    firstNameError,
  );

  const { isDirty: isLastNameDirty, error: lastNameError } =
    getFieldState(lastNameName, formState);

  const lastNameState = formHooks.useCheckState(
    isLastNameDirty,
    values?.lastName,
    lastNameError,
  );

  const getFieldError = React.useCallback((error?: FieldError) => {
    switch (error?.message) {
      case 'emptyError':
        return (
          <FormattedMessage {...messages.emptyError} />
        );
      case 'numbersNotAllowedError':
        return (
          <FormattedMessage {...messages.numbersNotAllowedError} />
        );
      case 'specialSymbolsNotAllowed':
        return (
          <FormattedMessage {...messages.specialSymbolsNotAllowed} />
        );
      default:
        return undefined;
    }
  }, []);

  return (
    <div className={
      classnames(
        styles.inputsGroup,
        { [styles.fullWidthVertical]: fullWidthVertical },
      )}>
      <InputField
        {...register(firstNameName)}
        className={styles.inputWrapper}
        labelClassName={styles.inputLabel}
        inputSize='large'
        state={firstNameState}
        label={firstNameLabel}
        error={getFieldError(firstNameError)}
        placeholder={firstNamePlaceholder || ''}
      />
      <InputField
        {...register(lastNameName)}
        className={styles.inputWrapper}
        labelClassName={styles.inputLabel}
        inputSize='large'
        state={lastNameState}
        label={lastNameLabel}
        error={getFieldError(lastNameError)}
        placeholder={lastNamePlaceholder || ''}
      />
    </div>
  );
});

FullNameSubForm.displayName = 'FullNameSubForm';
