import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { AccountConfirmationModal }
  from '@components/AccountConfirmationModal/AccountConfirmationModal';
import styles from '@components/AccountConfirmationModal/AccountConfirmationModal.module.css';
import { Button, ButtonFit } from '@components/Button/Button';
import { Link, LinkType } from '@components/Link/Link';
import { Modal } from '@customTypes/Modal';
import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';

const messages = defineMessages({
  title: {
    id: 'src.components.AccountResetPasswordConfirmModal.title',
    defaultMessage: 'Password was updated',
  },
  description: {
    id: 'src.components.AccountResetPasswordConfirmModal.description',
    defaultMessage: 'Your password was successfully updated',
  },
  btnLeft: {
    id: 'src.components.AccountResetPasswordConfirmModal.firstButton',
    defaultMessage: 'Account overview',
  },
  btnRight: {
    id: 'src.components.AccountResetPasswordConfirmModal.secondButton',
    defaultMessage: 'Homepage',
  },
});

export type Props = {
  modalClose: () => void;
  modalId: Modal | null;
};

export const AccountResetPasswordConfirmModal = React.memo((props: Props) => {
  const {
    modalId,
    modalClose,
  } = props;

  const accountOverviewRedirect = navigationHooks.useAccountOverviewRedirect();
  const homepageRedirect = navigationHooks.useHomePageRedirect();

  return (
    <AccountConfirmationModal
      isOpen={modalId === Modal.AccountResetPasswordConfirmModal}
      onClose={modalClose}
      title={<FormattedMessage {...messages.title} />}
      description={<FormattedMessage {...messages.description} />}
      buttonPrimary={
        <Link
          className={styles.link}
          to={accountOverviewRedirect}
          type={LinkType.Href}
        >
          <Button
            intent='primaryV2'
            size='medium'
            fit={ButtonFit.Fill}
            onClick={modalClose}
          >
            <FormattedMessage {...messages.btnLeft} />
          </Button>
        </Link>
      }
      buttonSecondary={
        <Link
          className={styles.link}
          to={homepageRedirect}
          type={LinkType.Href}
        >
          <Button
            intent='secondaryV2'
            size='medium'
            fit={ButtonFit.Fill}
            onClick={modalClose}
          >
            <FormattedMessage {...messages.btnRight} />
          </Button>
        </Link>
      }
    />
  );
});

AccountResetPasswordConfirmModal.displayName = 'AccountResetPasswordConfirmModal';
