import React from 'react';

import {
  arrow,
  autoUpdate,
  offset,
  shift,
  useFloating,
  flip,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  FloatingArrow,
  useTransitionStyles,
} from '@floating-ui/react';
import type { Placement, Side } from '@floating-ui/react';

import { default as moduleStyles } from './Tooltip.module.css';

const ARROW_WIDTH = 10;
const ARROW_HEIGHT = 10;

type ContextType = ReturnType<typeof hooks.useTooltip> | null;

const TooltipContext = React.createContext<ContextType>(null);

export const hooks = {
  useTooltip(enabled: boolean, placement: Placement) {
    const [open, setOpen] = React.useState(false);
    const arrowRef = React.useRef(null);
    const data = useFloating({
      placement,
      open,
      onOpenChange: setOpen,
      middleware: [
        offset(ARROW_HEIGHT),
        flip({
          padding: 5,
        }),
        shift({ padding: 5 }),
        arrow({ element: arrowRef }),
      ],
      whileElementsMounted: autoUpdate,
    });

    const hover = useHover(data.context, { enabled, move: false });
    const focus = useFocus(data.context, { enabled });
    const dismiss = useDismiss(data.context, { enabled });
    const role = useRole(data.context, { role: 'tooltip' });
    const interactions = useInteractions([
      hover,
      focus,
      dismiss,
      role,
    ]);

    return React.useMemo(() => ({
      arrowRef,
      open,
      setOpen,
      ...interactions,
      ...data,
    }), [open, setOpen, interactions, data]);
  },
  useTooltipContext() {
    const context = React.useContext(TooltipContext);

    if (context == null) {
      throw new Error('Tooltip components must be wrapped in <Tooltip />');
    }

    return context;
  },
};

export const TooltipTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & { children: React.FunctionComponentElement<unknown> }
>(({ children, ...props }, propRef) => {
  const context = hooks.useTooltipContext();
  const ref = useMergeRefs([context.refs.setReference, propRef]);

  return (
    <div
      ref={ref}
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
    >
      { children }
    </div>
  );
});

export const TooltipContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(({ style, children, ...props }, propRef) => {
  const context = hooks.useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);
  const arrowX = context.middlewareData.arrow?.x ?? 0;
  const arrowY = context.middlewareData.arrow?.y ?? 0;
  const transformX = arrowX + ARROW_WIDTH / 2;
  const transformY = arrowY + ARROW_HEIGHT;
  const initialStyle = ({ side }: { placement: Placement; side: Side }) => ({
    opacity: 0,
    borderRadius: 20,
    transform: {
      top: 'translateY(5px) scale(0.75)',
      right: 'translateX(-5px) scale(0.75)',
      bottom: 'translateY(-5px) scale(0.75)',
      left: 'translateX(5px) scale(0.75)',
    }[side],
  });
  const { isMounted, styles: transitionStyles } = useTransitionStyles(context.context, {
    initial: initialStyle,
    common: ({ side }) => ({
      transformOrigin: {
        top: `${transformX}px calc(100% + ${ARROW_HEIGHT}px)`,
        bottom: `${transformX}px ${-ARROW_HEIGHT}px`,
        left: `calc(100% + ${ARROW_HEIGHT}px) ${transformY}px`,
        right: `${-ARROW_HEIGHT}px ${transformY}px`,
      }[side],
    }),
    open: ({ side }) => ({
      opacity: 1,
      borderRadius: 4,
      transform: {
        top: 'translateY(0) scale(1)',
        right: 'translateX(0) scale(1)',
        bottom: 'translateY(0) scale(1)',
        left: 'translateX(0) scale(1)',
      }[side],
    }),
    close: initialStyle,
  });

  return isMounted ? (
    <FloatingPortal>
      <div
        ref={ref}
        className={moduleStyles.tooltipContent}
        style={{
          ...context.floatingStyles,
        }}
        {...context.getFloatingProps(props)}
      >
        <div
          style={{
            background: 'var(--white)',
            padding: 12,
            borderRadius: 4,
            fontSize: 13,
            boxShadow: '0px 4px 10px -3px rgba(0,0,0,0.33)',
            maxWidth: 300,
            ...style,
            ...transitionStyles,
          }}
        >
          { children }
          <FloatingArrow ref={context.arrowRef} context={context.context} fill='white' />
        </div>
      </div>
    </FloatingPortal>
  ) : null;
});

export type Props = {
  children: React.ReactNode;
  enabled?: boolean;
  placement?: Placement;
};

export const Tooltip = React.memo((props: Props) => {
  const {
    children,
    enabled = true,
    placement = 'top',
  } = props;
  const tooltip = hooks.useTooltip(enabled, placement);

  return (
    <TooltipContext.Provider value={tooltip}>
      { children }
    </TooltipContext.Provider>
  );
});

Tooltip.displayName = 'Tooltip';
