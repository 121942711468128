import React from 'react';

import { responsiveStateHooks } from '@nxte-nl/responsive-state-hooks';

import { GlobalMessages } from '@customTypes/Messages';
import { LayoutExtension } from '@customTypes/ResponsiveState';
import { messageHooks } from '@hooks/messageHooks/messageHooks';

import customMedia from '../../media/media.json';

const layouts: string[] = [];
const layoutExtensions: LayoutExtension[] = [
  LayoutExtension.Mobile,
  LayoutExtension.Tablet,
  LayoutExtension.DesktopSmall,
  LayoutExtension.DesktopLarge,
];

export const ResponsiveStateContainer = React.memo(() => {
  const emptyCallback = React.useCallback(() => { return; }, []);
  const setLayoutExtension = messageHooks.useNext(GlobalMessages.LayoutExtension);
  responsiveStateHooks.useResponsiveState<string, LayoutExtension>(
    customMedia['custom-media'],
    layouts,
    layoutExtensions,
    emptyCallback,
    setLayoutExtension,
  );

  return null;
});

ResponsiveStateContainer.displayName = 'ResponsiveStateContainer';
