import React from 'react';
import { Helmet } from 'react-helmet';

import { ApiErrorModal } from '@components/ApiErrorModal/ApiErrorModal';
import { GlobalMessages } from '@customTypes/Messages';
import { Modal } from '@customTypes/Modal';
import { messageHooks } from '@hooks/messageHooks/messageHooks';

import { LayoutExtensionContainer } from '../LayoutExtensionContainer/LayoutExtensionContainer';

export const ApiErrorContainer = React.memo(() => {
  const modalId = messageHooks.useSubjectState(GlobalMessages.ModalOpen);
  const modalClose = messageHooks.useNextWithValue(GlobalMessages.ModalOpen, null);
  return (
    <>
      {
        modalId === Modal.ApiError ? (
          <Helmet>
            <meta name='prerender-status-code' content='500' />
          </Helmet>
        ) : null
      }
      <LayoutExtensionContainer>
        {({ layoutExtension }) => (
          <ApiErrorModal
            expanded={modalId === Modal.ApiError}
            layoutExtension={layoutExtension}
            onClose={modalClose}
          />
        )}
      </LayoutExtensionContainer>
    </>
  );
});

ApiErrorContainer.displayName = 'ApiErrorContainer';
