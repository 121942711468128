import React from 'react';

import { AccountTokenExpiredModal }
  from '@components/AccountTokenExpiredModal/AccountTokenExpiredModal';
import { GlobalMessages } from '@customTypes/Messages';
import { messageHooks } from '@hooks/messageHooks/messageHooks';

export const AccountTokenExpiredModalContainer = () => {
  const modalId = messageHooks.useSubjectState(GlobalMessages.ModalOpen);
  const modalClose = messageHooks.useNextWithValue(GlobalMessages.ModalOpen, null);

  return (
    <AccountTokenExpiredModal
      modalId={modalId}
      modalClose={modalClose}
    />
  );
};

AccountTokenExpiredModalContainer.displayName = 'AccountTokenExpiredModalContainer';
