import React from 'react';

import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';

import { Link, LinkType } from '../Link/Link';

const sizes = {
  extraLarge: {
    width: 195,
    height: 62,
  },
  large: {
    width: 160,
    height: 50,
  },
  medium: {
    height: 43,
    width: 138,
  },
  extraMedium: {
    height: 40,
    width: 126,
  },
  small: {
    height: 30,
    width: 96,
  },
  extraSmall: {
    height: 20,
    width: 63,
  },
} as const;

export type Props = {
  classname?: string;
  size: 'extraLarge' | 'large' | 'medium' | 'extraMedium' | 'small' | 'extraSmall';
};
export const Logo = React.memo((props: Props) => {
  const { size, classname } = props;

  const homePageRedirect = navigationHooks.useHomePageRedirect();
  return (
    <Link to={homePageRedirect} type={LinkType.Href} className={classname}>
      <svg
        {...sizes[size]}
        viewBox='0 0 160 50'
      >
        <g id='img/logo/hotelgiftcard' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='logo'>
            <path d='M17.3563636,7.04043478 C13.5934545,7.70057971 10.3105455,9.77811594 8.112,12.8904348 C5.91418182,16.0042029 5.05963636,19.7853623 5.704,23.5252174 C6.63927273,28.8172464 10.2341818,34.0186957 16.1083636,38.5744928 C18.5054545,40.4462319 21.0930909,42.0773913 23.7861818,43.4194203 C25.8712727,41.2252174 27.7367273,38.817971 29.3461818,36.2433333 C33.3003636,29.9730435 34.896,23.86 33.9629091,18.5795652 C33.2821818,14.8324638 31.1832727,11.5694203 28.0501818,9.3926087 C24.9170909,7.2157971 21.1185455,6.38028986 17.3563636,7.04043478 L17.3563636,7.04043478 Z M22.6676364,48.9578261 C19.1345455,47.3230435 15.7876364,45.2795652 12.7265455,42.8882609 C5.768,37.4918841 1.46836364,31.1244928 0.290909091,24.4636232 C-0.602181818,19.2752174 0.578909091,14.0469565 3.61890909,9.74043478 C6.66036364,5.43536232 11.2007273,2.56217391 16.4036364,1.64985507 C21.6065455,0.736811594 26.8581818,1.89188406 31.1927273,4.90202899 C35.5250909,7.91289855 38.4290909,12.4244928 39.3716364,17.6172464 C40.5476364,24.2694203 38.6894545,31.717971 34.0043636,39.1469565 C31.9432727,42.4426087 29.4981818,45.5034783 26.704,48.2773913 L24.9425455,49.9643478 L22.6676364,48.9578261 Z' id='Fill-1' fill='#FFC4E9' style={{ mixBlendMode: 'multiply' }}></path>
            <path d='M24.9266909,6.38021739 C21.1063273,6.38021739 17.5121455,7.8592029 14.8045091,10.5447101 C12.0983273,13.2302174 10.5986909,16.8063043 10.5805091,20.6142754 C10.5805091,25.9765942 13.2161455,31.7207971 18.2023273,37.2128261 C20.2357818,39.4700725 22.4917818,41.5178986 24.9281455,43.3193478 C27.3463273,41.5316667 29.6103273,39.4780435 31.6452364,37.2207971 C36.6379636,31.7193478 39.2728727,25.9744203 39.2728727,20.6012319 C39.2561455,16.8063043 37.7565091,13.2302174 35.0488727,10.5439855 C32.3419636,7.8592029 28.7470545,6.38021739 24.9274182,6.38021739 L24.9266909,6.38021739 Z M24.9281455,49.9642754 L22.9005091,48.607029 C19.6656,46.3592029 16.7245091,43.7671739 14.1216,40.8765942 C8.21323636,34.3700725 5.08741818,27.3555797 5.08741818,20.6012319 C5.11069091,15.3244203 7.18487273,10.3794928 10.9281455,6.66572464 C14.6721455,2.95123188 19.6430545,0.90557971 24.9266909,0.90557971 C30.2096,0.90557971 35.1819636,2.95123188 38.9259636,6.665 C42.6692364,10.380942 44.7434182,15.3258696 44.7666909,20.588913 C44.7666909,27.3534058 41.6401455,34.3671739 35.7274182,40.8838406 C33.1288727,43.7678986 30.1877818,46.3592029 26.9936,48.5794928 L24.9281455,49.9642754 Z' id='Fill-4' fill='#E27AFC' style={{ mixBlendMode: 'multiply' }}></path>
            <path d='M32.5104727,7.04043478 C28.7482909,6.38028986 24.9504727,7.2157971 21.8173818,9.3926087 C18.6835636,11.5694203 16.5846545,14.8324638 15.9046545,18.5795652 C14.9708364,23.86 16.5664727,29.9730435 20.5206545,36.2433333 C22.1301091,38.817971 23.9955636,41.2252174 26.0813818,43.4194203 C28.7737455,42.0773913 31.3613818,40.4462319 33.7584727,38.5744928 C39.6326545,34.0186957 43.2275636,28.8172464 44.1628364,23.5252174 C44.8072,19.7853623 43.9526545,16.0042029 41.7548364,12.8904348 C39.5562909,9.77811594 36.2733818,7.70057971 32.5112,7.04043478 L32.5104727,7.04043478 Z M24.9250182,49.9643478 L23.1635636,48.2773913 C20.3693818,45.5034783 17.9235636,42.4426087 15.8632,39.1469565 C11.1781091,31.717971 9.31992727,24.2694203 10.4952,17.6172464 C11.4377455,12.4244928 14.3424727,7.91289855 18.6748364,4.90202899 C23.0086545,1.89188406 28.2602909,0.736811594 33.4639273,1.64985507 C38.6661091,2.56217391 43.2064727,5.43536232 46.2479273,9.74043478 C49.2879273,14.0469565 50.4697455,19.2752174 49.5766545,24.4636232 C48.3992,31.1244928 44.0995636,37.4918841 37.1402909,42.8882609 C34.0799273,45.2795652 30.7330182,47.3230435 27.1992,48.9578261 L24.9250182,49.9643478 Z' id='Fill-6' fill='#2091EF' style={{ mixBlendMode: 'multiply' }}></path>
            <path d='M72.5729455,12.5226812 L72.5729455,19.1922464 C72.5729455,19.4915217 72.3816727,19.6821014 72.0813091,19.6821014 L69.5133091,19.6821014 C69.1853091,19.6821014 68.9940364,19.4915217 68.9940364,19.1922464 L68.9940364,13.0125362 C68.9940364,11.1342754 67.8464,9.88137681 66.2071273,9.88137681 C64.4042182,9.88137681 63.3111273,11.242971 63.3111273,12.9031159 L63.3111273,19.1922464 C63.3111273,19.4915217 63.1198545,19.6821014 62.8194909,19.6821014 L60.2514909,19.6821014 C59.9227636,19.6821014 59.7322182,19.4915217 59.7322182,19.1922464 L59.7322182,0.490797101 C59.7322182,0.190797101 59.9227636,0.000217391304 60.2514909,0.000217391304 L62.7925818,0.000217391304 C63.1198545,0.000217391304 63.3111273,0.190797101 63.3111273,0.490797101 L63.3111273,8.03137681 C64.0762182,7.24152174 65.2784,6.66978261 66.9176727,6.66978261 C70.2514909,6.66978261 72.5729455,9.01036232 72.5729455,12.5226812' id='Fill-9' fill='#161616'></path>
            <path d='M85.3599273,13.1757971 C85.3599273,11.3794203 83.9941091,9.90913043 82.1635636,9.90913043 C80.3606545,9.90913043 78.9672,11.3794203 78.9672,13.1757971 C78.9672,15.0004348 80.3606545,16.4424638 82.1635636,16.4424638 C83.9941091,16.4424638 85.3599273,15.0004348 85.3599273,13.1757971 M75.3606545,13.1757971 C75.3606545,9.47362319 78.3933818,6.66927536 82.1635636,6.66927536 C85.9337455,6.66927536 88.9664727,9.47362319 88.9664727,13.1757971 C88.9664727,16.877971 85.9337455,19.6823188 82.1635636,19.6823188 C78.3933818,19.6823188 75.3606545,16.877971 75.3606545,13.1757971' id='Fill-11' fill='#161616'></path>
            <path d='M92.9288,14.809058 L92.9288,9.66413043 L90.8800727,9.66413043 C90.5513455,9.66413043 90.3877091,9.47427536 90.3877091,9.14746377 L90.3877091,7.15978261 C90.3877091,6.86050725 90.5513455,6.66992754 90.8800727,6.66992754 L93.0378909,6.66992754 L93.0378909,3.10398551 C93.0378909,2.80471014 93.2022545,2.6134058 93.5295273,2.6134058 L95.9891636,2.6134058 C96.3164364,2.6134058 96.5084364,2.80471014 96.5084364,3.10398551 L96.5084364,6.66992754 L100.661164,6.66992754 C100.988436,6.66992754 101.180436,6.86050725 101.180436,7.15978261 L101.180436,9.14746377 C101.180436,9.47427536 100.988436,9.66413043 100.661164,9.66413043 L96.5084364,9.66413043 L96.5084364,14.4554348 C96.5084364,15.9257246 97.4364364,16.4699275 98.7208,16.4699275 C99.4313455,16.4699275 99.9506182,16.225 100.333164,16.0619565 C100.633527,15.8981884 100.879345,15.9525362 101.016073,16.2518116 L101.835709,18.0221014 C101.972436,18.3213768 101.890255,18.5112319 101.6168,18.7293478 C101.343345,18.9199275 100.114255,19.6822464 97.9011636,19.6822464 C94.8138909,19.6822464 92.9288,17.9394928 92.9288,14.809058' id='Fill-13' fill='#161616'></path>
            <path d='M106.836436,11.8694928 L112.682982,11.8694928 L112.656073,11.7876087 C112.437164,10.4535507 111.371709,9.50065217 109.950618,9.50065217 C108.366618,9.50065217 107.218255,10.3448551 106.836436,11.8694928 M116.043709,13.6658696 C116.016073,13.9658696 115.851709,14.1557246 115.551345,14.1557246 L106.836436,14.1557246 C107.245891,15.6260145 108.639345,16.6876087 110.552073,16.6876087 C111.972436,16.6876087 112.874255,16.170942 113.475709,15.7347101 C113.748436,15.5441304 113.994982,15.570942 114.157891,15.870942 L115.114982,17.5593478 C115.278618,17.831087 115.250982,18.0760145 115.005164,18.2665942 C114.157891,18.9194928 112.628436,19.6818116 110.169527,19.6818116 C106.180436,19.6818116 103.2568,16.8506522 103.2568,13.1760145 C103.2568,9.52818841 105.962255,6.66949275 109.814618,6.66949275 C113.611709,6.66949275 116.070618,9.44630435 116.070618,13.2035507 C116.070618,13.3658696 116.070618,13.5028261 116.043709,13.6658696' id='Fill-15' fill='#161616'></path>
            <path d='M119.022545,19.1923188 L119.022545,0.490144928 C119.022545,0.190869565 119.213818,0.000289855072 119.541091,0.000289855072 L122.109091,0.000289855072 C122.410182,0.000289855072 122.601455,0.190869565 122.601455,0.490144928 L122.601455,19.1923188 C122.601455,19.4915942 122.410182,19.6821739 122.109091,19.6821739 L119.541091,19.6821739 C119.213818,19.6821739 119.022545,19.4915942 119.022545,19.1923188' id='Fill-17' fill='#161616'></path>
            <path d='M69.1034909,37.2756522 C69.1034909,35.3698551 67.8460364,34.0089855 65.8794909,34.0089855 C64.0489455,34.0089855 62.6554909,35.3973913 62.6554909,37.2756522 C62.6554909,39.1271014 64.0489455,40.5423188 65.8794909,40.5423188 C67.8460364,40.5423188 69.1034909,39.1546377 69.1034909,37.2756522 M72.4642182,31.2604348 L72.4642182,42.7205797 C72.4642182,46.3684058 70.0045818,49.0908696 65.5784,49.0908696 C63.0104,49.0908696 61.2896727,48.5191304 60.0053091,47.7024638 C59.7318545,47.5386957 59.7049455,47.2937681 59.8685818,47.0213043 L60.8794909,45.306087 C61.0162182,45.0343478 61.2620364,44.98 61.5624,45.1155072 C62.5733091,45.6328986 63.9122182,46.0415942 65.3325818,46.0415942 C67.4642182,46.0415942 68.8845818,44.8162319 68.8845818,43.1828986 L68.8845818,42.2031884 C67.9558545,43.1285507 66.6991273,43.7821739 65.0874909,43.7821739 C61.6169455,43.7821739 59.0489455,40.9778261 59.0489455,37.2756522 C59.0489455,33.5734783 61.6991273,30.7691304 65.1689455,30.7691304 C67.0547636,30.7691304 68.4751273,31.5589855 69.4584,32.6205797 L69.5405818,31.2328986 C69.5405818,30.9336232 69.7587636,30.7698551 70.0591273,30.7698551 L71.9718545,30.7698551 C72.2722182,30.7698551 72.4642182,30.9336232 72.4642182,31.2604348' id='Fill-19' fill='#161616'></path>
            <path d='M76.1532364,43.2921739 L76.1532364,31.2602899 C76.1532364,30.9595652 76.3445091,30.7689855 76.6448727,30.7689855 L79.2128727,30.7689855 C79.5408727,30.7689855 79.7321455,30.9595652 79.7321455,31.2602899 L79.7321455,43.2921739 C79.7321455,43.5914493 79.5408727,43.782029 79.2128727,43.782029 L76.6448727,43.782029 C76.3168727,43.782029 76.1532364,43.5914493 76.1532364,43.2921739 M75.5517818,26.6044928 C75.5517818,25.2704348 76.5626909,24.3726087 77.8739636,24.3457971 C79.2128727,24.3457971 80.2514182,25.2704348 80.2514182,26.6044928 C80.2514182,27.9385507 79.2128727,28.863913 77.8739636,28.863913 C76.5626909,28.863913 75.5517818,27.9385507 75.5517818,26.6044928' id='Fill-21' fill='#161616'></path>
            <path d='M84.6766545,43.2921739 L84.6766545,33.763913 L82.8184727,33.763913 C82.4912,33.763913 82.2992,33.574058 82.2992,33.2472464 L82.2992,31.2602899 C82.2992,30.9595652 82.4912,30.7689855 82.8184727,30.7689855 L84.6766545,30.7689855 L84.6766545,29.5443478 C84.6766545,26.0602899 86.7253818,23.8827536 90.2773818,23.8827536 C91.6162909,23.8827536 92.5450182,24.2363768 93.1741091,24.59 C93.4475636,24.7537681 93.5013818,24.9986957 93.3646545,25.2704348 L92.4635636,27.0943478 C92.3268364,27.3668116 92.0810182,27.4218841 91.7806545,27.3124638 C91.5348364,27.2305797 91.1792,27.1218841 90.6875636,27.1218841 C89.4577455,27.1218841 88.2555636,27.8566667 88.2555636,29.7349275 L88.2555636,30.7689855 L91.5348364,30.7689855 C91.8352,30.7689855 92.0257455,30.9595652 92.0257455,31.2602899 L92.0257455,33.2472464 C92.0257455,33.574058 91.8352,33.763913 91.5348364,33.763913 L88.2555636,33.763913 L88.2555636,43.2921739 C88.2555636,43.5914493 88.0642909,43.782029 87.7639273,43.782029 L85.1959273,43.782029 C84.8679273,43.782029 84.6766545,43.5914493 84.6766545,43.2921739' id='Fill-23' fill='#161616'></path>
            <path d='M95.8243636,38.908913 L95.8243636,33.7647101 L93.7756364,33.7647101 C93.4469091,33.7647101 93.284,33.5748551 93.284,33.2480435 L93.284,31.2603623 C93.284,30.961087 93.4469091,30.7705072 93.7756364,30.7705072 L95.9334545,30.7705072 L95.9334545,27.2038406 C95.9334545,26.9045652 96.0978182,26.7132609 96.4250909,26.7132609 L98.8847273,26.7132609 C99.212,26.7132609 99.4032727,26.9045652 99.4032727,27.2038406 L99.4032727,30.7705072 L103.556727,30.7705072 C103.884,30.7705072 104.076,30.961087 104.076,31.2603623 L104.076,33.2480435 C104.076,33.5748551 103.884,33.7647101 103.556727,33.7647101 L99.4032727,33.7647101 L99.4032727,38.5552899 C99.4032727,40.0255797 100.332,40.5697826 101.616364,40.5697826 C102.326909,40.5697826 102.846182,40.3248551 103.228727,40.1618116 C103.529091,39.9980435 103.775636,40.0523913 103.911636,40.3516667 L104.731273,42.1219565 C104.868,42.4212319 104.785818,42.611087 104.512364,42.8292029 C104.238909,43.0197826 103.009818,43.7821014 100.796727,43.7821014 C97.7094545,43.7821014 95.8243636,42.0393478 95.8243636,38.908913' id='Fill-25' fill='#161616'></path>
            <path d='M106.125527,37.2756522 C106.125527,33.6278261 109.103709,30.7691304 113.037527,30.7691304 C115.141527,30.7691304 116.425891,31.3415942 117.245527,31.8857971 C117.518255,32.0488406 117.5728,32.2937681 117.382255,32.6481159 L116.4528,34.3908696 C116.289164,34.6901449 116.016436,34.7169565 115.742982,34.5807246 C115.223709,34.2814493 114.458618,34.0089855 113.446982,34.0089855 C111.261527,34.0089855 109.786618,35.3698551 109.786618,37.2756522 C109.786618,39.1814493 111.234618,40.5423188 113.393164,40.5423188 C114.458618,40.5423188 115.196073,40.2973913 115.769891,39.9713043 C116.070982,39.8075362 116.289164,39.8618841 116.425891,40.1336232 L117.382255,41.9582609 C117.545891,42.23 117.491345,42.4481159 117.217891,42.6386957 C116.698618,42.9930435 115.359709,43.7821739 113.037527,43.7821739 C109.021527,43.7821739 106.125527,40.9502899 106.125527,37.2756522' id='Fill-27' fill='#161616'></path>
            <path d='M129.459345,37.2756522 C129.459345,35.3698551 128.201891,34.0089855 126.235345,34.0089855 C124.4048,34.0089855 123.011345,35.3973913 123.011345,37.2756522 C123.011345,39.1271014 124.4048,40.5423188 126.235345,40.5423188 C128.201891,40.5423188 129.486255,39.1546377 129.459345,37.2756522 M132.901527,31.2604348 L132.901527,43.2923188 C132.901527,43.5915942 132.710982,43.7821739 132.382982,43.7821739 L130.224436,43.7821739 C129.896436,43.7821739 129.705164,43.6184058 129.678255,43.2923188 L129.595345,41.8495652 C128.666618,42.9655072 127.3008,43.7821739 125.443345,43.7821739 C121.9728,43.7821739 119.4048,40.9778261 119.4048,37.2756522 C119.4048,33.5734783 122.054982,30.7691304 125.5248,30.7691304 C127.191709,30.7691304 128.502982,31.3684058 129.459345,32.2394203 L129.5408,31.2328986 C129.568436,30.9336232 129.759709,30.7698551 130.060073,30.7698551 L132.382982,30.7698551 C132.710982,30.7698551 132.901527,30.9611594 132.901527,31.2604348' id='Fill-29' fill='#161616'></path>
            <path d='M144.868436,31.3413043 L144.676436,33.8456522 C144.649527,34.1992754 144.430618,34.3362319 144.102618,34.2543478 C143.912073,34.1992754 143.666255,34.1724638 143.365891,34.1724638 C141.370982,34.1724638 140.141164,35.4789855 140.141164,37.2485507 L140.141164,43.292029 C140.141164,43.5913043 139.949891,43.7818841 139.649527,43.7818841 L137.081527,43.7818841 C136.753527,43.7818841 136.562255,43.5913043 136.562255,43.292029 L136.562255,31.2608696 C136.562255,30.9615942 136.753527,30.7702899 137.081527,30.7702899 L139.0488,30.7702899 C139.349164,30.7702899 139.540436,30.9615942 139.568073,31.2608696 L139.677164,33.4652174 C140.114255,31.9398551 141.671345,30.7695652 143.829891,30.7695652 C144.048073,30.7695652 144.266982,30.7695652 144.458982,30.7971014 C144.759345,30.823913 144.895345,31.042029 144.868436,31.3413043' id='Fill-31' fill='#161616'></path>
            <path d='M156.234182,37.2756522 C156.234182,35.4242029 154.976727,34.0089855 153.010182,34.0089855 C151.179636,34.0089855 149.786182,35.4242029 149.786182,37.2756522 C149.786182,39.1546377 151.179636,40.5423188 153.010182,40.5423188 C154.976727,40.5423188 156.234182,39.1814493 156.234182,37.2756522 M159.648727,24.5901449 L159.648727,43.2915942 C159.648727,43.5908696 159.458182,43.7814493 159.130182,43.7814493 L157.218182,43.7814493 C156.917091,43.7814493 156.725818,43.6176812 156.698909,43.2915942 L156.616,41.903913 C155.633455,42.9930435 154.212364,43.7821739 152.327273,43.7821739 C148.856727,43.7821739 146.179636,40.9778261 146.179636,37.2756522 C146.179636,33.5734783 148.747636,30.7691304 152.218182,30.7691304 C153.885091,30.7691304 155.195636,31.4502899 156.098182,32.43 L156.098182,24.5901449 C156.098182,24.2908696 156.261091,24.0995652 156.589091,24.0995652 L159.130182,24.0995652 C159.458182,24.0995652 159.648727,24.2908696 159.648727,24.5901449' id='Fill-33' fill='#161616'></path>
          </g>
        </g>
      </svg>
    </Link>
  );
});
