import React, { EventHandler, SyntheticEvent } from 'react';

import classnames from 'classnames';

import { domHooks } from '@hooks/domHooks/domHooks';
import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';

import { HrefLink } from '../HrefLink/HrefLink';
import styles from './Link.module.css';

export enum LinkType {
  ExternalHref = 'externalHref',
  Href = 'href',
  Span = 'span',
}

export type BaseProps = {
  children: React.ReactNode;
  className?: string;
  color?: 'black' | 'pink' | 'blue';
  disabled?: boolean;
  id?: string;
  onClick?: EventHandler<SyntheticEvent>;
};

type LinkProps = BaseProps & {
  target?: string;
  to: string | (() => string);
  type: LinkType.Href;
};

type ExternalLinkProps = BaseProps & {
  target?: string;
  to: string;
  type: LinkType.ExternalHref;
};

type SpanProps = BaseProps & {
  type: LinkType.Span;
};

export type Props = LinkProps | SpanProps | ExternalLinkProps;

export const hooks = {
  useScrollAnchor(to: string | (() => string)) {
    const pathname = navigationHooks.usePathname();

    return React.useMemo(() => {
      const href = typeof to === 'string' ? to : to();
      if (!href.includes('#')) return null;

      const [path, scrollAnchor] = href.split('#');
      if (!pathname.includes(path)) return null;

      return scrollAnchor.length > 0 ? scrollAnchor : null;
    }, [pathname, to]);
  },
  useWrapOnClick(
    disabled: boolean | undefined,
    anchor: string | null,
    onClick: EventHandler<SyntheticEvent> | undefined,
  ) {
    const scrollToAnchor = domHooks.useScrollToElement(anchor || '');

    return React.useCallback((e: React.MouseEvent) => {
      if (disabled) return;
      if (anchor) scrollToAnchor();
      onClick?.(e);
    }, [anchor, disabled, onClick, scrollToAnchor]);
  },
};

export const Link = React.memo((props: Props) => {
  const { children, className, color, disabled, onClick, id } = props;
  const classNames = classnames(
    styles.link,
    styles[color || 'black'],
    disabled ? styles.disabled : '', className,
  );

  const anchor = hooks.useScrollAnchor(props.type !== LinkType.Span ? props.to : '');
  const wrapOnClick = hooks.useWrapOnClick(disabled, anchor, onClick);

  return props.type === LinkType.Href && !disabled ? (
    <HrefLink
      id={id}
      className={classNames}
      to={!anchor ? props.to : undefined}
      target={props.target}
      onClick={wrapOnClick}>
      {children}
    </HrefLink>
  ) : props.type === LinkType.ExternalHref && !disabled ? (
    <a
      id={id}
      href={!anchor ? props.to : undefined}
      target={props.target}
      className={classNames}
      onClick={wrapOnClick}
    >
      {children}
    </a>
  ) : (
    <span
      id={id}
      className={classNames}
      onClick={wrapOnClick}
    >
      {children}
    </span>
  );
});

