import React from 'react';

import { ErrorBoundary } from '@nxte-nl/error-boundary';

import { CountryCombination } from '@customTypes/apiTypes';
import { languageResourcesHooks } from '@hooks/languageResourcesHooks/languageResourcesHooks';
import { languageMappingInverse } from '@hooks/navigationHooks/navigationHooks';

import { CountryCode, LangCode } from '../../types/apiEnums';
import { CountrySelectionPanelContainer }
  from '../CountrySelectionPanelContainer/CountrySelectionPanelContainer';

export const hooks = {
  useLanguageRedirect(
    countriesCombinations: CountryCombination[] | null,
    countryCode?: CountryCode | null,
    regionCode?: string | null,
    homePage?: boolean,
  ) {
    React.useEffect(() => {
      if (!homePage || !countriesCombinations || !countryCode || !regionCode) {
        return;
      }

      const countryCombination =
        countriesCombinations?.find((cC) => cC.country === countryCode);

      let languageCombination = countryCombination?.languagesCombinations[0];

      if (countryCombination?.country === CountryCode.BE) {
        if (regionCode === 'WAL' || regionCode === 'BRU') {
          languageCombination = countryCombination?.languagesCombinations
            .find((lC) => lC.languageCode === LangCode.fr_be);
        } else {
          languageCombination = countryCombination?.languagesCombinations
            .find((lC) => lC.languageCode === LangCode.nl_be);
        }
      }

      if (!languageCombination) {
        window.location.href = '/en-gb/';
      } else {
        window.location.href = `/${languageMappingInverse[languageCombination.languageCode]}/`;
      }
    }, [countriesCombinations, countryCode, regionCode, homePage]);
  },
};

export type Props = {
  homePage?: boolean;
};

export const LocaleRedirectContainer = React.memo((props: Props) => {
  const { homePage } = props;
  const { supportedCountries, countriesCombinations } = languageResourcesHooks.useStoreData();
  const { countryCode, regionCode } = languageResourcesHooks.useIpLocation();
  hooks.useLanguageRedirect(countriesCombinations, countryCode, regionCode, homePage);
  languageResourcesHooks.useLanguageChangeDetection();

  return countryCode && regionCode && supportedCountries ? (
    <ErrorBoundary>
      <CountrySelectionPanelContainer
        countryCode={countryCode}
        regionCode={regionCode}
        supportedCountries={supportedCountries}
      />
    </ErrorBoundary>
  ) : null;
});

LocaleRedirectContainer.displayName = 'LocaleRedirectContainer';
