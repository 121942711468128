import React from 'react';

import { ErrorBoundary } from '@nxte-nl/error-boundary';

import { FeatureToggleValues } from '@customTypes/FeatureToggles';
import { GlobalMessages } from '@customTypes/Messages';
import { messageHooks } from '@hooks/messageHooks/messageHooks';

export type Props = {
  children: (featureToggles: FeatureToggleValues) => React.ReactNode;
};

export const FeatureToggleHocContainer = React.memo((props: Props) => {
  const { children } = props;

  const featureToggles = messageHooks.useSubjectState(GlobalMessages.FeatureToggles);

  return (
    <ErrorBoundary>
      {children(featureToggles)}
    </ErrorBoundary>
  );
});

FeatureToggleHocContainer.displayName = 'FeatureToggleHocContainer';
