import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { ErrorBoundary } from '@nxte-nl/error-boundary';

import { AccountSwitcher } from '@components/AccountSwitcher/AccountSwitcher';
import { AccountModalStepperContainer }
  from '@containers/AccountModalStepperContainer/AccountModalStepperContainer';
import { AccountRegistrationConfirmContainer }
  from '@containers/AccountRegistrationConfirmContainer/AccountRegistrationConfirmContainer';
import { AccountRequestPasswordConfirmModalContainer }
  from '@containers/AccountRequestPasswordConfirmModalContainer/AccountRequestPasswordConfirmModalContainer';
import { AccountResetPasswordConfirmModalContainer }
  from '@containers/AccountResetPasswordConfirmModalContainer/AccountResetPasswordConfirmModalContainer';
import {
  AccountResetPasswordModalContainer,
} from '@containers/AccountResetPasswordModalContainer/AccountResetPasswordModalContainer';
import {
  AccountTokenExpiredModalContainer,
} from '@containers/AccountTokenExpiredModalContainer/AccountTokenExpiredModalContainer';
import { LayoutExtensionContainer }
  from '@containers/LayoutExtensionContainer/LayoutExtensionContainer';
import { AccountMessages } from '@customTypes/Messages';
import { GlobalMessages } from '@customTypes/Messages';
import { accountHooks } from '@hooks/accountHooks/accountHooks';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';

export type Props = {
  withName?: boolean;
};

export const AccountSwitcherContainer = ({ withName }: Props) => {
  const userInfoData = messageHooks.useSubjectState(AccountMessages.UserInfo);
  const pathname = navigationHooks.usePathname();
  const { accountRelease1,
    accountRelease2,
    accountRelease3,
    accountRelease4 } = messageHooks.useSubjectState(GlobalMessages.FeatureToggles);

  const { onLogOut } = accountHooks.useLogOut();

  return (
    <>
      <ErrorBoundary>
        <LayoutExtensionContainer>
          {({ layoutExtension }) => (
            <AccountSwitcher
              layoutExtension={layoutExtension}
              loggedIn={!userInfoData?.data.errors_v1?.length && !!userInfoData?.data.user}
              userName={userInfoData?.data.user?.mainProfile.firstName || null}
              onLogOut={onLogOut}
              accountRelease1={accountRelease1}
              accountRelease2={accountRelease2}
              accountRelease3={accountRelease3}
              accountRelease4={accountRelease4}
              isSidebarMenu={false}
              withName={withName}
            />
          )}
        </LayoutExtensionContainer>
        {/* eslint-disable-next-line max-len */}
        {pathname.match(/\/business|\/account\/(overview|personal-details)|\/booking\/success|check-balance|\/buy\/pay\/success/)
          ? <AccountModalStepperContainer/> : <GoogleReCaptchaProvider
            reCaptchaKey={CONFIG.reCaptchaKey}
            scriptProps={{ defer: true }}
          >
            <AccountModalStepperContainer/>
          </GoogleReCaptchaProvider>}
      </ErrorBoundary>
      <AccountResetPasswordModalContainer />
      <AccountResetPasswordConfirmModalContainer />
      <AccountTokenExpiredModalContainer />
      <AccountRequestPasswordConfirmModalContainer />
      <AccountRegistrationConfirmContainer />
    </>
  );
};

AccountSwitcherContainer.displayName = 'AccountSwitcherContainer';
