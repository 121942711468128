export enum Modal {
  AccountCancelBookingConfirmModal = 'accountCancelBookingConfirmModal',
  AccountDeleteModal = 'accountDeleteModal',
  AccountGiftcardDetailsModal = 'accountGiftcardDetailsModal',
  AccountHotelBookingDetailsModal = 'accountHotelBookingDetailsModal',
  AccountInitialModal = 'accountInitialModal',
  AccountOrderedGiftcardDetailsModal = 'accountOrderedGiftcardDetailsModal',
  AccountRegistrationConfirmModal = 'accountRegistrationConfirmModal',
  AccountRegistrationModal = 'accountRegistrationModal',
  AccountRequestPasswordConfirmModal = 'accountRequestPasswordConfirmModal',
  AccountResetPasswordConfirmModal = 'accountResetPasswordConfirmModal',
  AccountResetPasswordModal = 'accountResetPasswordModal',
  AccountTokenExpiredModal = 'accountTokenExpiredModal',
  AddGftCardModal = 'addGftCardModal',
  ApiError = 'apiError',
  Autocomplete = 'autocomplete',
  BuyGiftcardApiError = 'buyGiftcardApiError',
  ChangeUserDataModal = 'ChangeUserDataModal',
  ChangeUserPasswordConfirmationModal = 'ChangeUserPasswordConfirmationModal',
  ChangeUserPasswordModal = 'ChangeUserPasswordModal',
  DateRange = 'dateRange',
  DesktopFilters = 'desktopFilters',
  GiftCardBasket = 'giftCardBasket',
  HotelBasket = 'hotelBasket',
  HotelBookingApiError = 'hotelBookingApiError',
  HotelCheckoutApiError = 'hotelCheckoutApiError',
  HotelDetailsApiErrorContainer = 'hotelDetailsApiErrorContainer',
  LanguageSwitcher = 'languageSwitcher',
  LanguageSwitcherV2 = 'languageSwitcherV2',
  Occupancy = 'occupancy',
}
