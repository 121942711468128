import React from 'react';

import { AccountRequestPasswordConfirmModal }
  from '@components/AccountRequestPasswordConfirmModal/AccountRequestPasswordConfirmModal';
import { GlobalMessages } from '@customTypes/Messages';
import { messageHooks } from '@hooks/messageHooks/messageHooks';

export const AccountRequestPasswordConfirmModalContainer = () => {
  const modalId = messageHooks.useSubjectState(GlobalMessages.ModalOpen);
  const modalClose = messageHooks.useNextWithValue(GlobalMessages.ModalOpen, null);

  return (
    <AccountRequestPasswordConfirmModal
      modalId={modalId}
      modalClose={modalClose}
    />
  );
};

AccountRequestPasswordConfirmModalContainer.displayName =
  'AccountRequestPasswordConfirmModalContainer';
