import React from 'react';

import { max, format } from 'date-fns';

import { apolloHooks } from '@nxte-nl/apollo-hooks';

import { BookingData } from '@containers/AccountBookingsPageContainer/AccountBookingsPageContainer';
import { BaseVariables } from '@customTypes/apiCompound';
import {
  GiftCardSortingInput,
  GiftCardOrder,
  GiftCardOrdersResponse,
  HotelBookingOrder,
  HotelRoomOrderItem,
  MutationuserDeleteAccountArgs,
  MutationuserLogoutArgs,
  OrderFlow,
  OrdersResponse,
  QuerygetUserGiftCardsArgs,
  QuerygetUserBookingOrdersArgs,
  QuerygetUserGiftCardOrdersArgs,
  SortingGiftCardOrderInput,
  SortingOrderInput,
  StatusResponse, UserGiftCardsResponse,
  Package,
} from '@customTypes/apiTypes';
import { BaseResponse, OrderState } from '@customTypes/common';
import { dataMappingHooks } from '@hooks/dataMappingHooks/dataMappingHooks';
import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';
import { userDeleteAccount } from '@mutations/userDeleteAccount.gql';
import { userLogout } from '@mutations/userLogout.gql';
import { getUserBookingOrders } from '@queries/getUserBookingOrders.gql';
import { getUserGiftCardOrders } from '@queries/getUserGiftCardOrders.gql';
import { getUserGiftCards } from '@queries/getUserGiftCards.gql';

export const accountHooks = {
  useLogOut() {
    const pathname = navigationHooks.usePathname();

    const [mutate] = apolloHooks
      .useMutation<BaseResponse<StatusResponse>, BaseVariables<MutationuserLogoutArgs>>(
        userLogout, {
          refetchQueries:
            pathname.includes('/account/') ? ['getUserInfo'] : ['getUserInfo', 'giftcardBasket'],
        },
      );

    return {
      onLogOut: React.useCallback(() => mutate(), [mutate]),
    };
  },
  useDeleteUser() {
    const [mutate, { loading }] = apolloHooks
      .useMutation<BaseResponse<StatusResponse>, BaseVariables<MutationuserDeleteAccountArgs>>(
        userDeleteAccount,
        {
          refetchQueries: ['getUserInfo'],
        },
      );

    return {
      onUserDelete: React.useCallback(() => mutate(), [mutate]),
      loading,
    };
  },
  useUserBookingOrders(sortingOrder?: SortingOrderInput) {
    const { data, loading } = apolloHooks.useQuery<
    BaseResponse<OrdersResponse>,
    BaseVariables<QuerygetUserBookingOrdersArgs>
  >(getUserBookingOrders, {
    variables: {
      paging: {
        currentPage: 0,
        itemsPerPage: 999,
      },
      sortingOrder,
    },
    context: { orderFlow: OrderFlow.hg_booking_order_v2 },
  });

    return {
      loading,
      orders: (data?.data.orders || []) as HotelBookingOrder[],
      paging: data?.data.paging || null,
    };
  },
  useUserGiftCards(
    sortingOrder: GiftCardSortingInput,
    hideExpired = false,
    accountCheckout = false,
  ) {
    const { data, loading, refetch } = apolloHooks.useQuery<
      BaseResponse<UserGiftCardsResponse>,
      BaseVariables<QuerygetUserGiftCardsArgs>
    >(getUserGiftCards, {
      variables: {
        paging: {
          currentPage: 0,
          itemsPerPage: 999,
        },
        sortingOrder,
        hideExpired,
      },
      context: { orderFlow: OrderFlow.hg_booking_order_v2 },
      skip: !accountCheckout,
    });

    const userGiftCards: UserGiftCardsResponse | null = React.useMemo(() =>
      data?.data.paging?.totalItems && data?.data.giftCards && data?.data.totalBalance
        ? data.data : null, [data]);

    return {
      loading,
      userGiftCards,
      refetchGiftCards: refetch,
    };
  },
  useMapUserBookingOrdersToOrdersOverview(orders: HotelBookingOrder[]) {
    return React.useMemo(() => {
      const mappedBookings = orders.map((o) => {
        const hotelRoomOrderItem =
          o.shipments?.[0]?.packages?.[0]?.items?.[0] as HotelRoomOrderItem;

        return o.bookingDate && hotelRoomOrderItem.checkin
          && hotelRoomOrderItem.checkout
          && hotelRoomOrderItem.hotelTeaser
          && hotelRoomOrderItem.occupancy ? {
            uid: o.uid,
            bookingDate: o.bookingDate,
            bookingNumber: o.id,
            bookingStatus: o.orderState as OrderState,
            dates: {
              from: hotelRoomOrderItem.checkin,
              to: hotelRoomOrderItem.checkout,
            },
            hotelName: hotelRoomOrderItem.hotelTeaser?.title,
            hotelImage: hotelRoomOrderItem.hotelTeaser?.image,
            occupancy: hotelRoomOrderItem.occupancy,
            price: o.grandTotal,
          } : null;
      });
      return mappedBookings.filter((b) => b) as BookingData[];
    }, [orders]);
  },
  useOrderedGiftCards(sortingOrder: SortingGiftCardOrderInput, skip?: boolean) {
    const { data, loading } = apolloHooks.useQuery<
      BaseResponse<GiftCardOrdersResponse>,
      BaseVariables<QuerygetUserGiftCardOrdersArgs>
    >(getUserGiftCardOrders, {
      variables: {
        paging: {
          currentPage: 0,
          itemsPerPage: 999,
        },
        sortingOrder,
      },
      skip,
      context: { orderFlow: OrderFlow.giftcard_order },
    });

    return {
      loading,
      orders: (data?.data.orders || []) as GiftCardOrder[],
      paging: data?.data.paging || null,
    };
  },
  useMapOrderedGiftcardData(order: GiftCardOrder | null) {
    return React.useMemo(() => {
      if (order === null
        || order.shipments === null
        || order.shipments?.[0].packages === null
        || order.shipments?.[0].packages?.[0].items === null)
        return null;

      const packages = order?.shipments?.reduce((packages: Package[], shipment) =>
        packages.concat(shipment.packages || []), []) || [];
      const shipmentCost = order?.shipments?.filter((s) =>
        s.shipmentCost.value !== 0)?.[0]?.shipmentCost;
      const deliveryDate = order?.shipments?.reduce((deliveryDates, s) =>
        deliveryDates.concat(s.deliveryDate), []);
      const customerShipmentData = order?.shipments?.[1]?.shipmentPersonalInfo?.address.postalCode
        ? order?.shipments?.[1]?.shipmentPersonalInfo
        : order?.shipments?.[0]?.shipmentPersonalInfo;

      return {
        customerBillingData: order?.billingPersonalInfo?.customer ?? null,
        customerShipmentData: customerShipmentData || null,
        grandTotal: order?.grandTotal ?? null,
        orderDate: order?.orderCompleteDate || '',
        orderDetailsFile: order?.orderDetailsFile || '',
        orderItems: dataMappingHooks.useMapAccountOrderModalItems(packages),
        orderNumber: order.id,
        tax: order?.orderTaxes?.[0]?.taxAmount || null,
        creditCardPayments: order?.creditCardPayments || [],
        deliveryDate: format(max(deliveryDate || []), 'yyyy-MM-dd').toString(),
        cartUid: order?.uid || '',
        shipmentCost: shipmentCost || null,
      };
    }, [order]);
  },
};
