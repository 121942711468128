import React from 'react';

import { ErrorBoundary } from '@nxte-nl/error-boundary';

import { Modal } from '@components/Modal/Modal';
import { Typography } from '@components/Typography/Typography';
import { LayoutExtensionContainer }
  from '@containers/LayoutExtensionContainer/LayoutExtensionContainer';

import styles from './AccountConfirmationModal.module.css';

export type Props = {
  buttonPrimary?: JSX.Element | string | null;
  buttonSecondary?: JSX.Element | string | null;
  description?: JSX.Element | string;
  isOpen: boolean;
  onClose: () => void;
  title?: JSX.Element | string;
};

export const AccountConfirmationModal = React.memo((props: Props) => {
  const {
    description,
    isOpen,
    onClose,
    title,
    buttonPrimary = null,
    buttonSecondary = null,
  } = props;

  const titleValue = React.useMemo(() => (title ? (
    <Typography variant='h2' className={styles.title}>
      {title}
    </Typography>
  ) : null), [title]);

  return (
    <ErrorBoundary>
      <LayoutExtensionContainer>
        {({ layoutExtension, isDesktop }) => {
          return <Modal
            title={!isDesktop && titleValue}
            modalClassName={styles.modal}
            isOpen={isOpen}
            onClose={onClose}
            layoutExtension={layoutExtension}
            mobileV2
          >
            <div className={styles.content}>
              {
                titleValue && isDesktop ? (
                  <div className={styles.header}>
                    {titleValue}
                  </div>
                ) : null
              }
              {
                description ? (
                  <Typography variant='body4' className={styles.description}>
                    {description}
                  </Typography>
                ) : null
              }
              <div className={styles.buttons}>
                { buttonSecondary }
                { buttonPrimary }
              </div>
            </div>
          </Modal>;
        }}
      </LayoutExtensionContainer>
    </ErrorBoundary>
  );
});

AccountConfirmationModal.displayName = 'AccountConfirmationModal';
