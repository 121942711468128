import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import classnames from 'classnames';

import { ErrorBoundary } from '@nxte-nl/error-boundary';

import { TooltipContent, TooltipTrigger, Tooltip } from '@components/Tooltip/Tooltip';
import { CountryCode } from '@customTypes/apiEnums';

import { CountrySelect } from '../CountrySelect/CountrySelect';
import { InputField, InputState } from '../InputField/InputField';
import { Typography } from '../Typography/Typography';
import { Info as InfoIcon } from '../icons/Info/Info';
import styles from './PhoneNumberField.module.css';

const messages = defineMessages({
  phone: {
    id: 'src.components.PhoneNumberField.phone',
    defaultMessage: 'Phone number*',
  },
  phoneInfo: {
    id: 'src.components.PhoneNumberField.phoneInfo',
    defaultMessage: 'We need your phone number for important updates about your order.',
  },
});

export type Props = JSX.IntrinsicElements['input'] & {
  className?: string;
  customPhoneInfo?: JSX.Element | string;
  error?: React.ReactNode;
  errorClassName?: string;
  inputWrapperClassName?: string;
  inputsGroupClassName?: string;
  onCountryChange: (countryCode: CountryCode, phoneCode: string) => void;
  phoneCode: string;
  prefillCountryCode?: CountryCode | null;
  ref?: React.ForwardedRef<HTMLInputElement>;
  state?: InputState;
};
export const PhoneNumberField: React.FC<Props> = React.memo(React.forwardRef(( {
  className,
  inputsGroupClassName,
  inputWrapperClassName,
  phoneCode,
  state,
  errorClassName,
  error,
  onCountryChange,
  prefillCountryCode,
  customPhoneInfo,
  ...inputProps
}, ref: React.ForwardedRef<HTMLInputElement>) => {
  return (
    <div className={classnames(styles.phoneNumberField, className)}>
      <div className={styles.inputLabelContainer}>
        <Typography component='label' className={styles.inputLabel}>
          <FormattedMessage {...messages.phone} />
        </Typography>
        <div className={styles.infoIconContainer}>
          <Tooltip>
            <TooltipTrigger>
              <div className={styles.infoIconWrapper}>
                <InfoIcon />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              {customPhoneInfo
                ? customPhoneInfo : <FormattedMessage {...messages.phoneInfo} />}
            </TooltipContent>
          </Tooltip>
        </div>
      </div>
      <div className={classnames(styles.inputsGroup, inputsGroupClassName)}>
        <div className={classnames(styles.inputWrapper, inputWrapperClassName)}>
          <ErrorBoundary>
            <CountrySelect onChange={onCountryChange} defaultValue={prefillCountryCode || ''} />
          </ErrorBoundary>
        </div>
        <div className={classnames(styles.inputWrapper, inputWrapperClassName)}>
          <InputField
            {...inputProps}
            ref={ref}
            inputSize='large'
            prefixLabel={<Typography className={styles.phoneCode}>{phoneCode}</Typography>}
            state={state}
            inputClassName={styles.phoneInput}
            errorClassName={errorClassName}
            error={error}
            inputMode='numeric'
          />
        </div>
      </div>
    </div>
  );
}));

PhoneNumberField.displayName = 'PhoneNumberField';
