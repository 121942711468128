/* eslint-disable max-len */
import React from 'react';

import classnames from 'classnames';

import styles from './ExpediaLogo.module.css';

export type Props = {
  className?: string;
  height?: number;
  onClick?(): void;
  width?: number;
};
export const ExpediaLogo = React.memo((props: Props) => {
  const { width = 102, height = 22, className, onClick } = props;
  return (
    <svg
      width={width}
      height={height}
      className={classnames(className, styles.svg)}
      onClick={onClick}
      viewBox='0 0 403.22 78.18'
    >
      <path d='m158.46,41.8l13.67-18.88c.41-.58.08-1.16-.58-1.16h-7.43c-.41,0-.91.16-1.16.57l-9.02,13.15-9.24-13.15c-.24-.41-.74-.57-1.16-.57h-7.93c-.41,0-.66.33-.66.66,0,.17,0,.33.17.5l13.71,18.94-15.12,20.78c-.41.58-.08,1.16.49,1.16h7.52c.41,0,.91-.17,1.16-.58l10.45-15.06,10.61,15.06c.24.41.74.58,1.16.58h8.01c.41,0,.66-.33.66-.66,0-.17-.08-.33-.17-.5l-15.15-20.84Z' fill='#120531'/>
      <path d='m127.93,5.99c.58,0,.99.41.99.99v5.61c0,.58-.41.99-.99.99h-26.68v16.69h23.96c.57,0,.99.41.99.99v5.53c0,.58-.42.99-.99.99h-23.96v18.25h26.68c.58,0,.99.41.99.99v5.78c0,.58-.41.99-.99.99h-31.22c-2.4,0-4.22-1.82-4.22-4.21V10.2c0-2.4,1.82-4.22,4.22-4.22h31.22Z' fill='#120531'/>
      <path d='m200.37,57.94c-8.09,0-13.63-5.45-13.63-15.12s5.54-15.28,13.63-15.28,13.55,5.62,13.55,15.28-5.45,15.12-13.55,15.12m1.49-37.5c-8.1,0-12.4,3.55-14.87,6.86v-4.55c0-.57-.41-.99-.99-.99h-6.53c-.58,0-.99.41-.99.99v54.43c0,.58.41.99.99.99h6.61c.57,0,.99-.41.99-.99v-18.75c2.39,3.3,6.68,6.61,14.78,6.61,13.13,0,20.81-9.91,20.81-22.3s-7.69-22.3-20.81-22.3' fill='#120531'/>
      <path d='m236.86,38.62c.98-6.89,4.58-11.4,11.77-11.4s11.23,4.22,11.81,11.4h-23.58Zm11.83-18.17c-11.48,0-20.65,8.26-20.65,21.97s8.09,22.63,21.72,22.63c7.85,0,14.38-3.47,18.42-8.26.26-.24.34-.49.34-.74s-.08-.49-.34-.74l-3.8-3.72c-.24-.25-.49-.33-.74-.33s-.49.17-.74.41c-3.14,3.39-7.19,6.28-13.06,6.28-8.55,0-12.66-5.27-13.29-12.88h31.71c.58,0,.99-.41.99-.99v-1.65c0-12.55-7.6-21.97-20.56-21.97' fill='#120531'/>
      <path d='m366.47,58.02c-8.1,0-13.55-5.61-13.55-15.28s5.45-15.11,13.55-15.11,13.63,5.45,13.63,15.11-5.53,15.28-13.63,15.28m20.9-36.26h-6.53c-.58,0-.99.41-.99.99v4.62c-2.48-3.3-6.78-6.85-15.04-6.85-12.96,0-20.65,9.91-20.65,22.3s7.68,22.3,20.65,22.3c8.26,0,12.56-3.55,15.04-6.86v4.54c0,.58.41.99.99.99h6.53c.58,0,.99-.41.99-.99V22.76c0-.58-.41-.99-.99-.99' fill='#120531'/>
      <path d='m398.76,29.28c-2.15,0-3.63-1.57-3.63-3.72s1.49-3.63,3.63-3.63,3.55,1.57,3.55,3.63-1.49,3.72-3.55,3.72m0-8.17c-2.56,0-4.55,1.99-4.55,4.46s1.99,4.55,4.55,4.55,4.46-1.99,4.46-4.55-1.98-4.46-4.46-4.46' fill='#120531'/>
      <path d='m398.93,25.23h-.91v-1.16h.91c.49,0,.66.33.66.58,0,.33-.17.57-.66.57m.67.68c.68-.2.9-.78.9-1.25,0-.66-.49-1.32-1.41-1.32h-1.74c-.08,0-.16.08-.16.17v4.13c0,.08.08.16.16.16h.5c.08,0,.16-.08.16-.16v-1.65h.74l.75,1.65c.09.16.17.16.25.16h.57c.09,0,.17-.08.09-.24l-.82-1.64Z' fill='#120531'/>
      <path d='m296.93,58.02c-8.1,0-13.55-5.61-13.55-15.28s5.45-15.11,13.55-15.11,13.63,5.45,13.63,15.11-5.53,15.28-13.63,15.28m20.9-52.03h-6.61c-.58,0-.99.41-.99.99v20.4c-2.48-3.3-6.69-6.85-14.95-6.85-12.96,0-20.65,9.91-20.65,22.3s7.68,22.3,20.65,22.3c8.26,0,12.56-3.55,15.04-6.86v4.54c0,.58.41.99.99.99h6.53c.58,0,.99-.41.99-.99V6.98c0-.58-.41-.99-.99-.99' fill='#120531'/>
      <path d='m335.83,5.99c.57,0,.99.41.99.99v6.77c0,.58-.41.99-.99.99h-6.78c-.58,0-.99-.41-.99-.99v-6.77c0-.58.41-.99.99-.99h6.78Z' fill='#120531'/>
      <rect x='328.14' y='21.76' width='8.59' height='42.04' rx='.99' ry='.99' fill='#120531'/>
      <path d='m55.44,69.79H14.35c-7.93,0-14.35-6.43-14.35-14.35V14.35C0,6.43,6.42,0,14.35,0h41.09c7.93,0,14.35,6.43,14.35,14.35v41.09c0,7.93-6.42,14.35-14.35,14.35' fill='#ffe81c'/>
      <path d='m21.33,56.22v-6.77c0-.55-.44-.99-.99-.99h-6.77c-.55,0-.99.45-.99.99v6.77c0,.55.45.99.99.99h6.77c.55,0,.99-.45.99-.99' fill='#120531'/>
      <path d='m57.21,50.42V17.04c0-2.48-1.99-4.46-4.46-4.46H19.37c-.66,0-1.07.25-1.49.66l-6.69,6.69c-.49.5-.16,1.24.59,1.24h30.18s-18.72,18.71-18.72,18.71c-.33.33-.5.66-.5,1.24v4.92c0,.57.42.99.99.99h4.92c.58,0,.91-.17,1.24-.5l18.72-18.72v30.19c0,.74.75,1.07,1.24.58l6.7-6.69c.42-.41.66-.82.66-1.49' fill='#120531'/>
    </svg >
  );
});

ExpediaLogo.displayName = 'ExpediaLogo';
