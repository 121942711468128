import React from 'react';

import { RegistrationConfirmationModal }
  from '@components/RegistrationConfirmationModal/RegistrationConfirmationModal';
import { GlobalMessages } from '@customTypes/Messages';
import { Modal } from '@customTypes/Modal';
import { messageHooks } from '@hooks/messageHooks/messageHooks';

export const AccountRegistrationConfirmContainer = () => {
  const modalId = messageHooks.useSubjectState(GlobalMessages.ModalOpen);
  const modalClose = messageHooks.useNextWithValue(GlobalMessages.ModalOpen, null);

  return (
    <RegistrationConfirmationModal
      isOpen={modalId === Modal.AccountRegistrationConfirmModal}
      onClose={modalClose}
    />
  );
};

AccountRegistrationConfirmContainer.displayName = 'AccountRegistrationConfirmContainer';
