import React from 'react';
import { FlagIcon, FlagIconCode } from 'react-flag-kit';
import { useIntl } from 'react-intl';

import { languageCountryMapping } from '@components/CountrySelect/CountrySelect';
import { LanguageFormV2 } from '@components/LanguageFormV2/LanguageFormV2';
import { GlobalMessages } from '@customTypes/Messages';
import { LanguageSpecificUrlType } from '@customTypes/apiCompoundLanguageSpecificUrl';
import { CountryCombination, LanguageCombination } from '@customTypes/apiTypes';
import { LanguageCodeMapped } from '@customTypes/common';
import { countrySelectPanelHooks } from '@hooks/countrySelectPanelHooks/countrySelectPanelHooks';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import { languageMapping, languageMappingInverse, navigationHooks }
  from '@hooks/navigationHooks/navigationHooks';
import { queryHooks } from '@hooks/queryHooks/queryHooks';

export const hooks = {
  useHandleCountrySelectChange(
    data: CountryCombination[] | null,
    setSelectedCountry: (country: string) => void,
  ) {
    const intl = useIntl();

    let countryData = null;
    if (data) {
      countryData = data.map((item: CountryCombination) => {
        return {
          id: item.country,
          label: intl.formatDisplayName(item.country, { type: 'region' }) ?? item.country,
          icon:
            <FlagIcon
              code={item.country as FlagIconCode}
              size={30}
              style={{ minWidth: 30, height: 16, borderRadius: 4 }}
            />,
        };
      });
    }

    const handleCountrySelectChange = React.useCallback(
      (id: string) => {
        setSelectedCountry(id);
      },
      [setSelectedCountry],
    );

    return { handleCountrySelectChange, countryData };
  },
  useHandleLanguageNCurrencyChange(
    data: CountryCombination[] | null,
    selectedCountry: string,
  ) {
    const intl = useIntl();

    let languageData = null;
    let currency = '';

    if (data && selectedCountry.length) {
      const countryDataTarget = data.find((item) => item.country === selectedCountry);

      if (countryDataTarget) {
        languageData = countryDataTarget.languagesCombinations.map(
          (langComb: LanguageCombination) => {
            const languageType = languageMappingInverse[langComb.languageCode];
            const labelText = intl.formatDisplayName(languageType, { type: 'language' })
              ?.replace(/\(([^)]+)\)/, '').trim() ?? '';

            return {
              id: languageType,
              label: labelText,
            };
          },
        );

        currency = countryDataTarget.languagesCombinations[0].currency;
      }
    }

    return { languageData, currency };
  },
  useHandleLanguageSelectChange(
    languageData:
      | {
          id: string;
          label: string;
        }[]
      | null,
    setSelectedLanguage: (language: string) => void,
  ) {
    const handleLanguageSelectChange = React.useCallback(
      (id: string) => {
        setSelectedLanguage(id);
      },
      [setSelectedLanguage],
    );

    const currentLanguage = navigationHooks.useLanguage();

    const isLangAvailable = languageData?.some((lang) => lang.id === currentLanguage) ?? false;
    const defaultLanguage = isLangAvailable
      ? currentLanguage
      : languageData ? languageData[0].id : '';

    React.useEffect(() => {
      setSelectedLanguage(defaultLanguage);
    }, [defaultLanguage, setSelectedLanguage]);

    return { handleLanguageSelectChange };
  },
  useSelectedLanguage(
    currentLanguage: string,
    selectedLanguage: string,
    onClose: () => void,
  ) {
    const { url } = queryHooks.useQueryParams();
    const urls = messageHooks.useSubjectState(GlobalMessages.Urls);
    const entityUrl = navigationHooks.useEntityUrl();
    const { handlePanelClose } = countrySelectPanelHooks.useClosePanel();
    const selectedLanguageCode = selectedLanguage as LanguageCodeMapped;

    const onLanguageSelected = React.useCallback(() => {
      handlePanelClose();

      if (currentLanguage !== selectedLanguageCode) {
        const specificUrl = urls && urls[LanguageSpecificUrlType.PageEntity].find((url) =>
          url.langCode === languageMapping[selectedLanguageCode]);
        const specificUrlWithoutTrailingSlashOnHome =
          specificUrl && specificUrl.url === '/' ? '' : specificUrl?.url;
        window.location.href =
          `/${selectedLanguage}${specificUrlWithoutTrailingSlashOnHome ?? entityUrl}/${url.search}`;
      }

      onClose();
    }, [
      urls,
      selectedLanguage,
      entityUrl,
      url,
      onClose,
      selectedLanguageCode,
      handlePanelClose,
      currentLanguage,
    ]);

    return { onLanguageSelected };
  },
};

export type Props = {
  onClose: () => void;
};

export const LanguageFormContainerV2 = React.memo((props: Props) => {
  const { onClose } = props;
  const storeData = messageHooks.useSubjectState(GlobalMessages.StoreData);

  const currentLanguage = navigationHooks.useLanguage();
  const [selectedCountry, setSelectedCountry] =
    React.useState<string>(languageCountryMapping[currentLanguage]);
  const [selectedLanguage, setSelectedLanguage] = React.useState<string>('');

  const countriesCombinations = storeData?.data?.countriesCombinations || null;

  const {
    handleCountrySelectChange,
    countryData,
  } = hooks.useHandleCountrySelectChange(countriesCombinations, setSelectedCountry);

  const { languageData, currency } = hooks.useHandleLanguageNCurrencyChange(
    countriesCombinations,
    selectedCountry,
  );

  const { handleLanguageSelectChange } = hooks.useHandleLanguageSelectChange(
    languageData,
    setSelectedLanguage,
  );

  const { onLanguageSelected } = hooks.useSelectedLanguage(
    currentLanguage,
    selectedLanguage,
    onClose,
  );

  return (
    <LanguageFormV2
      countryData={countryData}
      languageData={languageData}
      currency={currency}
      handleCountrySelectChange={handleCountrySelectChange}
      handleLanguageSelectChange={handleLanguageSelectChange}
      onLanguageSelected={onLanguageSelected}
      defaultCountryId={selectedCountry}
      defaultLangId={selectedLanguage}
    />
  );
});

LanguageFormContainerV2.displayName = 'LanguageFormContainerV2';
