import React from 'react';

export type Props = {
  className?: string;
  color?: string;
  height?: number;
  onClick?(): void;
  width?: number;
};

export const Account = React.memo((props: Props) => {
  const {
    width = 24,
    height = 24,
    className,
    color = '#161616',
    onClick,
  } = props;

  const style = {
    minWidth: width,
    minHeight: height,
  };

  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      style={style}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
    >
      <g id='np_account_2366989_000000 1' clipPath='url(#clip0_15056_7196)'>
        <g id='Group'>
          <path id='Vector' d='M15.5031 9.60018C15.5031 11.5352 13.9337 13.1047 11.9986 13.1047C10.0635 13.1047 8.49414 11.5353 8.49414 9.60018C8.49414 7.66509 10.0635 6.0957 11.9986 6.0957C13.9337 6.0957 15.5031 7.66509 15.5031 9.60018Z' fill={color} />
          <path id='Vector_2' d='M11.9996 0.600098C5.71185 0.600098 0.599609 5.71234 0.599609 12.0001C0.599609 18.2879 5.71185 23.4001 11.9996 23.4001C18.2874 23.4001 23.3996 18.2879 23.3996 12.0001C23.3996 5.71234 18.2874 0.600098 11.9996 0.600098ZM18.0718 18.8646C16.9918 16.0559 14.6874 14.1846 11.9996 14.1846C9.31185 14.1846 7.00737 16.0568 5.92737 18.8646C4.03175 17.1846 2.83185 14.7368 2.83185 12.0001C2.83185 6.93562 6.93633 2.83234 11.9996 2.83234C17.0641 2.83234 21.1674 6.93586 21.1674 12.0001C21.1674 14.7356 19.9674 17.1846 18.0718 18.8646Z' fill={color} />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_15056_7196'>
          <rect width={width} height={height} fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
});

Account.displayName = 'Account';
