import React from 'react';
import { useForm, UseFormTrigger } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Button, ButtonFit } from '@components/Button/Button';
import { InputField } from '@components/InputField/InputField';
import { Typography } from '@components/Typography/Typography';
import { formHooks } from '@hooks/formHooks/formHooks';

import styles from './AccountResetPasswordForm.module.css';

const messages = defineMessages({
  description: {
    id: 'src.components.AccountResetPasswordForm.description',
    defaultMessage: 'Vul je e-mailadres in om in te loggen of een account aan te maken.',
  },
  passwordLabel: {
    id: 'src.components.AccountResetPasswordForm.passwordLabel',
    defaultMessage: 'Nieuw wachtwoord',
  },
  passwordPlaceholder: {
    id: 'src.components.AccountResetPasswordForm.passwordPlaceholder',
    defaultMessage: 'Vul je wachtwoord in',
  },
  passwordHint: {
    id: 'src.components.AccountResetPasswordForm.passwordHint',
    defaultMessage: 'Tenminste 8 karakters en 1 cijfer',
  },
  passwordNotCorrect: {
    id: 'src.components.AccountResetPasswordForm.passwordNotCorrect',
    defaultMessage: 'Het ingevoerde wachtwoord is ongeldig',
  },
  repeatPasswordLabel: {
    id: 'src.components.AccountResetPasswordForm.repeatPasswordLabel',
    defaultMessage: 'Herhaal wachtwoord',
  },
  repeatPasswordPlaceholder: {
    id: 'src.components.AccountResetPasswordForm.repeatPasswordPlaceholder',
    defaultMessage: 'Vul je wachtwoord in',
  },
  passwordShortError: {
    id: 'src.components.AccountResetPasswordForm.passwordShortError',
    defaultMessage: 'Wachtwoord te kort',
  },
  passwordDoesNotMatch: {
    id: 'src.components.AccountResetPasswordForm.passwordDoesNotMatch',
    defaultMessage: 'Passwords does not match',
  },
  emptyError: {
    id: 'src.components.AccountResetPasswordForm.emptyError',
    defaultMessage: 'Required field',
  },
  nextStep: {
    id: 'src.components.AccountResetPasswordForm.nextStep',
    defaultMessage: 'Wachtwoord instellen',
  },
  passwordBackendNotValid: {
    id: 'src.components.AccountResetPasswordForm.passwordBackendNotValid',
    defaultMessage: 'The entered password is invalid.',
  },
});

export type ResetPasswordFormValues = {
  password: string;
  repeatPassword: string;
};

export type Props = {
  loading: boolean;
  onSubmit: (data: ResetPasswordFormValues) => void;
};

export const hooks = {
  useCheckPassword (
    password: string,
    trigger: UseFormTrigger<ResetPasswordFormValues>,
  ) {
    React.useEffect(() => {
      trigger('repeatPassword');
    }, [password, trigger]);
  },
};

export const AccountResetPasswordForm = React.memo((props: Props) => {
  const {
    loading,
    onSubmit,
  } = props;

  const intl = useIntl();

  const passwordRegex = React.useMemo(() => /[A-Za-z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/, []) ;

  const validationSchema = React.useMemo(() => yup.object().shape({
    password: yup.string().min(8, 'passwordShortError').required('emptyError')
      .matches(passwordRegex, 'passwordNotCorrect'),
    repeatPassword: yup.string().required('emptyError')
      .min(8, 'passwordShortError').oneOf([yup.ref('password')], 'passwordDoesNotMatch'),
  }), [passwordRegex]);

  const methods = useForm<ResetPasswordFormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    delayError: 1000,
  });
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields, isValid },
    getValues,
    trigger,
    watch,
  } = methods;

  const [password, repeatPassword] = getValues(['password', 'repeatPassword']);

  const passwordState = formHooks.useCheckState(
    dirtyFields.password,
    password,
    errors.password,
  );
  const repeatPasswordState = formHooks.useCheckState(
    dirtyFields.repeatPassword,
    repeatPassword,
    errors.repeatPassword,
  );

  const passwordValue = watch('password');
  hooks.useCheckPassword(passwordValue, trigger);

  return (
    <>
      <Typography variant='body4' className={styles.description}>
        <FormattedMessage {...messages.description} />
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          {...register('password')}
          withShowPassword={true}
          inputSize='large'
          state={passwordState}
          label={<FormattedMessage {...messages.passwordLabel} />}
          labelClassName={styles.label}
          error={errors.password && (
            <FormattedMessage
              {...messages[errors.password.message as
                ('emptyError' | 'passwordBackendNotValid' | 'passwordShortError')]}
            />
          )}
          placeholder={intl.formatMessage(messages.passwordPlaceholder)}
          hint={
            <Typography variant='body3'>
              <FormattedMessage {...messages.passwordHint} />
            </Typography>
          }
        />
        <InputField
          {...register('repeatPassword')}
          withShowPassword={true}
          inputSize='large'
          state={repeatPasswordState}
          label={<FormattedMessage {...messages.repeatPasswordLabel} />}
          labelClassName={styles.label}
          onBlur={() => trigger('password')}
          error={errors.repeatPassword && (
            <FormattedMessage
              {...messages[errors.repeatPassword.message as
                ('emptyError' | 'passwordDoesNotMatch' | 'passwordShortError')]}
            />
          )}
          placeholder={intl.formatMessage(messages.repeatPasswordPlaceholder)}
        />
        <Button
          type='submit'
          intent='primaryV2'
          size='medium'
          disabled={!isValid}
          loading={loading}
          fit={ButtonFit.Fill}
        >
          <FormattedMessage {...messages.nextStep} />
        </Button>
      </form>
    </>
  );
});

AccountResetPasswordForm.displayName = 'AccountResetPasswordForm';
