import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { ErrorBoundary } from '@nxte-nl/error-boundary';

import { Modal } from '@components/Modal/Modal';
import { Typography } from '@components/Typography/Typography';
import { AccountResetPasswordFormContainer }
  from '@containers/AccountResetPasswordFormContainer/AccountResetPasswordFormContainer';
import { LayoutExtensionContainer }
  from '@containers/LayoutExtensionContainer/LayoutExtensionContainer';
import { Modal as ModalType } from '@customTypes/Modal';

import styles from './AccountResetPasswordModal.module.css';

const messages = defineMessages({
  title: {
    id: 'src.components.AccountResetPasswordModal.title',
    defaultMessage: 'Nieuw wachtwoord instellen',
  },
});

export type Props = {
    modalClose: () => void;
    modalId: ModalType | null;
    token?: string;
};

export const AccountResetPasswordModal = React.memo((props: Props) => {
  const {
    token,
    modalId,
    modalClose,
  } = props;

  const title = React.useMemo(() => (
    <Typography variant='h2' className={styles.title}>
      <FormattedMessage {...messages.title} />
    </Typography>
  ), []);

  return (
    <ErrorBoundary>
      <LayoutExtensionContainer>
        {({ layoutExtension, isDesktop }) => {
          return (
            <Modal
              title={!isDesktop && title}
              modalClassName={styles.modal}
              isOpen={modalId === ModalType.AccountResetPasswordModal}
              onClose={modalClose}
              layoutExtension={layoutExtension}
              disableCloseByClickOutside={true}
              mobileV2
            >
              <div className={styles.content}>
                {
                  isDesktop ? (
                    <div className={styles.header}>
                      {title}
                    </div>
                  ) : null
                }
                <AccountResetPasswordFormContainer
                  layoutExtension={layoutExtension}
                  token={token}
                />
              </div>
            </Modal>
          );
        }}
      </LayoutExtensionContainer>
    </ErrorBoundary>
  );
});

AccountResetPasswordModal.displayName = 'AccountResetPasswordModal';
