import React from 'react';

import countyCodesList from 'country-codes-list';
import countries, { Alpha2Code } from 'i18n-iso-countries';
import deLocale from 'i18n-iso-countries/langs/de.json';
import enLocale from 'i18n-iso-countries/langs/en.json';
import esLocale from 'i18n-iso-countries/langs/es.json';
import frLocale from 'i18n-iso-countries/langs/fr.json';
import itLocale from 'i18n-iso-countries/langs/it.json';
import nlLocale from 'i18n-iso-countries/langs/nl.json';

import { LanguageCodeMapped } from '@customTypes/common';
import {
  navigationHooks,
} from '@hooks/navigationHooks/navigationHooks';

import { BasicSelect, SelectItem } from '../BasicSelect/BasicSelect';
import styles from './CountrySelect.module.css';

countries.registerLocale({
  locale: LanguageCodeMapped.DeDe,
  countries: deLocale.countries,
});

countries.registerLocale({
  locale: LanguageCodeMapped.EsEs,
  countries: esLocale.countries,
});

countries.registerLocale({
  locale: LanguageCodeMapped.EnGb,
  countries: enLocale.countries,
});

countries.registerLocale({
  locale: LanguageCodeMapped.FrBe,
  countries: frLocale.countries,
});

countries.registerLocale({
  locale: LanguageCodeMapped.FrFr,
  countries: frLocale.countries,
});

countries.registerLocale({
  locale: LanguageCodeMapped.ItIt,
  countries: itLocale.countries,
});

countries.registerLocale({
  locale: LanguageCodeMapped.NlBe,
  countries: nlLocale.countries,
});

countries.registerLocale({
  locale: LanguageCodeMapped.NlNl,
  countries: nlLocale.countries,
});

countries.registerLocale({
  locale: LanguageCodeMapped.EnDe,
  countries: deLocale.countries,
});

countries.registerLocale({
  locale: LanguageCodeMapped.EnEs,
  countries: esLocale.countries,
});

countries.registerLocale({
  locale: LanguageCodeMapped.EnBe,
  countries: nlLocale.countries,
});

countries.registerLocale({
  locale: LanguageCodeMapped.EnFr,
  countries: frLocale.countries,
});

countries.registerLocale({
  locale: LanguageCodeMapped.EnIt,
  countries: itLocale.countries,
});

countries.registerLocale({
  locale: LanguageCodeMapped.EnNl,
  countries: nlLocale.countries,
});

export const phoneCodesList: PhoneCodesList =
  countyCodesList.customList('countryCode', '+{countryCallingCode}');

export type PhoneCodesList = {
  [key: string]: string;
};

export const languageCountryMapping: {
  [key in LanguageCodeMapped]: Alpha2Code;
} = {
  [LanguageCodeMapped.NlNl]: 'NL',
  [LanguageCodeMapped.EnGb]: 'GB',
  [LanguageCodeMapped.DeDe]: 'DE',
  [LanguageCodeMapped.EsEs]: 'ES',
  [LanguageCodeMapped.FrFr]: 'FR',
  [LanguageCodeMapped.FrBe]: 'BE',
  [LanguageCodeMapped.ItIt]: 'IT',
  [LanguageCodeMapped.NlBe]: 'BE',
  [LanguageCodeMapped.EnNl]: 'NL',
  [LanguageCodeMapped.EnBe]: 'BE',
  [LanguageCodeMapped.EnFr]: 'FR',
  [LanguageCodeMapped.EnIt]: 'IT',
  [LanguageCodeMapped.EnDe]: 'DE',
  [LanguageCodeMapped.EnEs]: 'ES',
};

export const hooks = {
  useCountriesList(countryCodes?: Alpha2Code[]): SelectItem[] {
    const language = navigationHooks.useLanguage();
    const formattedLang = language.startsWith('en') ? LanguageCodeMapped.EnGb : language;

    return React.useMemo(() => {
      const list: SelectItem[] = [];
      if (countryCodes && countryCodes.length) {
        const items = countryCodes.map((code) => ({
          id: code,
          label: countries.getName(code, formattedLang),
        }));
        list.push(...items);
      } else {
        const countriesNames = countries.getNames(formattedLang);
        for (const alpha2Key in countriesNames) {
          list.push({ id: alpha2Key, label: countriesNames[alpha2Key] });
        }
      }
      return list.sort((a, b) => a.label.localeCompare(b.label));
    }, [formattedLang, countryCodes]);
  },
  useDropdownState(
    onChange: (countryCode: string, phoneCode: string) => void,
    defaultValue?: string | null,
  ) {
    const language = navigationHooks.useLanguage();

    const [selectedCountryId, setSelectedCountryId] =
      React.useState<string>(defaultValue ? defaultValue : languageCountryMapping[language]);

    React.useEffect(() => {
      defaultValue && setSelectedCountryId(defaultValue);

      !defaultValue && onChange(selectedCountryId, phoneCodesList[selectedCountryId]);
    }, [defaultValue, onChange, selectedCountryId]);

    const handleCountryChange = React.useCallback(
      (countryCode: string) => {
        setSelectedCountryId(countryCode);

        onChange(countryCode, phoneCodesList[countryCode]);
      },
      [onChange],
    );

    return {
      selectedCountryId,
      onSelected: handleCountryChange,
    };
  },
};

export type Props = {
  countryCodes?: Alpha2Code[];
  defaultValue?: string;
  onChange: (countryCode: Alpha2Code, phoneCode: string) => void;
};
export const CountrySelect = React.memo((props: Props) => {
  const { selectedCountryId, onSelected } = hooks.useDropdownState(
    props.onChange,
    props.defaultValue,
  );
  const countriesList = hooks.useCountriesList(props.countryCodes);

  return (
    <BasicSelect
      selectClasses={{
        basicSelectHeader: styles.basicSelectHeader,
        basicSelectHeaderValue: styles.basicSelectHeaderValue,
      }}
      items={countriesList}
      defaultSelectedItemId={selectedCountryId}
      search
      onChange={onSelected}
    />
  );
});

CountrySelect.displayName = 'CountrySelect';
