import React from 'react';

import classnames from 'classnames';

import { stateHooks } from '@nxte-nl/state-hooks';

import { EyeClose } from '@components/icons/EyeClose/EyeClose';
import { EyeOpen } from '@components/icons/EyeOpen/EyeOpen';

import { Close } from '../icons/Close/Close';
import styles from './Input.module.css';

export enum InputState {
  Default = 'default',
  Error = 'error',
  Success = 'success',
}

export const hooks = {
  useOnFocus(innerOnFocus: () => void, outerOnFocus?: ChangeHandler) {
    return React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
      innerOnFocus();
      outerOnFocus && outerOnFocus(event);
    }, [innerOnFocus, outerOnFocus]);
  },
  useOnBlur(innerOnBlur: () => void, outerOnBlur?: ChangeHandler) {
    return React.useCallback((event: React.FocusEvent<HTMLInputElement>) => {
      innerOnBlur();
      outerOnBlur && outerOnBlur(event);
    }, [innerOnBlur, outerOnBlur]);
  },
};

export type ChangeHandler =
  (event: React.SyntheticEvent<HTMLInputElement>) => unknown;

export type Props = JSX.IntrinsicElements['input'] & {
  handleClearButtonCLick?: () => void;
  inputClassName?: string;
  inputSize: 'large' | 'medium' | 'small' | 'extraSmall';
  noBackgroundPrefix?: boolean;
  placeholder?: string | JSX.Element;
  prefixLabel?: React.ReactNode;
  ref?: React.Ref<HTMLInputElement>;
  state?: InputState;
  withClearButton?: boolean;
  withShowPassword?: boolean;
};
export const Input = React.memo(React.forwardRef((
  props: Props,
  ref: React.ForwardedRef<HTMLInputElement>,
) => {
  const {
    className,
    state = InputState.Default,
    inputSize,
    prefixLabel: prefix,
    inputClassName,
    onFocus,
    onBlur,
    readOnly,
    noBackgroundPrefix,
    withClearButton,
    handleClearButtonCLick,
    placeholder,
    withShowPassword,
    ...rest
  } = props;

  const {
    value: focused,
    setTrue: innerOnFocus,
    setFalse: innerOnBlur,
  } = stateHooks.useBooleanState();
  const handleFocus = hooks.useOnFocus(innerOnFocus, onFocus);
  const handleBlur = hooks.useOnBlur(innerOnBlur, onBlur);

  const {
    value: showPassword,
    toggle: handlePasswordShown,
  } = stateHooks.useBooleanState({ initialState: false });

  return (
    <div>
      <div
        className={classnames(styles.inputWrapper, {
          [styles.withPrefix]: !!prefix,
          [styles[state]]: true,
          [styles[inputSize]]: true,
          [styles.focused]: focused && !readOnly,
          [styles.withPasswordIcon]: withShowPassword,
        }, className)}
      >
        <div className={classnames({
          [styles.prefix]: typeof prefix === 'string',
          [styles.noBackgroundPrefix]: noBackgroundPrefix,
        })}>{prefix}</div>
        <input
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={ref}
          className={classnames(styles.input, inputClassName)}
          readOnly={readOnly}
          placeholder={placeholder}
          type={withShowPassword
            ? showPassword ? 'text' : 'password'
            : props.type
          }
          {...rest}
        />
        {state === InputState.Error && withClearButton
          ? <div className={styles.inputCloseButton}>
            <Close
              className={styles.closeIcon}
              onClick={handleClearButtonCLick}
              color='#FF2929'
              width={16}
              height={16}
              boldIcon
            />
          </div> : null}

        {withShowPassword ? (
          <div className={styles.passwordIcon}>
            {showPassword
              ? <EyeClose onClick={handlePasswordShown} />
              : <EyeOpen onClick={handlePasswordShown} />
            }
          </div>
        ) : null}
      </div>
    </div>
  );
}));

Input.displayName = 'Input';
