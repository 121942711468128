import React from 'react';
import { FormProvider, useForm, UseFormSetError, UseFormTrigger } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import 'yup-phone';
import { textCallbacks } from '@callbacks/textCallbacks/textCallbacks';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { stateHooks } from '@nxte-nl/state-hooks';

import { Button, ButtonFit } from '@components/Button/Button';
import { Checkbox } from '@components/Checkbox/Checkbox';
import { FullNameFormValues, FullNameSubForm, validationSchema as fullNameValidationSchema }
  from '@components/FullNameSubForm/FullNameSubForm';
import { InputField } from '@components/InputField/InputField';
import { PhoneNumberField } from '@components/PhoneNumberField/PhoneNumberField';
import { Typography } from '@components/Typography/Typography';
import { CountryCode } from '@customTypes/apiEnums';
import { BaseResponseV2, StatusResponsePresentational } from '@customTypes/common';
import { formHooks } from '@hooks/formHooks/formHooks';
import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';

import styles from './AccountRegistrationForm.module.css';

const messages = defineMessages({
  description: {
    id: 'src.components.AccountRegistrationForm.description',
    defaultMessage: 'Welkom bij Hotel Giftcard! We willen nog een paar dingen van je weten om een account aan te maken.',
  },
  firstNameLabel: {
    id: 'src.components.AccountRegistrationForm.firstNameLabel',
    defaultMessage: 'Voornaam*',
  },
  firstNamePlaceholder: {
    id: 'src.components.AccountRegistrationForm.firstNamePlaceholder',
    defaultMessage: 'Vul je voornaam in',
  },
  lastNameLabel: {
    id: 'src.components.AccountRegistrationForm.lastNameLabel',
    defaultMessage: 'Achternaam*',
  },
  lastNamePlaceholder: {
    id: 'src.components.AccountRegistrationForm.lastNamePlaceholder',
    defaultMessage: 'Vul je achternaam in',
  },
  emailLabel: {
    id: 'src.components.AccountRegistrationForm.emailLabel',
    defaultMessage: 'E-mailadres*',
  },
  emailPlaceholder: {
    id: 'src.components.AccountRegistrationForm.emailPlaceholder',
    defaultMessage: 'Vul je e-mailadres in*',
  },
  passwordLabel: {
    id: 'src.components.AccountRegistrationForm.passwordLabel',
    defaultMessage: 'Wachtwoord*',
  },
  passwordConfirmLabel: {
    id: 'src.components.AccountRegistrationForm.passwordConfirmLabel',
    defaultMessage: 'Bevestig wachtwoord*',
  },
  passwordHint: {
    id: 'src.components.AccountRegistrationForm.passwordHint',
    defaultMessage: 'Tenminste 8 karakters en 1 cijfer',
  },
  emptyError: {
    id: 'src.components.AccountRegistrationForm.emptyError',
    defaultMessage: 'Dit is een verplicht veld',
  },
  phoneNotValid: {
    id: 'src.components.AccountRegistrationForm.phoneNotValid',
    defaultMessage: 'Het ingevoerde telefoonnummer is ongeldig',
  },
  passwordNotCorrect: {
    id: 'src.components.AccountRegistrationForm.passwordNotCorrect',
    defaultMessage: 'Het ingevoerde wachtwoord is ongeldig',
  },
  passwordShortError: {
    id: 'src.components.AccountRegistrationForm.passwordShortError',
    defaultMessage: 'Wachtwoord te kort',
  },
  passwordDoesntMatch: {
    id: 'src.components.AccountRegistrationForm.passwordDoesntMatch',
    defaultMessage: '"Dit wachtwoord komt niet overeen',
  },
  emailSubscriptionCheckbox: {
    id: 'src.components.AccountRegistrationForm.emailSubscriptionCheckbox',
    defaultMessage: 'Ja, ik ontvang graag de leukste reisinspiratie per e-mail.',
  },
  termsAndConditionsCheckbox: {
    id: 'src.components.AccountRegistrationForm.termsAndConditionsCheckbox',
    defaultMessage: 'Ik heb de <link>algemene voorwaarden</link> gelezen en ga ermee akkoord.',
  },
  nextStep: {
    id: 'src.components.AccountRegistrationForm.nextStep',
    defaultMessage: 'Naar bezorging',
  },
  backLink: {
    id: 'src.components.AccountRegistrationForm.backLink',
    defaultMessage: 'Terug naar inloggen',
  },
  emailNotCorrect: {
    id: 'src.components.AccountRegistrationForm.emailNotCorrect',
    defaultMessage: 'Incorrect e-mail address',
  },
  emailBackendNotValid: {
    id: 'src.components.AccountRegistrationForm.emailBackendNotValid',
    defaultMessage: 'The entered email is invalid.',
  },
});

export type AccountRegistrationFormValues = {
  email: string;
  newsletterSubscription: boolean;
  password: string;
  phone: string;
} & FullNameFormValues;

export type AccountRegistrationFormValuesWithPasswordConfirm
  = AccountRegistrationFormValues & { passwordConfirm: string };

export type RegistrationDefaultValues = FullNameFormValues & {
  countryCode?: CountryCode;
  email: string;
  phone: string;
};

export type Props = {
  handleBackButton?: () => void;
  loading: boolean;
  onSubmit: (
    formData: AccountRegistrationFormValues,
    onCompleteCb: (data: BaseResponseV2<StatusResponsePresentational>) => void,
  ) => void;
  prefillData: RegistrationDefaultValues | null;
};

export const hooks = {
  useCheckPassword (
    password: string,
    trigger: UseFormTrigger<AccountRegistrationFormValuesWithPasswordConfirm>,
  ) {
    React.useEffect(() => {
      trigger('passwordConfirm');
    }, [password, trigger]);
  },
  useHandleSubmit(
    onSubmit: (
      formData: AccountRegistrationFormValues,
      onCompleteCb: (data: BaseResponseV2<StatusResponsePresentational>) => void,
    ) => void,
    phoneCode: string,
    onErrorSetting: (data: BaseResponseV2<StatusResponsePresentational>) => void,
  ) {
    return React.useCallback((data: AccountRegistrationFormValues) => {
      onSubmit({
        fullName: data.fullName,
        phone: `${phoneCode}${data.phone}`,
        newsletterSubscription: data.newsletterSubscription,
        password: data.password,
        email: data.email,
      }, onErrorSetting);
    }, [onSubmit, phoneCode, onErrorSetting]);
  },
  useSetErrorCallback(setError: UseFormSetError<AccountRegistrationFormValuesWithPasswordConfirm>) {
    return React.useCallback((data: BaseResponseV2<StatusResponsePresentational>) => {
      const emailError = data?.data.errors_v1?.find(
        error => 'referencedRequestParameter' in error
          ? error.code === 1001 && error.referencedRequestParameter?.includes('email')
          : undefined,
      );

      if (emailError)
        setError('email', { type: 'emailBackendNotValid', message: 'emailBackendNotValid' });
    }, [setError]);
  },
};

export const AccountRegistrationForm = React.memo((props: Props) => {
  const {
    prefillData,
    loading,
    onSubmit,
    handleBackButton,
  } = props;

  const {
    phoneCode,
    countryCode,
    handleCountryChange,
    defaultPhoneCode,
  } = formHooks.useHandleCountryChange(prefillData?.countryCode || null);

  const passwordRegex = /^(?=.*[0-9])[A-Za-z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]+/;

  const validationSchema = yup.object().shape({
    email: yup.string().required('emptyError').email('emailNotCorrect'),
    newsletterSubscription: yup.boolean().required(),
    fullName: fullNameValidationSchema,
    phone: yup.string().matches(/^\d+$/, 'phoneNotValid')
      .phone(countryCode || undefined, true, 'phoneNotValid').required('emptyError'),
    password: yup.string().required('emptyError').min(8, 'passwordShortError')
      .matches(passwordRegex, 'passwordNotCorrect'),
    passwordConfirm: yup.string().oneOf([yup.ref('password')], 'passwordDoesntMatch')
      .min(8, 'passwordShortError').required('emptyError'),
  });

  const intl = useIntl();

  const methods = useForm<AccountRegistrationFormValuesWithPasswordConfirm>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    delayError: 1000,
    defaultValues: {
      ...prefillData,
      phone: '',
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields, isValid },
    getValues,
    trigger,
    watch,
    setValue,
    setError,
  } = methods;

  const values = getValues();

  const emailState =
    formHooks.useCheckState(dirtyFields.email, values.email, errors.email);
  const phoneNumberState =
    formHooks.useCheckState(dirtyFields.phone, values.phone, errors.phone);
  const passwordState =
    formHooks.useCheckState(
      dirtyFields.password,
      values.password,
      errors.password,
    );
  const passwordConfirmState =
    formHooks.useCheckState(
      dirtyFields.passwordConfirm,
      values.passwordConfirm,
      errors.passwordConfirm,
    );

  const passwordValue = watch('password');
  hooks.useCheckPassword(passwordValue, trigger);

  const termsAndConditionsRedirect = navigationHooks.useTermsAndConditionsRedirect();
  const { value: termsAndConditionsChecked, toggle: onTermsAndConditionsToggle } =
  stateHooks.useBooleanState();

  const onErrorSetting = hooks.useSetErrorCallback(setError);

  const onFormSubmit = hooks.useHandleSubmit(
    onSubmit, phoneCode, onErrorSetting,
  );

  formHooks.usePrefillDefaultPhone(setValue, prefillData?.phone, defaultPhoneCode);

  return (
    <FormProvider {...methods} >
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Typography variant='body4' className={styles.description}>
          <FormattedMessage {...messages.description} />
        </Typography>

        <FullNameSubForm
          name='fullName'
          firstNameLabel={<FormattedMessage {...messages.firstNameLabel} />}
          lastNameLabel={<FormattedMessage {...messages.lastNameLabel} />}
          firstNamePlaceholder={intl.formatMessage(messages.firstNamePlaceholder)}
          lastNamePlaceholder={intl.formatMessage(messages.lastNamePlaceholder)}
          fullWidthVertical
        />

        <InputField
          {...register('email')}
          inputSize='large'
          label={<FormattedMessage {...messages.emailLabel} />}
          labelClassName={styles.label}
          state={emailState}
          error={errors.email
            && <FormattedMessage
              {...messages[errors.email.message as
                  ('emptyError' | 'emailNotCorrect' | 'emailBackendNotValid')]}
            />
          }
          placeholder={intl.formatMessage(messages.emailPlaceholder)}
        />
        <PhoneNumberField
          {...register('phone')}
          state={phoneNumberState}
          phoneCode={phoneCode}
          onCountryChange={handleCountryChange}
          error={errors.phone
          && <FormattedMessage
            {...messages[errors.phone.message as 'emptyError' | 'phoneNotValid']}
          />
          }
        />
        <InputField
          {...register('password')}
          withShowPassword={true}
          inputSize='large'
          state={passwordState}
          label={<FormattedMessage {...messages.passwordLabel} />}
          labelClassName={styles.label}
          hint={
            <Typography variant='body3'>
              <FormattedMessage {...messages.passwordHint} />
            </Typography>
          }
          error={errors.password
          && <FormattedMessage
            {...messages[errors.password.message as 'emptyError' | 'passwordNotCorrect']}
          />
          }
          placeholder='***************'
        />
        <InputField
          {...register('passwordConfirm')}
          withShowPassword={true}
          inputSize='large'
          state={passwordConfirmState}
          label={<FormattedMessage {...messages.passwordConfirmLabel} />}
          labelClassName={styles.label}
          hint={
            <Typography variant='body3'>
              <FormattedMessage {...messages.passwordHint} />
            </Typography>
          }
          onBlur={() => trigger('password')}
          error={errors.passwordConfirm
          && <FormattedMessage
            {...messages[errors.passwordConfirm.message as 'emptyError' | 'passwordDoesntMatch']}
          />
          }
          placeholder='***************'
        />

        <Checkbox
          className={styles.checkbox}
          name='termsAndConditionsCheckbox'
          onChange={onTermsAndConditionsToggle}
          label={
            <Typography variant='body4' className={styles.checkboxText}>
              <FormattedMessage
                {...messages.termsAndConditionsCheckbox}
                values={{ link: textCallbacks.wrapLinkTextHandler(
                  termsAndConditionsRedirect(), {
                    className: styles.termsAndConditionsLink,
                    targetBlank: true,
                  },
                ) }}
              />
            </Typography>
          }
        />
        <Checkbox
          {...register('newsletterSubscription')}
          className={styles.checkbox}
          name='newsletterSubscription'
          label={
            <Typography variant='body4' className={styles.checkboxText}>
              <FormattedMessage {...messages.emailSubscriptionCheckbox} />
            </Typography>
          }
        />

        <div className={styles.buttonGroup}>
          {handleBackButton ? (
            <Button
              intent='secondaryV2'
              size='medium'
              fit={ButtonFit.Fill}
              onClick={handleBackButton}
              className={styles.backButton}
            >
              <FormattedMessage {...messages.backLink} />
            </Button>
          ) : null}

          <Button
            type='submit'
            intent='primaryV2'
            size='medium'
            disabled={!isValid || !termsAndConditionsChecked}
            loading={loading}
            fit={ButtonFit.Fill}
            className={styles.nextButton}
          >
            <FormattedMessage {...messages.nextStep} />
          </Button>
        </div>
      </form>
    </FormProvider>
  );
});

AccountRegistrationForm.displayName = 'AccountRegistrationForm';
