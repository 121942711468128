import React from 'react';

import {
  AccountResetPasswordConfirmModal,
} from '@components/AccountResetPasswordConfirmModal/AccountResetPasswordConfirmModal';
import { GlobalMessages } from '@customTypes/Messages';
import { messageHooks } from '@hooks/messageHooks/messageHooks';

export const AccountResetPasswordConfirmModalContainer = () => {
  const modalId = messageHooks.useSubjectState(GlobalMessages.ModalOpen);
  const modalClose = messageHooks.useNextWithValue(GlobalMessages.ModalOpen, null);

  return (
    <AccountResetPasswordConfirmModal
      modalId={modalId}
      modalClose={modalClose}
    />
  );
};

AccountResetPasswordConfirmModalContainer.displayName = 'AccountResetPasswordConfirmModalContainer';
