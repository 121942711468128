import React from 'react';

import anime from 'animejs';
import classnames from 'classnames';

import styles from './DropdownV2.module.css';

export const hooks = {
  useAnimeCallbacks(subMenuRef: React.MutableRefObject<HTMLOListElement | null>) {
    const begin = React.useCallback(() => {
      subMenuRef.current?.classList.remove(styles.hide);
      subMenuRef.current?.classList.add(styles.show);
    }, [subMenuRef]);
    const complete = React.useCallback(() => {
      subMenuRef.current?.classList.remove(styles.show);
      subMenuRef.current?.classList.add(styles.hide);
    }, [subMenuRef]);

    return {
      begin,
      complete,
    };
  },
  useEventHandlers(subMenuRef: React.MutableRefObject<HTMLOListElement | null>) {
    const { begin, complete } = hooks.useAnimeCallbacks(subMenuRef);

    const handleMouseEnter = React.useCallback((event: React.MouseEvent<HTMLDivElement>) => {
      const elements = event.currentTarget.querySelectorAll(`.${styles.menuItem}`);
      anime.remove(elements);
      const elementWrappers = event.currentTarget.querySelectorAll(`.${styles.menuItemWrapper}`);
      anime.remove(elementWrappers);
      const subMenu = event.currentTarget.querySelector(`.${styles.subMenu}`);
      anime.remove(subMenu);
      const expandTimeline = anime.timeline({
        duration: 300,
        delay: anime.stagger(100),
        begin,
      });
      expandTimeline
        .add({
          targets: elements,
          scaleX: 1,
        });
      expandTimeline
        .add({
          targets: elementWrappers,
          scale: 1,
          translateX: 0,
          opacity: 1,
        }, '-100');
    }, [begin]);
    const handleMouseLeave = React.useCallback((event: React.MouseEvent<HTMLDivElement>) => {
      const elements = event.currentTarget.querySelectorAll(`.${styles.menuItem}`);
      anime.remove(elements);
      const elementWrappers = event.currentTarget.querySelectorAll(`.${styles.menuItemWrapper}`);
      anime.remove(elementWrappers);
      const subMenu = event.currentTarget.querySelector(`.${styles.subMenu}`);
      anime.remove(subMenu);
      const collapseTimeline = anime.timeline({
        duration: 300,
        delay: anime.stagger(100, { from: 'last' }),
        complete,
      });
      collapseTimeline
        .add({
          targets: elements,
          scaleX: 0,
          easing: 'easeOutExpo',
        });
      collapseTimeline
        .add({
          targets: elementWrappers,
          scale: 1.25,
          translateX: -10,
          opacity: 0,
        }, '0');
    }, [complete]);

    return {
      onMouseEnter: handleMouseEnter,
      onMouseLeave: handleMouseLeave,
    };
  },
};

export type Props = {
  dropdownClass?: string;
  items: JSX.Element[];
  rtl?: boolean;
  subMenuClass?: string;
  title: React.ReactNode;
};
export const DropdownV2 = React.memo((props: Props) => {
  const {
    dropdownClass,
    title,
    items,
    subMenuClass,
    rtl = false,
  } = props;
  const subMenuRef = React.useRef<HTMLOListElement | null>(null);
  const { onMouseEnter, onMouseLeave } = hooks.useEventHandlers(subMenuRef);

  return (
    <div
      className={classnames(
        styles.dropdownV2,
        dropdownClass,
        { [styles.rtl]: rtl },
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      { title }
      <ol ref={subMenuRef} className={classnames(styles.subMenu, styles.hide, subMenuClass)}>
        {
          items.map((item, idx) => (
            <li
              key={`DropdownV2_${title}_${idx}`}
              className={classnames(styles.menuItem, { [styles.rtl]: rtl })}
              style={{ transform: 'scaleX(0)' }}
            >
              <div className={classnames(styles.menuItemWrapper, { [styles.rtl]: rtl })}>
                { item }
              </div>
            </li>
          ))
        }
      </ol>
    </div>
  );
});

DropdownV2.displayName = 'DropdownV2';
