import React from 'react';

import { apolloHooks } from '@nxte-nl/apollo-hooks';

import { GlobalMessages } from '@customTypes/Messages';
import { BaseVariables } from '@customTypes/apiCompound';
import { QuerygetStoreDataArgs, StoreDataResponse } from '@customTypes/apiTypes';
import { BaseResponse, LanguageCodeMapped } from '@customTypes/common';
import { getStoreData } from '@queries/getStoreData.gql';

import { messageHooks } from '../messageHooks/messageHooks';
import { navigationHooks } from '../navigationHooks/navigationHooks';

export const languageResourcesHooks = {
  useStoreData() {
    const setStoreData = messageHooks.useNext(GlobalMessages.StoreData);
    const storeData = messageHooks.useSubjectState(GlobalMessages.StoreData);
    const language = navigationHooks.useLanguage();

    const { loading } = apolloHooks.useQuery<BaseResponse<StoreDataResponse>,
      BaseVariables<QuerygetStoreDataArgs>>(getStoreData, {
        skip: storeData !== null && !language,
        onCompleted: (data) => setStoreData(data),
      });

    const countriesCombinations = storeData?.data?.countriesCombinations || null;

    const supportedCountries =
      !loading && countriesCombinations
        ? countriesCombinations.map((combination) => combination.country) : null;

    return { supportedCountries, loading, countriesCombinations };
  },
  useLanguageChangeDetection() {
    const setLanguage = messageHooks.useNext(GlobalMessages.Language);
    const language = navigationHooks.useLanguage();
    React.useEffect(() => {
      setLanguage(language);
    }, [language, setLanguage]);
  },
  useIpLocationState() {
    const ipLocation = messageHooks.useSubjectState(GlobalMessages.IpLocation);
    const setIpLocation = messageHooks.useNext(GlobalMessages.IpLocation);
    return { ipLocation, setIpLocation };
  },
  useIpLocation() {
    const { ipLocation, setIpLocation } = languageResourcesHooks.useIpLocationState();

    React.useEffect(() => {
      async function fetchData() {
        const response = await fetch('/ip');
        const data = await response.json();
        setIpLocation(data);
      }
      !ipLocation && fetchData();
    }, [ipLocation, setIpLocation]);

    return {
      countryCode: ipLocation?.country_code ?? null,
      regionCode: ipLocation?.region_code ?? null,
    };
  },
  useRouting(
    language: LanguageCodeMapped | null,
  ): { languageOrDefault: LanguageCodeMapped; messages: {[key: string]: string } } {
    return React.useMemo(() => {
      const languageOrDefault = language || LanguageCodeMapped.EnGb;

      return {
        languageOrDefault,
        messages: require(`../../messages/${languageOrDefault}.json`) };
    }, [language]);
  },
  useNeededLanguages(language: LanguageCodeMapped, neededLanguages: LanguageCodeMapped[]) {
    return React.useMemo(
      () => neededLanguages.includes(language),
      [language, neededLanguages],
    );
  },
};
