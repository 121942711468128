import { NetworkError } from '@apollo/client/errors';
import { onError } from '@apollo/client/link/error';
import { OperationDefinitionNode } from 'graphql';

import {
  ApolloClient,
  ApolloLink,
  Context,
  FetchResult,
  from,
  HttpLink,
  InMemoryCache,
  NextLink,
  Observable,
  Operation,
  CustomContextVars,
  ApolloMiddleware,
} from '@nxte-nl/apollo-hooks';

import { BaseResponse, BaseResponseV2, LanguageCodeMapped } from '@customTypes/common';
import { languageMapping } from '@hooks/navigationHooks/navigationHooks';

export const callbacks = {
  apolloRequestHandler: (language: string, pathNameHeader: string, middleware?: ApolloMiddleware) =>
    (operation: Operation, forward: NextLink): Observable<FetchResult> | null => {
      operation.setContext(({ headers }: Context) => {
        const updatedHeaders = {
          'x-store': CONFIG.cachedHotelGraphql.storeId.toString(),
          'x-langcode': languageMapping[language as LanguageCodeMapped],
          'x-pathname': pathNameHeader,
          ...headers,
        };

        const operationDefinition = operation.query.definitions
          .find(x => x.kind === 'OperationDefinition') as OperationDefinitionNode;
        if (operationDefinition.operation === 'mutation') {
          updatedHeaders['x-bypass-mutations'] = '1';
        }

        // the header below intended to skip caching for prerendering requests
        // conciously turned off in order to improve performance of pages generation
        // if (serverUtils.isServerSide()) {
        //   updatedHeaders['x-bypass-prerendering'] = '1';
        // }

        return {
          headers: updatedHeaders,
        };
      });

      operation.variables.userData = {
        langcode: languageMapping[language as LanguageCodeMapped],
        storeId: CONFIG.cachedHotelGraphql.storeId,
        orderFlowType: operation.getContext().orderFlow,
      };

      return forward(operation)
        .map((data: FetchResult<BaseResponse<unknown> | BaseResponseV2<unknown>>) => {
          const context: CustomContextVars = operation.getContext();
          if (context.middleware) return context.middleware<unknown>(data);
          if (!context.ignoreError) return middleware?.<unknown>(data) || data;
          return data;
        });
    },
  apolloErrorHandler(handeError?: (error?: NetworkError) => void) {
    return onError(({ networkError }) => {
      if (networkError) handeError?.(networkError);
    });
  },
};

export const clientFactory = {
  createClient: (
    language: string,
    pathNameHeader: string,
    middleware?: ApolloMiddleware,
    errorHandler?: (error?: NetworkError) => void,
  ) =>
    new ApolloClient({
      link: from([
        new ApolloLink(callbacks.apolloRequestHandler(language, pathNameHeader, middleware)),
        callbacks.apolloErrorHandler(errorHandler),
        new HttpLink({
          uri: CONFIG.cachedHotelGraphql.uri,
          credentials: 'include',
        }),
      ]),
      cache: new InMemoryCache(),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
        mutate: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
      },
    }),
};
