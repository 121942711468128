import React from 'react';

export type Props = {
  className?: string;
  height?: number;
  onClick?(): void;
  width?: number;
};
export const ChevronDown = React.memo((props: Props) => {
  return (
    <svg
      width={props.width || 16}
      height={props.height || 16}
      className={props.className}
      onClick={props.onClick}
      viewBox='0 0 10 6'
    >
      <title>icon/chevron down</title>
      <g id='icon/chevron-down' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path d='M5.08972925,5.99568105 C5.28635658,5.97400454 5.47253336,5.88562945 5.62205665,5.74222644 L9.64149983,1.90038812 C9.84411805,1.71946742 9.97197462,1.45435069 9.99589988,1.16670372 C10.0198251,0.87905675 9.93832997,0.593095918 9.77010287,0.372979926 C9.60113122,0.153717676 9.36038763,0.0194881142 9.10168861,0.00196506279 C8.84298959,-0.0155430481 8.58878852,0.0845048246 8.39738647,0.279601912 L5,3.52787621 L1.60261347,0.279601912 C1.41121141,0.0845090933 1.15701034,-0.0155366451 0.898311321,0.00196506279 C0.639612302,0.0194731737 0.398866796,0.15370487 0.22989706,0.372979926 C0.061671879,0.593074575 -0.0198252026,0.87905675 0.00410005446,1.16670372 C0.0280253115,1.45435069 0.155876143,1.71945888 0.358500102,1.90038812 L4.37794328,5.74222644 C4.57457062,5.9306494 4.8317767,6.02152808 5.08972925,5.99568105 Z' id='Fill-1' fill='#161616'></path>
      </g>
    </svg>
  );
});
