import React from 'react';

import {
  useClick,
  useDismiss,
  useInteractions,
  FloatingPortal,
  useFloating,
  FloatingOverlay,
  useTransitionStyles,
  UseTransitionStylesProps,
} from '@floating-ui/react';
import classnames from 'classnames';

import { Loader } from '@nxte-nl/loader';

import { LayoutExtension } from '@customTypes/ResponsiveState';
import { domHooks } from '@hooks/domHooks/domHooks';

import { Close as CloseIcon } from '../icons/Close/Close';
import styles from './Modal.module.css';

export type Props = {
  animationDuration?: number;
  children: JSX.Element | string | null;
  closeButtonClassName?: string;
  containerClassName?: string;
  desktopCloseButtonColor?: string;
  desktopCloseButtonSize?: number;
  desktopStickyRight?: boolean;
  disableCloseByClickOutside?: boolean;
  disableStartAnimation?: boolean;
  disabledScroll?: boolean;
  filledHeight?: boolean;
  footer?: React.ReactNode;
  footerClassName?: string;
  headerClassName?: string;
  isOpen: boolean;
  layoutExtension: LayoutExtension | null;
  loading?: boolean;
  mobileCloseButtonColor?: string;
  mobileCloseButtonSize?: number;
  mobileV2?: boolean;
  modalClassName?: string;
  nodeId?: string;
  onClose?: () => void;
  overlayClassName?: string;
  title?: React.ReactNode;
  withTabletOpacityAnimation?: boolean;
};

export const ANIMATION_DURATION = 300;

export const Modal = React.memo(React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    nodeId,
    isOpen,
    onClose,
    title,
    modalClassName,
    overlayClassName,
    children,
    headerClassName,
    closeButtonClassName,
    footerClassName,
    layoutExtension,
    loading = false,
    footer,
    disabledScroll = false,
    disableStartAnimation = false,
    mobileV2 = false,
    disableCloseByClickOutside,
    filledHeight = false,
    mobileCloseButtonSize,
    desktopCloseButtonSize,
    desktopCloseButtonColor,
    mobileCloseButtonColor,
    containerClassName,
    animationDuration,
    desktopStickyRight,
    withTabletOpacityAnimation = false,
  } = props;

  const { isMobile, isTablet, isDesktop } = domHooks.useLayout(layoutExtension);

  const { refs, context } = useFloating({ nodeId, open: isOpen, onOpenChange: onClose });

  const overlayOpenState: UseTransitionStylesProps['open'] = {
    opacity: 1, backdropFilter: 'blur(4px)',
  };

  const overlayCloseState: UseTransitionStylesProps['open'] = {
    opacity: 0, backdropFilter: 'none',
  };

  const { styles: overlayStyles } = useTransitionStyles(context, {
    duration: animationDuration || ANIMATION_DURATION,
    initial: overlayCloseState,
    open: overlayOpenState,
    close: overlayCloseState,
  });

  const modalOpenStateTransform = React.useMemo(() => {
    if (isMobile || isTablet) {
      return mobileV2 ? 'translateY(0)' : 'translateX(0)';
    }

    if (desktopStickyRight) {
      return 'translateX(0)';
    }

    return 'scale(1)';
  }, [isMobile, isTablet, desktopStickyRight, mobileV2]);

  const modalCloseStateTransform = React.useMemo(() => {
    if (isMobile || isTablet) {
      return mobileV2 ? 'translateY(100%)' : 'translateX(-100%)';
    }

    if (desktopStickyRight) {
      return 'translateX(100%)';
    }

    return 'scale(0.9)';
  }, [isMobile, isTablet, desktopStickyRight, mobileV2]);

  const modalOpenState: UseTransitionStylesProps['open'] = {
    opacity: 1,
    transform: modalOpenStateTransform,
  };

  const modalCloseState: UseTransitionStylesProps['close'] = {
    opacity: (!withTabletOpacityAnimation && isTablet)
      && (isMobile || isTablet) ? 1 : 0,
    transform: modalCloseStateTransform,
  };

  const { isMounted, styles: modalStyles } = useTransitionStyles(context, {
    duration: animationDuration || ANIMATION_DURATION,
    initial: disableStartAnimation ? modalOpenState : modalCloseState,
    open: modalOpenState,
    close: { ...modalCloseState, zIndex: 1000 },
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
    outsidePress: () => !disableCloseByClickOutside,
  });

  const { getFloatingProps } = useInteractions([click, dismiss]);

  const { isScrollPresent, refCallback } = domHooks.useScrollPresent(isMobile || isTablet);

  return (
    <>
      {isMounted && (
        <FloatingPortal id={nodeId}>
          <FloatingOverlay
            style={{ ...overlayStyles }}
            className={classnames(styles.overlay, overlayClassName, {
              [styles.mobileV2]: mobileV2,
            })}
          />
          <div className='floating-modal' ref={refs.setFloating} {...getFloatingProps()}>
            <div
              className={classnames(styles.modal, modalClassName, {
                [styles.mobileV2]: mobileV2,
                [styles.disabledScroll]: disabledScroll,
                [styles.animateHeight]: mobileV2 && title,
                [styles.filledHeight]: filledHeight,
              })}
              style={{ ...modalStyles }}
              ref={ref}
            >
              {isDesktop && <div onClick={onClose}
                className={classnames(styles.closeButton, closeButtonClassName)}>
                <CloseIcon
                  color={desktopCloseButtonColor ?? '#fff'}
                  width={desktopCloseButtonSize || 12}
                  height={desktopCloseButtonSize || 12}
                />
              </div>}
              <div className={classnames(styles.container, containerClassName, {
                [styles.scrollGap]: isScrollPresent,
                [styles.noMaxHeight]: !mobileV2,
              })} ref={refCallback}>
                <div className={classnames(styles.header, headerClassName, {
                  [styles.bordered]: title,
                })}>
                  <div className={styles.headerContent}>
                    {title}
                    {(isMobile || isTablet)
                      && <div className={classnames(styles.closeButton, closeButtonClassName)}
                        onClick={onClose}>
                        <CloseIcon
                          color={mobileCloseButtonColor}
                          width={mobileCloseButtonSize || 20}
                          height={mobileCloseButtonSize || 20}
                        />
                      </div>}
                  </div>
                </div>
                {children}
                {loading && (
                  <div className={styles.modalLoadingState}>
                    <Loader
                      loaderColor='rgb(195, 200, 233)'
                      smallSize={true}
                    />
                  </div>
                )}
                {footer && (
                  <div className={classnames(styles.footer, footerClassName)}>
                    {footer}
                  </div>
                )}
              </div>
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  );
}));

Modal.displayName = 'Modal';
