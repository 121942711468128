import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { AccountConfirmationModal }
  from '@components/AccountConfirmationModal/AccountConfirmationModal';
import { Modal } from '@customTypes/Modal';

const messages = defineMessages({
  title: {
    id: 'src.components.AccountRequestPasswordConfirmModal.title',
    defaultMessage: 'Registreren gelukt!',
  },
  description: {
    id: 'src.components.AccountRequestPasswordConfirmModal.description',
    defaultMessage: 'Je account is aangemaakt. Bestel vanaf nu Hotel Giftcards als cadeau, of wissel je eigen giftcard in voor een verblijf in je favoriete hotel.',
  },
});

export type Props = {
  modalClose: () => void;
  modalId: Modal | null;
};

export const AccountRequestPasswordConfirmModal = React.memo((props: Props) => {
  const {
    modalId,
    modalClose,
  } = props;

  return (
    <AccountConfirmationModal
      isOpen={modalId === Modal.AccountRequestPasswordConfirmModal}
      onClose={modalClose}
      title={<FormattedMessage {...messages.title} />}
      description={<FormattedMessage {...messages.description} />}
    />
  );
});

AccountRequestPasswordConfirmModal.displayName = 'AccountRequestPasswordConfirmModal';
