import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { serverUtils } from '@utils/serverUtils/serverUtils';

export type Props = {
  children: React.ReactNode;
  className?: string;
  id?: string;
  onClick?: React.MouseEventHandler;
  target?: string;
  to?: string | (() => string);
};

export const HrefLink = React.memo((props: Props) => {
  const {
    children,
    className,
    to,
    target,
    onClick,
    id,
  } = props;

  const href = to
    ? typeof to === 'string'
      ? to
      : to()
    : undefined;

  return (
    <>
      {serverUtils.isNextBuild() || !to
        ? <a
          id={id}
          className={className}
          href={href}
          target={target}
          onClick={onClick}
        >
          {children}
        </a>
        : (
          <RouterLink
            id={id}
            className={className}
            to={to}
            target={target}
            onClick={onClick}
          >
            {children}
          </RouterLink>
        )}
    </>
  );});

HrefLink.displayName = 'HrefLink';
