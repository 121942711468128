import React from 'react';
import { useForm, UseFormSetError } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { BaseResponse } from '@nxte-nl/apollo-hooks';

import { Button, ButtonFit } from '@components/Button/Button';
import { InputField } from '@components/InputField/InputField';
import { RecaptchaError } from '@components/RecaptchaError/RecaptchaError';
import { Typography } from '@components/Typography/Typography';
import { StatusResponsePresentational } from '@customTypes/common';
import { formHooks } from '@hooks/formHooks/formHooks';

import styles from './AccountIdentifyForm.module.css';

const messages = defineMessages({
  description: {
    id: 'src.components.AccountIdentifyForm.description',
    defaultMessage: 'Vul je e-mailadres in om in te loggen of een account aan te maken.',
  },
  emailLabel: {
    id: 'src.components.AccountIdentifyForm.emailLabel',
    defaultMessage: 'E-mailadres',
  },
  emailNotCorrect: {
    id: 'src.components.AccountIdentifyForm.emailNotCorrect',
    defaultMessage: 'Incorrect e-mail address',
  },
  emailPlaceholder: {
    id: 'src.components.AccountIdentifyForm.emailPlaceholder',
    defaultMessage: 'Vul je e-mailadres in',
  },
  emptyError: {
    id: 'src.components.AccountIdentifyForm.emptyError',
    defaultMessage: 'Required field',
  },
  nextStep: {
    id: 'src.components.AccountIdentifyForm.nextStep',
    defaultMessage: 'Ga verder',
  },
  emailBackendNotValid: {
    id: 'src.components.AccountIdentifyForm.emailBackendNotValid',
    defaultMessage: 'The entered email is invalid.',
  },
});
export const hooks = {
  useFormSubmit(
    onFormSubmit: (
      formData: IdentifyFormValues,
      onCompleteCb: (data: BaseResponse<StatusResponsePresentational>) => void,
    ) => void,
    onErrorSetting: (data: BaseResponse<StatusResponsePresentational>) => void,
  ) {
    return React.useCallback((data: IdentifyFormValues) => {
      onFormSubmit(data, onErrorSetting);
    }, [onFormSubmit, onErrorSetting]);
  },
  useSetErrorCallback(setError: UseFormSetError<IdentifyFormValues>) {
    return React.useCallback((data: BaseResponse<StatusResponsePresentational>) => {
      const emailError = data?.data.errors_v1?.find(
        error => 'referencedRequestParameter' in error
          ? error.code === 1001 && error.referencedRequestParameter?.includes('email')
          : undefined,
      );

      if (emailError)
        setError(
          'email',
          { type: 'emailBackendNotValid', message: 'emailBackendNotValid' },
        );
    }, [setError]);
  },
};

export type IdentifyFormValues = {
  email: string;
};

export type Props = {
  loading: boolean;
  onSubmit: (data: IdentifyFormValues) => void;
  recaptchaError: string | null;
};

export const AccountIdentifyForm = React.memo((props: Props) => {
  const {
    loading,
    onSubmit,
    recaptchaError,
  } = props;

  const intl = useIntl();

  const validationSchema = React.useMemo(() => yup.object().shape({
    email: yup.string().required('emptyError').email('emailNotCorrect'),
  }), []);

  const methods = useForm<IdentifyFormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    delayError: 1000,
  });
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields, isValid },
    getValues,
    setError,
  } = methods;

  const email = getValues('email');
  const onErrorSetting = hooks.useSetErrorCallback(setError);
  const handleFormSubmit = hooks.useFormSubmit(onSubmit, onErrorSetting);

  const emailState =
      formHooks.useCheckState(dirtyFields.email, email, errors.email);

  return (
    <>
      <Typography variant='body4' className={styles.description}>
        <FormattedMessage {...messages.description} />
      </Typography>
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <InputField
          {...register('email')}
          className={styles.identifyInput}
          inputSize='large'
          state={emailState}
          label={<FormattedMessage {...messages.emailLabel} />}
          labelClassName={styles.label}
          error={errors.email
            && <FormattedMessage
              {...messages[errors.email.message as
                  ('emptyError' | 'emailNotCorrect' | 'emailBackendNotValid')]}
            />
          }
          placeholder={intl.formatMessage(messages.emailPlaceholder)}
        />
        {recaptchaError && <RecaptchaError />}
        <Button
          type='submit'
          intent='primaryV2'
          size='medium'
          disabled={!isValid}
          loading={loading}
          fit={ButtonFit.Fill}
        >
          <FormattedMessage {...messages.nextStep} />
        </Button>
      </form>
    </>
  );
});

AccountIdentifyForm.displayName = 'AccountIdentifyForm';
