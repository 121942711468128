import React from 'react';

import classNames from 'classnames';

import styles from './Typography.module.css';

export type Props = {
  children?: React.ReactNode;
  className?: string;
  component?: keyof HTMLElementTagNameMap;
  dangerouslySetInnerHTML?: { __html: string };
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'body1' | 'body2' | 'body3' | 'body4' ;
};

const variantMapping: Record<string, keyof HTMLElementTagNameMap> = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  body1: 'p',
  body2: 'p',
  body3: 'p',
  body4: 'p',
};

export const Typography = React.memo(({
  variant = 'body2', className, component, children, dangerouslySetInnerHTML,
}: Props) => {
  const Component = component || variantMapping[variant];
  return (
    <Component
      className={classNames(styles.root, styles[variant], className)}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {children}
    </Component>
  );
});
