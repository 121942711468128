import React from 'react';

export type Props = {
  className?: string;
  height?: number;
  onClick?(): void;
  width?: number;
};
export const Basket = React.memo((props: Props) => {
  return (
    <svg
      width={props.width || 24}
      height={props.height || 24}
      className={props.className}
      onClick={props.onClick}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path fill='transparent' fillRule='evenodd' clipRule='evenodd' d='M20 17.836C20 18.4762 19.4076 19 18.6836 19H5.31644C4.5924 19 4 18.4762 4 17.836L5 9.16404C5 8.52382 5.5924 8 6.31644 8H17.6836C18.4076 8 19 8.52382 19 9.16404L20 17.836Z' stroke='#161616' strokeWidth='1.8' strokeLinecap='round' strokeLinejoin='round'/>
      <path fill='transparent' d='M9 11V6C9 4.34315 10.3431 3 12 3C13.6569 3 15 4.34315 15 6V11' stroke='#161616' strokeWidth='1.8' strokeLinecap='round' strokeLinejoin='round'/>
    </svg>
  );
});

Basket.displayName = 'Basket';
