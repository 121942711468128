import React from 'react';

import { apolloHooks } from '@nxte-nl/apollo-hooks';

import { AccountIdentifyForm, IdentifyFormValues }
  from '@components/AccountIdentifyForm/AccountIdentifyForm';
import { BaseVariables } from '@customTypes/apiCompound';
import { QuerycheckUserExistArgs, StatusResponse } from '@customTypes/apiTypes';
import { BaseResponse } from '@customTypes/common';
import { googleRecaptchaHooks } from '@hooks/googleRecaptchaHooks/googleRecaptchaHooks';
import { checkUserExist } from '@queries/checkUserExist.gql';

export const hooks = {
  useOnCompletedHandler(
    setLoginStep: () => void,
    setRegisterStep: () => void,
    setEmail: (value: string | null) => void,
  ) {
    return React.useCallback((
      data: BaseResponse<StatusResponse>,
      email?: string | null,
    ) => {
      if (!data || data.data.errors_v1?.length) return setEmail(null);
      setEmail(email ?? null);

      if (data.data.success) return setLoginStep();

      setRegisterStep();
    }, [setEmail, setLoginStep, setRegisterStep]);
  },
  useIdentifyUser(
    handleData: (
      data: BaseResponse<StatusResponse>,
      email: string | null | undefined,
    ) => void,
  ) {
    const {
      executeRecaptcha,
      isRecaptchaLoading,
      recaptchaError } = googleRecaptchaHooks.useExecuteRecaptcha();

    const [executeRequest, { loading }] = apolloHooks.useLazyQuery<
      BaseResponse<StatusResponse>,
      BaseVariables<QuerycheckUserExistArgs>
    >(checkUserExist);

    const handleSubmit = React.useCallback(async (
      data: IdentifyFormValues,
      onCompleteCb?: (data: BaseResponse<StatusResponse>) => void,
    ) => {
      const captchaToken = await executeRecaptcha();
      const email = data.email;
      if (!captchaToken) return;
      await executeRequest({
        variables: { email: email, captchaToken },
        onCompleted: (data) => {
          handleData(data, email);
          onCompleteCb?.(data);
        } });
    }, [executeRecaptcha, executeRequest, handleData]);

    return {
      isIdentifyUserLoading: loading || isRecaptchaLoading,
      onIdentifyUserSubmit: handleSubmit,
      recaptchaError,
    };
  },
};

export type Props = {
  setEmail: (value: string | null) => void;
  setLoginStep: () => void;
  setRegisterStep: () => void;
};
export const AccountIdentifyFormContainer = (props: Props) => {
  const {
    setLoginStep,
    setRegisterStep,
    setEmail,
  } = props;

  const handleData = hooks.useOnCompletedHandler(setLoginStep, setRegisterStep, setEmail);
  const {
    isIdentifyUserLoading,
    onIdentifyUserSubmit,
    recaptchaError,
  } = hooks.useIdentifyUser(handleData);

  return (
    <AccountIdentifyForm
      loading={isIdentifyUserLoading}
      onSubmit={onIdentifyUserSubmit}
      recaptchaError={recaptchaError}
    />
  );
};

AccountIdentifyFormContainer.displayName = 'AccountIdentifyFormContainer';
