import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  starsValue: {
    id: 'src.hooks.intlHooks.starsValue',
    defaultMessage: '{starsNumbers, plural, one {1 star} other {{starsNumbers} stars}}' ,
  },
});

export const intlHooks = {
  useFormatPrice(price: number | null, currency: string) {
    const intl = useIntl();

    return React.useMemo(
      () => (price || price === 0) && currency
        ? intl.formatNumber(price, { style: 'currency', currency }) : '',
      [price, currency, intl],
    );
  },
  useShortPriceRange({
    min = null,
    max = null,
    currency = '',
  }: { currency?: string; max?: number | null; min?: number | null }) {
    const formattedMinPrice =
      intlHooks.useFormatPrice(min, currency);

    const formattedMaxPrice =
      intlHooks.useFormatPrice(max, currency);

    return formattedMinPrice && formattedMaxPrice
      ? `${formattedMinPrice} - ${formattedMaxPrice}` : '';
  },
  useStarsValueToShow(selectedStarsArray: number[]) {
    const intl = useIntl();
    if (selectedStarsArray.length === 0) {
      return '';
    }
    else if (selectedStarsArray.length <= 2) {
      return intl.formatMessage(
        messages.starsValue,
        { starsNumbers: intl.formatList(selectedStarsArray.sort().map((x) => x.toString())) },
      );
    }
    else {
      return intl.formatList(selectedStarsArray.sort().map((x) => x.toString()));
    }
  },
};
