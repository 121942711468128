import React from 'react';

import classnames from 'classnames';

import { Throbber } from '@components/icons/Throbber/Throbber';

import styles from './ThrobberWrapper.module.css';

export type Props = {
  height?: number;
  onClick?: () => void;
  throbberClassName?: string;
  throbberWrapperClassName?: string;
  width?: number;
};

export const ThrobberWrapper = React.memo((props: Props) => {
  const {
    throbberWrapperClassName,
    throbberClassName,
    width,
    height,
    onClick,
  } = props;

  return (
    <div className={classnames(styles.throbberWrapper, throbberWrapperClassName)}>
      <Throbber
        className={throbberClassName}
        width={width || 40}
        height={height || 40}
        onClick={onClick}
      />
    </div>
  );
});

ThrobberWrapper.displayName = 'ThrobberWrapper';
