import React from 'react';

import classnames from 'classnames';

import styles from './Loader.module.css';

export type Props = {
  className?: string;
  loaderColor?: string;
  mediumSize?: boolean;
  smallSize?: boolean;
};

export const Loader = React.memo((props: Props) => {
  const circles = Array.from(Array(12).keys());

  const { className, loaderColor, smallSize, mediumSize } = props;

  return (
    <div className={classnames(className, styles.loader, {
      [styles.smallSize]: smallSize,
      [styles.mediumSize]: mediumSize && !smallSize,
    })} >
      {circles.map((circle) => (
        <div
          key={circle}
          className={styles.loaderItem}
          style={{ background: loaderColor }}
        />
      ))}
    </div>
  );
});

Loader.displayName = 'Loader';
