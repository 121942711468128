import React from 'react';

import classnames from 'classnames';

import styles from './Throbber.module.css';

export type Props = {
  className?: string;
  height?: number;
  onClick?(): void;
  width?: number;
};
export const Throbber = React.memo((props: Props) => {
  return (
    <svg
      width={props.width || 25}
      height={props.height || 25}
      viewBox='0 0 25 25'
      className={classnames(styles.animate, props.className)}
      onClick={props.onClick}
    >
      <g id='throbber' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='Group' transform='translate(12.500000, 12.500000) rotate(90.000000) translate(-12.500000, -12.500000) '>
          <circle id='Oval' fill='#1F35D4' cx='5' cy='5' r='5'></circle>
          <circle id='Oval' fill='#FFC4E9' cx='20' cy='5' r='5'></circle>
          <circle id='Oval' fill='#2091EF' cx='5' cy='20' r='5'></circle>
          <circle id='Oval' fill='#E27AFC' cx='20' cy='20' r='5'></circle>
        </g>
      </g>
    </svg>
  );
});

Throbber.displayName = 'Throbber';
