import React from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { stateHooks } from '@nxte-nl/state-hooks';

export const googleRecaptchaHooks = {
  useExecuteRecaptcha() {
    const { executeRecaptcha: _executeRecaptcha } = useGoogleReCaptcha();

    const {
      setTrue: setCapthaLoadingTrue, setFalse: setCaptchaLoadingFalse, value: isRecaptchaLoading,
    } = stateHooks.useBooleanState();

    const [error, setError] = React.useState<string | null>(null);

    const executeRecaptcha = React.useCallback(
      async (action: string = CONFIG.reCaptchaBusinessRequestQuotaAction) => {
        if (!_executeRecaptcha) return;

        setCapthaLoadingTrue();

        let captchaToken = null;
        try {
          captchaToken = await _executeRecaptcha(action);
        } catch (e) {
          setError('Recaptcha error');
        }

        setCaptchaLoadingFalse();

        return captchaToken;
      }, [_executeRecaptcha, setCaptchaLoadingFalse, setCapthaLoadingTrue],
    );

    return { isRecaptchaLoading, executeRecaptcha, recaptchaError: error };
  },
};
