import React from 'react';

import { ErrorBoundary } from '@nxte-nl/error-boundary';

import { GlobalMessages } from '@customTypes/Messages';
import { LayoutExtension } from '@customTypes/ResponsiveState';
import { domHooks } from '@hooks/domHooks/domHooks';
import { messageHooks } from '@hooks/messageHooks/messageHooks';

export type Props = {
  children: ({
    layoutExtension,
    isMobile,
    isTablet,
    isDesktop,
  }: {
    isDesktop: boolean;
    isMobile: boolean;
    isTablet: boolean;
    layoutExtension: LayoutExtension;
  }) => React.ReactNode;
};

export const LayoutExtensionContainer = React.memo((props: Props) => {
  const { children } = props;
  const layoutExtension = messageHooks.useSubjectState(GlobalMessages.LayoutExtension);
  const layouts = domHooks.useLayout(layoutExtension);

  return layoutExtension
    ? <ErrorBoundary>
      {children({ layoutExtension, ...layouts })}
    </ErrorBoundary>
    : null;
});

LayoutExtensionContainer.displayName = 'LayoutExtensionContainer';
