import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';

import styles from './RecaptchaError.module.css';

const messages = defineMessages({
  recaptchaErrorMessage: {
    id: 'src.components.RecaptchaError.recaptchaErrorMessage',
    defaultMessage: 'Google reCaptcha failed, please try again',
  },
});

export const RecaptchaError = React.memo(() => {
  return (
    <div className={styles.recaptchaError}>
      <FormattedMessage {...messages.recaptchaErrorMessage} />
    </div>
  );
});

RecaptchaError.displayName = 'RecaptchaError';
